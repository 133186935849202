import React from 'react';
import styles from './styles.module.scss';
import classNames from 'classnames';

interface PopupBaseProps {
    open: boolean;
    onClose(): void;
    children: React.ReactNode;
}

const PopupBase: React.FC<PopupBaseProps> = ({ open = false, onClose = () => { }, children }) => {
    return (
        <div className={classNames(styles['popup-base'], {
            [styles['open']]: open,
        })}>
            <div className={styles['backdrop']} onClick={onClose}></div>
            {children}
        </div>
    )
}

export default PopupBase