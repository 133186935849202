export function toTitleCase(str: string | undefined): string {
  if (str) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }
  return '';
}

export function collapseAddress(
  address: string,
  numberOfInits: number = 7,
  numberOfLast: number = 4
): string {
  if (
    address &&
    address.length > numberOfInits &&
    address.length - numberOfLast > 0
  ) {
    return `${address.slice(0, numberOfInits)}....${address.slice(
      address.length - numberOfLast
    )}`;
  } else {
    return '';
  }
}

export function addLargeNumbers(num1: string, num2: string) {
  // Reverse 2 string
  let reversedStrNum1 = num1.split('').reverse().join('');
  let reversedStrNum2 = num2.split('').reverse().join('');

  // Xác định max length
  let maxLength = Math.max(reversedStrNum1.length, reversedStrNum2.length);

  // Mảng để lưu kết quả
  let resultArray = [];

  // Biến nhớ
  let carry = 0;

  // Lặp qua từng chữ số
  for (let i = 0; i < maxLength; i++) {
    let digit1 = i < reversedStrNum1.length ? parseInt(reversedStrNum1[i]) : 0;
    let digit2 = i < reversedStrNum2.length ? parseInt(reversedStrNum2[i]) : 0;

    // Cộng lần lượt các hàng tương ứng
    let sum = digit1 + digit2 + carry;
    carry = Math.floor(sum / 10);
    resultArray.push(sum % 10);
  }

  // Nếu có nhớ cuối cùng
  if (carry > 0) {
    resultArray.push(carry);
  }

  // Reverse string kết quả
  let result = resultArray.reverse().join('');

  return result;
}
