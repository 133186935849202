

import { PageData2 } from 'interface';
import { request_new } from '../request';
import { CampaignService } from 'interface/campaign/campaign_service';

export const getCampaignServiceDetail = async (params: any) => {
    // await fetchingCampaign({
    //     campaign_address: params?.address
    // })
    return request_new<PageData2<CampaignService>>('get', '/api/public/CampaignService/detail', params)
};
