import { AppConfig, NetworkConfigs, getAppConfig } from './core/logic';

const local: AppConfig = {
  chainId: 'euphoria-2',
  chainName: 'Euphoria',
  addressPrefix: 'aura',
  rpcUrl: 'https://rpc.euphoria.aura.network',
  httpUrl: 'https://lcd.euphoria.aura.network', //LCD
  faucetUrl: 'http://grpc.euphoria.aura.network:9090',
  feeToken: 'ueaura',
  stakingToken: 'ueaura',
  coinMap: {
    ueaura: { denom: 'EAURA', fractionalDigits: 6 }
  },
  gasPrice: 0.0025
};


const auraeuphoria: AppConfig = {
  chainId: 'euphoria-2',
  chainName: 'Euphoria',
  addressPrefix: 'aura',
  rpcUrl: 'https://rpc.euphoria.aura.network',
  httpUrl: 'https://lcd.euphoria.aura.network', //LCD
  faucetUrl: 'http://grpc.euphoria.aura.network:9090',
  feeToken: 'ueaura',
  stakingToken: 'ueaura',
  coinMap: {
    ueaura: { denom: 'EAURA', fractionalDigits: 6 }
  },
  gasPrice: 0.0025
};


const auramainnet: AppConfig = {
  chainId: 'xstaxy-1',
  chainName: 'Aura Mainnet',
  addressPrefix: 'aura',
  rpcUrl: 'https://rpc.aura.network',
  httpUrl: 'https://lcd.aura.network', //LCD
  faucetUrl: 'https://grpc.aura.network',
  feeToken: 'uaura',
  stakingToken: 'uaura',
  coinMap: {
    uaura: { denom: 'AURA', fractionalDigits: 6 }
  },
  gasPrice: 0.0025
};


const configs: NetworkConfigs = { local, auraeuphoria, auramainnet };
export const config = getAppConfig(configs);
