import React from 'react'
import styles from './styles.module.scss';
import classNames from 'classnames';

interface TooltipProps {
    className?: string;
    text?: string;
}

const Tooltip: React.FC<TooltipProps> = ({ className, text = '' }) => {
    return (
        text ? <div className={classNames(styles['tooltip'], className)} role='tooltip'>{text}</div> : <></>
    )
}

export default Tooltip