import React from 'react'
import PopupBase from '../PopupBase'
import styles from './styles.module.scss';
import { Close } from 'assets/svg-component';

interface ModalBaseProps {
    open: boolean;
    onClose(): void;
    children: React.ReactNode
}


const ModalBase: React.FC<ModalBaseProps> = ({ open = false, onClose = () => { }, children }) => {
    React.useEffect(() => {
        if (!!document) {
            const body = document.querySelector("body")!;
            if (open) {
                body.style.overflowY = 'hidden';
            }
            else {
                body.style.overflowY = 'auto';
            }
        }
    }, [open])
    return (
        <PopupBase open={open} onClose={onClose}>
            <div className={styles['modal']}>
                <div className={styles['close']}>
                    <Close onClick={onClose} />
                </div>
                <div className={styles['body']}>{children}</div>
            </div>
        </PopupBase>
    )
}

export default ModalBase