import * as React from 'react';
const SvgGift = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16px"
    height="16px"
    fill="none"
    viewBox="0 0 60 60"
    role={'icon-default'}
    {...props}
  >
    <path
      stroke={'currentColor'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M6 35.24V22.07a6 6 0 0 1 6-6h36a6 6 0 0 1 6 6v13.17m-48 0h48m-48 0v13.83a6 6 0 0 0 6 6h36a6 6 0 0 0 6-6V35.24M30.437 55.07v-39m-3.026-1.264c.449-.018.864-.254 1.088-.641.223-.387.22-.865.011-1.263-.796-1.452-3.591-6.279-5.416-7.332-2.272-1.312-5.192-.538-6.497 1.723-1.306 2.262-.517 5.177 1.756 6.49 1.854 1.07 7.402 1.06 9.058 1.023Zm4.176-1.904c-.209.398-.212.876.011 1.262.224.387.64.623 1.088.642 1.656.036 7.234.029 9.058-1.024 2.273-1.313 3.062-4.228 1.756-6.49-1.305-2.26-4.224-3.035-6.497-1.723-1.854 1.07-4.62 5.88-5.416 7.333Z"
    />
  </svg>
);
export default SvgGift;
