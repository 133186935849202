import * as React from 'react';
const SvgSearch = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16px"
    height="16px"
    fill="none"
    viewBox="0 0 16 16"
    role={'icon-default'}
    {...props}
  >
    <path
      stroke={'currentColor'}
      strokeLinecap="round"
      strokeWidth={1.5}
      d="M11.285 11.36 13.6 13.6m-.747-5.973a5.227 5.227 0 1 1-10.453 0 5.227 5.227 0 0 1 10.453 0Z"
    />
  </svg>
);
export default SvgSearch;
