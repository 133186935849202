import { PageData3 } from 'interface';
import { request_new } from './request';

export const postApproveNft = (params: any) => {
    return request_new<PageData3<any>>('post', '/api/public/UserService/approve-campaign', params)
};

export const getApproveNft = (params: any) => {
    return request_new<PageData3<any>>('get', '/api/public/UserService/approve-campaign', params)
};
