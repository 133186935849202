export const constState = {
    campaignDetail: 'campaignDetail',
    isLoadingCampaignDetail: 'isLoadingCampaignDetail',
    campaignAnalysis: 'campaignAnalysis',

    publicCampaign: 'publicCampaign',
    isLoadingPublicCampaign: 'isLoadingPublicCampaign',

    stakedCampaign: 'stakedCampaign',
    isLoadingStakedCampaign: 'isLoadingStakedCampaign',

    createdCampaign: 'createdCampaign',
    isLoadingCreatedCampaign: 'isLoadingCreatedCampaign',

    stakingHistory: 'stakingHistory',

    rewardDebt: 'rewardDebt',
    rewardClaimed: 'rewardClaimed',
    nftStaked: 'nftStaked',

    addressContract: 'addressContract',
}