import ModalBase from 'components/base/ModalBase';
import { useStoreModal } from 'context/StoreModal';
import React, { useEffect } from 'react';
import styles from './styles.module.scss';
import moment from 'moment';
import BaseButton from 'components/base/Button';
import ButtonGradient from 'components/base/ButtonGradient';
import classNames from 'classnames';
import { formatNumberCoin } from 'helpers/number';
import { useClient } from 'core/logic/service/public';
import { useSdk } from 'core/logic';
import { toast } from 'react-toastify';
import { handleMsgErr } from 'utils/contract-utils';
import { useCampaignService } from 'context/CampaignService';
import { StatusClaim, useUnstakeAll } from 'context/UnstakeAll';
import rewardPNG from 'assets/images/reward.png';
import { useClaimReward } from 'context/ClaimReward';
import { addLargeNumbers } from 'utils/string';
import { UnStakeNft } from 'core/logic/service/campaign';

const UnstakeAllModal = () => {
  const { address } = useSdk();
  const { campaign } = useClient();
  const { isOpen, setIsOpen } = useStoreModal('unstake-all-modal');
  const { listUnstake, setStatusClaim } = useUnstakeAll();
  const { campaignDetail, rewardDebt } = useCampaignService();
  const { requestListStakeNFT } = useCampaignService();

  const [loadingBtn, setLoadingBtn] = React.useState(false);
  const [totalReward, setTotalReward] = React.useState('0');

  const unStakeAll = async () => {
    setLoadingBtn(true);
    setStatusClaim(StatusClaim.Pending);

    console.log({ listUnstake });

    //TODO: unstake all
    let un_stakes: UnStakeNft[] = [];
    listUnstake.forEach((nft) => {
      un_stakes.push({
        key: nft?.key,
        lockup_term: nft?.lockup_term,
        token_id: nft?.token_id
      });
    });

    await campaign
      ?.un_stake_and_claim_nft(address, un_stakes, totalReward)
      .then((data) => {
        setIsOpen(false);
        setLoadingBtn(false);
        setStatusClaim(StatusClaim.Success);
        requestListStakeNFT(undefined);
        toast.success('Unstake and Claim successfully');
      })
      .catch((err) => {
        setLoadingBtn(false);
        setStatusClaim(StatusClaim.NotYet);
        let msg = handleMsgErr(err?.message);
        toast.error(msg);
      });
  };

  useEffect(() => {
    if (!isOpen) setLoadingBtn(false);
  }, [isOpen]);

  useEffect(() => {
    let totalReward = rewardDebt;
    listUnstake.forEach((nft) => {
      totalReward = addLargeNumbers(totalReward, nft?.pending_reward);
    });
    setTotalReward(totalReward);
  }, [listUnstake]);

  return (
    <ModalBase
      open={isOpen}
      onClose={() => {
        setIsOpen(false);
      }}
    >
      <div className={styles['claim-reward-modal']}>
        <h6 className={'title-modal'}>Unstake All ({listUnstake.length})</h6>
        <div className={styles['infor-claim']}>
          <div className={`${styles['table']}`}>
            <div className={` ${styles['list-nft']}`}>
              <div className={styles['table-head']}>
                <span>NFT Lists</span>
                <span>Rewards</span>
              </div>
              <div className={styles['list-nft-data']}>
                {listUnstake?.map((nft) => {
                  return (
                    <div className={styles['nft']}>
                      <div>
                        <img
                          src={nft?.media_info?.offchain?.image?.url}
                          className={styles['logo']}
                          alt={nft?.media_info?.offchain?.image?.url}
                        />
                        <label className={styles['name']}>
                          {campaignDetail?.collection_name} #{nft?.token_id}
                        </label>
                      </div>
                      <span className={styles['reward']}>
                        {formatNumberCoin(
                          nft?.pending_reward,
                          campaignDetail?.token_decimal
                        )}{' '}
                        {campaignDetail?.token_symbol || 'AURA'}
                      </span>
                    </div>
                  );
                })}

                <div style={{ height: 30 }}></div>
              </div>
              <div className={styles['list-nft__end']}></div>
            </div>
          </div>

          <div className={`${styles['row-infor']} ${styles['background']}`}>
            <div className={styles['label-reward']}>
              <img src={rewardPNG} alt="" />
              <label htmlFor="">Total Rewards</label>
            </div>
            <div className={styles['title']}>
              {' '}
              {formatNumberCoin(
                Number(totalReward),
                campaignDetail?.token_decimal
              )}{' '}
              {campaignDetail?.token_symbol || 'AURA'}
            </div>
          </div>
        </div>
        <div className={styles['button-frame']}>
          <BaseButton onClick={unStakeAll}>Unstake & Claim</BaseButton>
        </div>
      </div>
    </ModalBase>
  );
};

export default UnstakeAllModal;
