import React from 'react';
import styles from './styles.module.scss';

interface CustomToastWithLinkProps {
  text: string;
  transaction: string | undefined;
}

const CustomToastWithLink: React.FC<CustomToastWithLinkProps> = ({
  text = '',
  transaction = ''
}) => (
  <div className={styles['toast']}>
    <label>
      {text},{' '}
      <a href={transaction} target="_blank" rel='noopener noreferrer'>
        view detail
      </a>
    </label>
  </div>
);

export default CustomToastWithLink;
