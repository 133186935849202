import {
  CosmWasmClient,
  SigningCosmWasmClient
} from '@cosmjs/cosmwasm-stargate';

export interface Token {
  contract_addr: string;
}

export interface NativeToken {
  denom: string;
}

export enum TokenInfo {
  Token,
  NativeToken
}

export interface AssetTokenInfo {
  info: TokenInfo;
  amount: number;
}
export interface LockupTerm {
  name: string;
  value: number;
  percent: number;
}

export interface FactoryInstance {
  readonly contractAddress: string;

  // queries
  config: () => Promise<any>;
  campaigns: (limit?: number) => Promise<any>;

  // actions
  update_config: (
    sender: string,
    owner?: string,
    campaign_code_id?: number
  ) => Promise<any>;
  create_campaign: (
    sender: string,
    campaign_name: string,
    campaign_image: string,
    campaign_description: string,
    limit_per_staker: number,
    reward_token_info: AssetTokenInfo,
    allowed_collection: string,
    lockup_term: LockupTerm,
    start_time: number,
    end_time: number
  ) => Promise<any>;
  update_campaign: (
    sender: string,
    contract_addr: string,
    campaign_name: string,
    campaign_image: string,
    campaign_description: string,
    limit_per_staker: number,
    reward_token_info: AssetTokenInfo,
    allowed_collection: string,
    lockup_term: LockupTerm,
    start_time: number,
    end_time: number
  ) => Promise<any>;
}

export interface FactoryContract {
  use: (contractAddress: string) => FactoryInstance;
}

export const Factory = (
  client: SigningCosmWasmClient | CosmWasmClient
): FactoryContract => {
  const use = (contractAddress: string): FactoryInstance => {
    // query
    const config = async (): Promise<any> => {
      return client.queryContractSmart(contractAddress, { config: {} });
    };
    const campaigns = async (limit?: number): Promise<any> => {
      return client.queryContractSmart(contractAddress, {
        campaigns: { limit }
      });
    };

    // action
    const update_config = async (
      sender: string,
      owner?: string,
      campaign_code_id?: number
    ): Promise<string> => {
      if (client instanceof SigningCosmWasmClient) {
        const result = await client.execute(
          sender,
          contractAddress,
          {
            update_config: {
              owner,
              campaign_code_id
            }
          },
          'auto',
          'memo'
        );
        return result.transactionHash;
      }
      return 'Unauthorized';
    };

    const create_campaign = async (
      sender: string,
      campaign_name: string,
      campaign_image: string,
      campaign_description: string,
      limit_per_staker: number,
      reward_token_info: AssetTokenInfo,
      allowed_collection: string,
      lockup_term: LockupTerm,
      start_time: number,
      end_time: number
    ): Promise<string> => {
      if (client instanceof SigningCosmWasmClient) {
        const result = await client.execute(
          sender,
          contractAddress,
          {
            create_campaign: {
              campaign_name,
              campaign_image,
              campaign_description,
              limit_per_staker,
              reward_token_info,
              allowed_collection,
              lockup_term,
              start_time,
              end_time
            }
          },
          'auto',
          'memo'
        );
        return result.transactionHash;
      }
      return 'Unauthorized';
    };
    const update_campaign = async (
      sender: string,
      contract_addr: string,
      campaign_name: string,
      campaign_image: string,
      campaign_description: string,
      limit_per_staker: number,
      reward_token_info: AssetTokenInfo,
      allowed_collection: string,
      lockup_term: LockupTerm,
      start_time: number,
      end_time: number
    ): Promise<string> => {
      if (client instanceof SigningCosmWasmClient) {
        const result = await client.execute(
          sender,
          contractAddress,
          {
            update_campaign: {
              contract_addr,
              campaign_name,
              campaign_image,
              campaign_description,
              limit_per_staker,
              reward_token_info,
              allowed_collection,
              lockup_term,
              start_time,
              end_time
            }
          },
          'auto',
          'memo'
        );
        return result.transactionHash;
      }
      return 'Unauthorized';
    };

    return {
      contractAddress,
      config,
      campaigns,
      update_config,
      create_campaign,
      update_campaign
    };
  };
  return { use };
};
