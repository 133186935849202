import * as React from 'react';
const SvgArrowDown = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16px"
    height="16px"
    fill="none"
    viewBox="0 0 12 12"
    role={'icon-default'}
    {...props}
  >
    <path
      stroke={'currentColor'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M8.4 4.8 6 7.2 3.6 4.8"
    />
  </svg>
);
export default SvgArrowDown;
