import ModalBase from 'components/base/ModalBase';
import styles from './styles.module.scss';
import { useStoreModal } from 'context/StoreModal'
import React from 'react'
import ButtonGradient from 'components/base/ButtonGradient';
import { loadKeplrWallet, useSdk } from 'core/logic';
import { config } from 'config';
import { setLocalStorage } from 'helpers/localStorage';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { selectChooseWallet } from 'store/selectors';
import { postFirstConnect } from 'api/firstconnect.api';
import { Player } from '@lottiefiles/react-lottie-player';

const TosPolicyModal = () => {
    const { isOpen, setIsOpen } = useStoreModal("tos-policy");
    const { init, setInitializing } = useSdk();
    const chooseWallet = useSelector(selectChooseWallet);
    return (
        <ModalBase
            open={isOpen}
            onClose={() => {
                setIsOpen(false);
            }}>
            <div className={styles['body']}>
                <div className={styles['content']}>
                    <h3 className={styles['title']}>ToS & Privacy Policy</h3>
                    <label className={styles['description']}>Welcome to Stakify!</label>
                    {/* <img src="/christmas-comingsoon.svg" className={styles['christmas-mobile']} />
                     */}
                    <Player
                        src={'/lotties/mascot-noel.json'}
                        className={styles['christmas-mobile']}
                        loop
                        autoplay
                    />
                    <label className={styles['des-2']}>By connecting your wallet and using Stakify, you agree to our <a href='/' target='__blank'>Term of Service</a> and <a href='/' target='__blank'>Privacy Policy</a></label>
                    <div className={styles['btn-frame']}>
                        <ButtonGradient onClick={
                            async () => {
                                setIsOpen(false);
                                const signer = await loadKeplrWallet(config.chainId);
                                init(signer);
                                setInitializing(false);
                                signer.getAccounts().then((data) => {
                                    const addr = data[0]?.address
                                    postFirstConnect({
                                        wallet: addr
                                    })
                                    setLocalStorage('LAST_USED_PROVIDER', { provider: chooseWallet, timestamp: moment().toDate().getTime(), address: addr });
                                })
                            }
                        }>Accept and Sign</ButtonGradient>
                        <ButtonGradient onClick={
                            () => { setIsOpen(false) }
                        }>
                            Cancel
                        </ButtonGradient>
                    </div>
                </div>
                <Player
                    src={'/lotties/mascot-noel.json'}
                    className={styles['christmas']}
                    loop
                    autoplay
                />
                {/* <img src="/christmas-comingsoon.svg" className={styles['christmas']} /> */}
            </div>
        </ModalBase >
    )
}

export default TosPolicyModal