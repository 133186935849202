import ButtonGradient from 'components/base/ButtonGradient';
import styles from './styles.module.scss';
import { Back, Close, Copy, LinkExternal, Logout, Search, Wallet } from 'assets/svg-component';
import { useStoreModal } from 'context/StoreModal';
import { useSdk } from 'core/logic';
import { collapseAddress } from 'utils/string';
import { appSetting } from 'constants/configuration';
import React, { useEffect, useRef } from 'react';
import { deleteLocalStorage, getLocalStorage } from 'helpers/localStorage';
import { toast } from 'react-toastify';
import classNames from 'classnames';
import { useLocation, useNavigate } from 'react-router-dom';
import logoIcon from 'assets/images/logo-icon.svg';
import ActionIcon from 'components/base/ActionIcon';
import { useSearch } from 'context/SearchContext';

const Bounce = require('react-reveal/Bounce');

interface HeaderProps { }

const Header: React.FC<HeaderProps> = () => {
  // const dispatch = useDispatch();
  // const currentTheme = useSelector(selectCurrentTheme);
  const loginRef = useRef<HTMLDivElement | null>(null);
  const { setIsOpen } = useStoreModal('connect-wallet');
  const { initializing, initialized, address, clear } = useSdk();
  const [showDropDown, setShowDropDown] = React.useState<boolean>(false);
  const { valueSearch, setValueSearch } = useSearch();
  const navigate = useNavigate();
  const location = useLocation();
  function onClickItem(callback = () => { }) {
    setShowDropDown(prev => !prev);
    callback();
  }
  useEffect(() => {
    function closeDropdown(e: any) {
      const cond = loginRef?.current ? (e.target === loginRef.current || loginRef.current.contains(e.target)) : null;
      if (showDropDown && !cond) setShowDropDown(false);
    }
    window.addEventListener('click', closeDropdown);
    return () => {
      window.removeEventListener('click', closeDropdown);
    };
  }, [showDropDown]);
  function search() {
    if (location?.pathname !== "/search") {
      if (valueSearch) {
        navigate(`/search?name=${valueSearch}`);
        setValueSearch("");
      } else {

        toast.error("Please enter campaign name");

      }
    }

  }
  function clearSearch() {
    setValueSearch('');
  }
  return (
    <header className={classNames(styles['header'], {
      [styles['in-search-page']]: location?.pathname === "/search"
    })}>
      <div className={styles['left-side']}>
        {
          location?.pathname === "/search" ? <ActionIcon
            icon={<Back />}
            onClick={() => {
              navigate('/');
            }}
          /> : <img src={logoIcon} className={styles['logo-icon']} alt='Logo Stakify' />
        }
        <div className={styles['search']}>
          <Search onClick={() => {
            search();
          }} />
          <input placeholder="Search a campaign" type="text" value={valueSearch} onChange={(e) => { setValueSearch(e.target.value) }} onKeyDown={(event) => {
            if (event.key === "Enter" && location?.pathname !== "/search") {
              // Cancel the default action, if needed
              event.preventDefault();
              // Trigger the button element with a click
              search();
            }
          }} />
          {valueSearch && <Close onClick={clearSearch} />}
        </div>
      </div>
      <div className={styles['frame-btn']}>
        {initializing ? (
          <></>
        ) : (
          <>
            {initialized && (
              <div className={styles['network']}>
                <img src="/aura.svg" alt="aura" />
                <label>{appSetting.chain}</label>
              </div>
            )}
            <ActionIcon className={styles['search-mobile']} icon={<Search />} onClick={() => {
              navigate('/search');
            }} />

            <div className={styles['btn-connect']} ref={loginRef}>

              <ButtonGradient

                title={address ? address : 'Please connect wallet'}
                iconBefore={<Wallet />}

                className={styles['btn-login']}
                onClick={() => {
                  if (initialized) {
                    // clear();
                    setShowDropDown(prev => !prev)
                  } else {
                    setIsOpen(true);
                  }
                }}
              >
                <span>{initialized ? collapseAddress(address) : 'Connect'}</span>

              </ButtonGradient>
              <Bounce when={initialized && showDropDown}>
                <div className={classNames(styles['dropdown'], {
                  [styles['open']]: initialized && showDropDown,
                })} >
                  <div className={styles['type-wallet']}>
                    <label className={styles['wallet-label']}>Wallet</label>
                    <label className={styles['wallet-name']}>{getLocalStorage("LAST_USED_PROVIDER")?.provider}</label>
                  </div>
                  <div className={styles['dropdown-item']}>
                    <Copy />
                    <label className={styles['dropdown-item__label']} onClick={() => {
                      onClickItem(() => {
                        navigator.clipboard.writeText(address);
                        toast.success("Copy successfully")
                      })
                    }}>Copy wallet</label>
                  </div>
                  <div className={styles['dropdown-item']} onClick={() => {
                    window.open(`${appSetting.scan}/account/${address}`);
                  }}>
                    <LinkExternal />
                    <label className={styles['dropdown-item__label']}>Go to wallet</label>
                  </div>
                  <div className={styles['dropdown-item']} onClick={() => {
                    onClickItem(() => {
                      clear();
                      deleteLocalStorage("LAST_USED_PROVIDER")
                    })
                  }}>
                    <Logout />
                    <label className={styles['dropdown-item__label']}>Disconnect</label>
                  </div>
                </div>
              </Bounce>
            </div>
          </>
        )}
      </div>
    </header>
  );
};

export default Header;

// {/* <div className="page-header">
// <div className="container">
//   <div className="page-header__logo">
//     <Link to={URL.HOME}>
//       <img src={logo} alt="logo" />
//     </Link>
//   </div>
//   <div className="page-header__content">
//     <div className="page-header__menu">
//       <Menu />
//     </div>

//     <Space>
//       <div className="theme-toggle">
//         <div
//           className={classnames(
//             'theme-icon',
//             currentTheme === Themes.LIGHT && 'active'
//           )}
//         >
//           <Icon
//             className="icon"
//             component={sunIcon}
//             onClick={() =>
//               currentTheme !== Themes.LIGHT &&
//               dispatch(commonActions.changeTheme())
//             }
//           />
//         </div>
//         <div
//           className={classnames(
//             'theme-icon',
//             currentTheme === Themes.DARK && 'active'
//           )}
//         >
//           <Icon
//             className="icon"
//             component={vectorIcon}
//             onClick={() =>
//               currentTheme !== Themes.DARK &&
//               dispatch(commonActions.changeTheme())
//             }
//           />
//         </div>
//       </div>
//       <ConnectWallet />
//     </Space>
//   </div>
// </div>
// </div> */}
