import React, { useEffect, useState } from 'react'
// import { Chart as ChartJS, registerables, ChartData, ChartOptions } from 'chart.js';
// import { Line } from "react-chartjs-2";
import styles from './styles.module.scss';
import LineChart from './LineChart';
import { ChartAnalysis } from 'interface/campaign/campaign_analysis';
import moment from 'moment';

// ChartJS.register(...registerables);
// ChartJS.register(
//     {
//         id: 'margin',
//         beforeInit: function (chart, args, options) {
//             if (chart?.legend && chart?.legend?.fit) {
//                 const fitValue = chart.legend.fit;
//                 chart.legend.fit = function fit() {
//                     fitValue.bind(chart.legend)();
//                     return this.height += 60;
//                 }
//             }
//         }
//     }
// )

interface ChartProps {
    data: ChartAnalysis[],
}

const data_sample = [
    {
        date: '12 Apr',
        value: 400,
    },
    {
        date: '13 Apr',
        value: 500,
    },
    {
        date: '14 Apr',
        value: 450,
    },
    {
        date: '15 Apr',
        value: 300,
    },
    {
        date: '16 Apr',
        value: 350,
    },
    {
        date: '12 Apr',
        value: 400,
    },
    {
        date: '13 Apr',
        value: 500,
    },
    {
        date: '14 Apr',
        value: 450,
    },
    {
        date: '15 Apr',
        value: 300,
    },
    {
        date: '16 Apr',
        value: 350,
    },
    {
        date: '12 Apr',
        value: 400,
    },
    {
        date: '13 Apr',
        value: 500,
    },
    {
        date: '14 Apr',
        value: 450,
    },
    {
        date: '15 Apr',
        value: 300,
    },
    {
        date: '16 Apr',
        value: 350,
    },
]

const Chart: React.FC<ChartProps> = ({ data }) => {
    const [dataChart, setDataChart] = useState<any>(data);
    useEffect(() => {
        setDataChart(() => {
            let dataSorted = data?.sort((a, b) => {
                return moment(a?.day).diff(b?.day);
            })
            return {
                labels: dataSorted?.map(dt => moment(dt?.day).format("DD MMM")),
                datasets: [{
                    label: 'Staked NFTs in term',
                    data: dataSorted?.map((dt) => dt?.count),
                    // labelT: data_sample?.map((dt) => `${handleMonth(dt?.month)}/${dt?.year}`),
                    backgroundColor: '#7F6AFF',
                    borderColor: '#7F6AFF',
                }]
            }
        })
    }, [data])
    return (
        <div className={styles['chart']}>
            {
                dataChart && <LineChart data={dataChart} options={{
                    // animations: {
                    //     tension: {
                    //         duration: 1000,
                    //         easing: 'linear',
                    //         from: 1,
                    //         to: 0,
                    //         loop: true
                    //     }
                    // },
                    responsive: true,
                    tension: 0.3,
                    elements: {
                        point: {
                            radius: 0
                        }
                    },
                    maintainAspectRatio: false,
                    borderWidth: 1,
                    borderStyle: 'dotted',
                    plugins: {
                        legend: {
                            display: false,
                            align: 'start',
                            labels: {
                                usePointStyle: true,
                                boxWidth: 6,
                                boxHeight: 6,
                                color: '#FFFFFF',
                                font: {
                                    family: 'Poppins',
                                    size: 14,
                                    weight: '600',
                                }
                            }
                        },

                    },
                    scales: {

                        y: {
                            // border: {
                            //     display: false,
                            //     dash: [4, 4],
                            // },
                            beginAtZero: true,

                            ticks: {
                                color: '#fff',
                                maxTicksLimit: 4,
                                font: {
                                    family: 'Poppins',
                                    // weight: 600,
                                    size: 14,
                                },


                            },

                            grid: {
                                color: function (context) {
                                    if (context.tick.value != 0) {
                                        return 'rgba(255, 255, 255, 0.4)';
                                    }
                                    return '#fff';
                                },
                                drawOnChartArea: true,
                                drawTicks: true,
                            }
                        },
                        x: {
                            // border: {
                            //     display: false,
                            //     color: 'white',
                            // },
                            beginAtZero: true,
                            ticks: {
                                maxTicksLimit: 6,
                                color: '#fff',
                                font: {
                                    family: 'Poppins',
                                    // weight: 600,
                                    size: 14,
                                },
                                // beginAtZero: true,

                            },
                            grid: {
                                color: 'rgba(255, 255, 255, 0)',
                                borderColor: 'rgba(255, 255, 255, 0)',  // <-- this line is answer to initial question
                            }
                        }
                    }
                }} />
            }
        </div>
    )
}

export default Chart