import * as React from 'react';
const SvgFileEyes = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16px"
    height="16px"
    fill="none"
    viewBox="0 0 24 24"
    role={'icon-default'}
    {...props}
  >
    <path
      stroke={'currentColor'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M11.4 21.6h-6A2.4 2.4 0 0 1 3 19.2V4.8a2.4 2.4 0 0 1 2.4-2.4h10.8a2.4 2.4 0 0 1 2.4 2.4v4.8m-1.8 6.54v-.063m4.2.114s-.996 2.94-4.2 2.889c-3.204-.052-4.2-2.889-4.2-2.889s.956-2.99 4.2-2.99 4.2 2.99 4.2 2.99Z"
    />
  </svg>
);
export default SvgFileEyes;
