import ModalBase from 'components/base/ModalBase';
import { useStoreModal } from 'context/StoreModal';
import { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import BaseButton from 'components/base/Button';
import ButtonGradient from 'components/base/ButtonGradient';
import { formatNumberCoin } from 'helpers/number';
import { useClient } from 'core/logic/service/public';
import { useAddReward } from 'context/AddReward';
import Icon from '@ant-design/icons';
import { ReactComponent as wallet } from 'assets/images/wallet/wallet-image.svg';
import { Form } from './Form';
import { useSdk } from 'core/logic';
import { toast } from 'react-toastify';
import CustomToastWithLink from 'components/base/CustomToastWithLink';
import { fetchingCampaign } from 'api/campaign/fetching_campaign.api';
import { useCampaignService } from 'context/CampaignService';
import { handleMsgErr } from 'utils/contract-utils';
import { appSetting } from 'constants/configuration';

const AddRewardModal = () => {
  const { isOpen, setIsOpen } = useStoreModal('add-reward');
  const { balance, campaignDetail } = useAddReward();
  const { campaign, cw20 } = useClient();
  const { address, initialized } = useSdk();
  const [depositReward, setDepositReward] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const { fetchDataCreatedCampaign, fetchDataDetailCampaign } =
    useCampaignService();

  useEffect(() => {
    setDepositReward(0);
  }, [campaignDetail]);

  const handleDepositReward = () => {
    if (campaignDetail?.address) {
      let depositToken =
        depositReward * Math.pow(10, Number(campaignDetail?.token_decimal));
      if (depositToken <= 0) {
        toast.error('Deposit rewards must be greater than 0');
      } else if (depositToken > balance) {
        toast.error('Deposit rewards must be less than the existing balance');
      } else {
        setLoading(true);
        campaign
          ?.add_reward_token(
            address,
            depositToken.toLocaleString('fullwide', {
              useGrouping: false
            })
          )
          .then(async (d) => {
            setLoading(false);
            setIsOpen(false);
            await fetchingCampaign({
              campaign_address: campaignDetail?.address
            });
            fetchDataDetailCampaign(undefined);
            toast.success(
              <CustomToastWithLink
                text="Reward has been deposited successfully!"
                transaction={`${appSetting.scan}/transaction/${d}`}
              />
            );
            fetchDataCreatedCampaign();
          })
          .catch((err) => {
            setLoading(false);
            let msg = handleMsgErr(err?.message);
            toast.error(msg);
          });
      }
    }
  };

  return (
    <ModalBase
      open={isOpen}
      onClose={() => {
        setIsOpen(false);
      }}
    >
      <div className={styles['add-reward-modal']}>
        <h6 className={'title-modal'}>Deposit rewards</h6>
        <div className={styles['info-balance']}>
          <Icon className={styles['info-balance__left']} component={wallet} />
          <div className={styles['info-balance__right']}>
            <span className={styles['address-campaign']}>
              {campaignDetail?.address}
            </span>
            <span>
              <label className={styles['title']}> Balance: </label>
              {formatNumberCoin(
                Number(balance),
                campaignDetail?.token_decimal
              )}{' '}
              { } {campaignDetail?.token_symbol}
            </span>
          </div>
        </div>
        <div className={styles['infor-claim']}>
          <label className={styles['title']}>{campaignDetail?.name}</label>
          <span>
            <label className={styles['title']}>Current rewards: </label>
            {formatNumberCoin(
              Number(campaignDetail?.token_amount),
              campaignDetail?.token_decimal
            )}{' '}
            {campaignDetail?.token_symbol}
          </span>
        </div>
        <div className={styles['form']}>
          <Form
            currentAmount={depositReward}
            setCurrentAmount={setDepositReward}
            symbol={campaignDetail?.token_symbol || ''}
          />
          <BaseButton
            className={styles['button']}
            outline="#000"
            onClick={() =>
              setDepositReward(
                balance / Math.pow(10, Number(campaignDetail?.token_decimal))
              )
            }
          >
            Max
          </BaseButton>
        </div>
        <div className={styles['button-frame']}>
          <BaseButton
            outline="#000"
            onClick={() => {
              setIsOpen(false);
            }}
            className={styles['btn']}
          >
            Cancel
          </BaseButton>
          <ButtonGradient
            className={styles['btn']}
            loading={loading}
            disabled={Number(depositReward) === 0}
            onClick={handleDepositReward}
          >
            Submit
          </ButtonGradient>
        </div>
      </div>
    </ModalBase>
  );
};

export default AddRewardModal;
