import ClaimRewardModal from 'components/modal/ClaimRewardModal';
import { useStoreModal } from 'context/StoreModal';
import { CampaignService } from 'interface/campaign/campaign_service';
import { createContext, useState } from 'react';

export enum StatusClaim {
  Success = 'success',
  Fail = 'fail',
  Cancel = 'cancel',
  NotYet = 'not yet',
  Pending = 'pending'
}

export type IClaimRewardContext = {
  isOpen: boolean;
  open: (reward: string, campaign: CampaignService | undefined) => void;
  reward: string;
  campaignDetail: CampaignService | undefined;
  statusClaim: StatusClaim;
  setStatusClaim: (value: StatusClaim) => void;
};

const initValues = {
  isOpen: false,
  open: (reward: string, campaign: CampaignService | undefined) => {},
  reward: '0',
  campaignDetail: undefined,
  statusClaim: StatusClaim.NotYet,
  setStatusClaim: () => {}
};

export const ClaimRewardContext =
  createContext<IClaimRewardContext>(initValues);

const ClaimRewardProvider: React.FC<{ children: React.ReactNode }> = ({
  children
}) => {
  const { isOpen, setIsOpen } = useStoreModal('claim-reward');
  const [campaignDetail, setCampaignDetail] = useState<CampaignService>();
  const [reward, setReward] = useState<string>('0');
  const [statusClaim, setStatusClaim] = useState<StatusClaim>(
    StatusClaim.NotYet
  );
  function open(reward: string, campaign: CampaignService | undefined) {
    setReward(reward);
    setCampaignDetail(campaign);
    setIsOpen(true);
  }
  return (
    <ClaimRewardContext.Provider
      value={{
        open,
        reward,
        campaignDetail,
        isOpen,
        statusClaim,
        setStatusClaim
      }}
    >
      {children}
      <ClaimRewardModal />
    </ClaimRewardContext.Provider>
  );
};

export default ClaimRewardProvider;
