import React, { useEffect, useRef, useState } from 'react';
import styles from './styles.module.scss';
import classNames from 'classnames';

interface TabsDashProps {
  data: string[];
  indexActive: number;
  setIndexActive(value: number): void;
  className?: string;
}

const TabsDash: React.FC<TabsDashProps> = ({
  data,
  indexActive = 0,
  setIndexActive = () => { },
  className
}) => {
  const indicatorRef = useRef<HTMLInputElement>(null);
  const tabsRef = useRef<HTMLInputElement>(null);
  const [tabs, setTabs] = useState<any>([]);

  useEffect(() => {
    if (data?.length > 0) {
      let indicator = indicatorRef.current;
      let tabsQuery = tabsRef.current?.querySelectorAll('.' + styles['item']);
      if (indicator && tabsQuery && tabsQuery[0]?.parentElement) {
        indicator.style.width =
          tabsQuery[0].getBoundingClientRect().width + 'px';
        indicator.style.left =
          tabsQuery[0].getBoundingClientRect().left -
          tabsQuery[0].parentElement.getBoundingClientRect().left +
          'px';
      }
      setTabs(tabsQuery);
      // setIndexActive(0);
    }
  }, [data, setIndexActive]);

  useEffect(() => {
    if (tabs && data?.length > indexActive) {
      let indicator = indicatorRef.current;
      let tab = tabs[indexActive];
      if (indicator) {
        indicator.style.width = tab?.getBoundingClientRect().width + 'px';
        indicator.style.left =
          tab?.getBoundingClientRect().left -
          tab?.parentElement.getBoundingClientRect().left +
          Number(tabsRef.current?.scrollLeft) +
          'px';
      }
    }
  }, [indexActive, data, tabs]);

  return (
    <div ref={tabsRef} className={classNames(styles['tabdash'], className)}>
      <div ref={indicatorRef} className={styles['indicator']}></div>
      {data?.map((item, index) => {
        return (
          <label
            key={index}
            className={styles['item']}
            onClick={() => {
              setIndexActive(index);
            }}
          >
            {item}
          </label>
        );
      })}
    </div>
  );
};

export default TabsDash;
