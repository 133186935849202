import { request } from "api/request";
import { appSetting } from "constants/configuration";


export const fetchNftInfo = async (token_ids: any[], address: string | undefined) => {
  let payload = {
    'query': 'query q($address: String, $tokenId: [String!]) {\n' +
      '      ' + appSetting.prefix_network + ' { \n' +
      '        data: smart_contract(where: {address: {_eq: $address}}) {\n' +
      '          cw721_contract {\n' +
      '            cw721_tokens(where: {token_id: {_in: $tokenId}}) {\n' +
      '              media_info\n' +
      '              token_id\n' +
      '            }\n' +
      '          }\n' +
      '        }\n' +
      '      } \n' +
      '    }',
    'variables': {
      "address": address,
      "tokenId": token_ids
    },
    'operationName': 'q',
  };
  return await request<any>('post', appSetting.query, JSON.stringify(payload),
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
}