import { getCampaignServiceDetail } from 'api/campaign/campaign_detail.api';
import {
  getCampaignService,
  getCampaignServiceOwner,
  getCampaignServiceStaked
} from 'api/campaign/campaign_service.api';
import { getStakingHistory } from 'api/campaign/staking_history.api';
import { useSdk } from 'core/logic';
import { useClient } from 'core/logic/service/public';
import {
  // CampaignCreated,
  CampaignService,
  Staker
} from 'interface/campaign/campaign_service';
// import { StakingHistory } from 'interface/campaign/staking_history';
import React, { createContext, useEffect, useReducer } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { fetchNftInfo } from 'hooks/api/fetchNftInfo';
import { useStakeNFTs } from 'context/StakeNFTs';
import {
  IStateCampaignService,
  constState,
  initialState,
  reducer
} from './reduceState';
// import { CampaignAnalysis } from 'interface/campaign/campaign_analysis';
import { getCampaignAnalysis } from 'api/campaign/campaign_analysis.api';
import { handleMsgErr } from 'utils/contract-utils';
import { NftKey } from 'core/logic/service/campaign';
import { appSetting } from 'constants/configuration';
import { formatNumberCoin } from 'helpers/number';
import Decimal from 'decimal.js';
import { addLargeNumbers } from 'utils/string';

export interface ICampaignService extends IStateCampaignService {
  fetchDataPublicCampaign: () => void;
  fetchDataStakedCampaign: () => void;
  fetchDataCreatedCampaign: () => void;
  fetchDataDetailCampaign: (addr: string | undefined) => void;
  fetchDataStakingHistory: () => Promise<any>;
  requestListStakeNFT: (campaignDt: CampaignService | undefined) => void;
  requestInforReward: (campaignDt: CampaignService | undefined) => void;
}

const initValues = {
  fetchDataPublicCampaign: () => {},
  fetchDataStakedCampaign: () => {},
  fetchDataCreatedCampaign: () => {},
  fetchDataDetailCampaign: () => {
    return new Promise(() => {});
  },
  fetchDataStakingHistory: () => {
    return new Promise(() => {});
  },
  requestListStakeNFT: (campaignDt: CampaignService | undefined) => {},
  requestInforReward: (campaignDt: CampaignService | undefined) => {},
  ...initialState
};

export const CampaignServiceContext =
  createContext<ICampaignService>(initValues);

const CampaignServiceProvider: React.FC<{ children: React.ReactNode }> = ({
  children
}) => {
  const location = useLocation();
  const { isOpen: isOpenStakeNFTs } = useStakeNFTs();
  const { initialized, address } = useSdk();
  const { campaign, createCampaign, campaignClientAddress, campaignContract } =
    useClient();

  const [states, dispatch] = useReducer(reducer, initialState);
  const { campaignDetail, addressContract } = states;

  async function requestListStakeNFT(campaignDt?: CampaignService | undefined) {
    const campaignData: CampaignService | undefined =
      campaignDt || campaignDetail;
    if (
      campaign &&
      initialized &&
      campaign?.contractAddress === addressContract
    ) {
      await campaign.nft_staked(address).then(async (data) => {
        let staker: Staker = { ...data, nfts: [] };

        let keys: NftKey[] = [...data?.keys];

        let token_ids: string[] = [];

        for (const key of keys) {
          await campaign
            .nft_info(key)
            .then((data) => {
              staker.nfts.push(data);
              token_ids.push(data.token_id);
            })
            .catch((err) => {
              console.log(err);
            });
        }

        await fetchNftInfo(token_ids, campaignData?.collection_address).then(
          (data: any) => {
            var firstX;
            if (appSetting.prefix_network === 'xstaxy') {
              firstX = data?.data?.xstaxy;
            } else {
              firstX = data?.data?.euphoria;
            }
            const data_nft_info: any[] =
              firstX?.data[0]?.cw721_contract?.cw721_tokens;

            staker.nfts.forEach((nft, index) => {
              let info = data_nft_info.find(
                (n) => n?.token_id === nft?.token_id
              );
              staker.nfts[index].media_info = info.media_info;
            });
          }
        );

        let pending_reward: string = staker.reward_debt;
        // console.log({ pending: staker.reward_debt });

        staker.nfts.forEach((nft) => {
          // pending_reward = pending_reward.add(new Decimal(nft?.pending_reward));
          pending_reward = addLargeNumbers(pending_reward, nft?.pending_reward);
        });

        // console.log({ pending_reward: pending_reward.toString() });

        // console.log({ decimal: Number(campaignDetail?.token_decimal), h: formatNumberCoin(Number(staker.reward_debt), Number(campaignDetail?.token_decimal)), v })

        // await campaign.total_pending_reward().then(data => {
        //   console.log({ r: data })
        // })

        dispatch({
          type: constState?.nftStaked,
          value: staker.nfts
        });

        dispatch({
          type: constState?.rewardDebt,
          value: pending_reward
        });
        dispatch({
          type: constState?.rewardClaimed,
          value: staker.reward_claimed
        });
      });
    }
  }

  async function requestInforReward(campaignDt?: CampaignService | undefined) {
    const campaignData: CampaignService | undefined =
      campaignDt || campaignDetail;
    if (
      campaign &&
      initialized &&
      campaign?.contractAddress === addressContract
    ) {
      await campaign
        .campaign_info()
        .then((data) => {
          dispatch({
            type: constState?.rewardClaimed,
            value: data?.total_reward_claimed
          });
        })
        .catch((err) => {
          let msg = handleMsgErr(err?.message);
          toast.error(msg);
        });

      await campaign
        ?.total_pending_reward()
        .then((data) => {
          dispatch({
            type: constState?.rewardDebt,
            value: data?.total_reward_claimed
          });
        })
        .catch((err) => {
          let msg = handleMsgErr(err?.message);
          toast.error(msg);
        });
    }
  }

  async function fetchDataCreatedCampaign() {
    dispatch({
      type: constState?.isLoadingCreatedCampaign,
      value: true
    });
    await getCampaignServiceOwner({ address: address })
      .then((data) => {
        dispatch({
          type: constState?.createdCampaign,
          value: data?.data
        });
        dispatch({
          type: constState?.isLoadingCreatedCampaign,
          value: false
        });
      })
      .catch((err) => {
        dispatch({
          type: constState?.isLoadingCreatedCampaign,
          value: false
        });
        let msg = handleMsgErr(err?.message);
        toast.error(msg);
      });
  }

  async function fetchDataPublicCampaign() {
    dispatch({
      type: constState?.isLoadingPublicCampaign,
      value: true
    });
    await getCampaignService({ type: 'all', address: 'public' })
      .then((data) => {
        dispatch({
          type: constState?.publicCampaign,
          value: data?.data
        });
        dispatch({
          type: constState?.isLoadingPublicCampaign,
          value: false
        });
      })
      .catch((err) => {
        dispatch({
          type: constState?.isLoadingPublicCampaign,
          value: false
        });
        let msg = handleMsgErr(err?.message);
        toast.error(msg);
      });
  }

  async function fetchDataStakedCampaign() {
    dispatch({
      type: constState?.isLoadingStakedCampaign,
      value: true
    });
    getCampaignServiceStaked({ address: address })
      .then((data: any) => {
        dispatch({
          type: constState?.stakedCampaign,
          value: data?.data
        });
        dispatch({
          type: constState?.isLoadingStakedCampaign,
          value: false
        });
      })
      .catch((err) => {
        dispatch({
          type: constState?.isLoadingStakedCampaign,
          value: false
        });
        let msg = handleMsgErr(err?.message);
        toast.error(msg);
      });
  }

  function fetchDataDetailCampaign(addr: string | undefined) {
    var addTemp = addressContract || addr;
    if (addTemp) {
      getCampaignServiceDetail({
        address: addTemp
      })
        .then(async (data) => {
          dispatch({
            type: constState?.campaignDetail,
            value: data?.data
          });
          dispatch({
            type: constState?.isLoadingCampaignDetail,
            value: false
          });
        })
        .catch((err) => {
          let msg = handleMsgErr(err?.message);
          toast.error(msg);
        });
    }
  }

  async function fetchDataStakingHistory() {
    if (addressContract) {
      await getStakingHistory({
        campaign_address: addressContract,
        owner_address: address
      })
        .then((data) => {
          dispatch({
            type: constState?.stakingHistory,
            value: data?.data
          });
        })
        .catch((err) => {
          let msg = handleMsgErr(err?.message);
          toast.error(msg);
        });
    }
  }

  useEffect(() => {
    (async function updateData() {
      await fetchDataPublicCampaign();
    })();
  }, []);

  useEffect(() => {
    (async function updateDataInitlized() {
      if (initialized) {
        await fetchDataStakedCampaign();
        await fetchDataCreatedCampaign();
      }
    })();
  }, [initialized, address]);

  useEffect(() => {
    if (!isOpenStakeNFTs && campaignDetail) {
      requestListStakeNFT(campaignDetail);
    }
  }, [isOpenStakeNFTs]);

  useEffect(() => {
    let check =
      location?.pathname?.includes('/detail-campaign/') ||
      location?.pathname?.includes(
        '/created-campaign/campaign-created-detail/'
      );
    if (check) {
      let addr = location?.pathname?.replace('/detail-campaign/', '');
      addr = addr?.replace('/created-campaign/campaign-created-detail/', '');
      dispatch({
        type: constState?.addressContract,
        value: addr
      });
    } else {
      dispatch({
        type: constState?.addressContract,
        value: ''
      });
    }
  }, [location]);

  useEffect(() => {
    if (addressContract) {
      createCampaign(addressContract);
    }
    if (addressContract && initialized) {
      const is_po = location.pathname.includes('campaign-created-detail');
      const param = is_po
        ? {
            campaign_address: addressContract
          }
        : {
            campaign_address: addressContract,
            owner_address: address
          };
      let dataStakingHistory = getStakingHistory(param);
      dataStakingHistory.then((data) => {
        dispatch({
          type: constState?.stakingHistory,
          value: data?.data
        });
      });
    }
  }, [addressContract, initialized, campaignContract]);

  useEffect(() => {
    (async function updateCampaignInfor(): Promise<void> {
      try {
        let campaignData: CampaignService | undefined = undefined;
        if (
          location?.pathname?.includes('detail-campaign') ||
          location?.pathname?.includes('campaign-created-detail')
        ) {
          dispatch({
            type: constState?.isLoadingCampaignDetail,
            value: true
          });
          if (campaign && addressContract) {
            await getCampaignServiceDetail({
              address: addressContract
            })
              .then(async (data) => {
                dispatch({
                  type: constState?.campaignDetail,
                  value: data?.data
                });
                campaignData = data?.data;
                if (location?.pathname?.includes('detail-campaign')) {
                  await requestListStakeNFT(campaignData);
                } else {
                  await requestInforReward(campaignData);
                  await getCampaignAnalysis({
                    address: addressContract,
                    owner: address
                  }).then((data) => {
                    dispatch({
                      type: constState?.campaignAnalysis,
                      value: data?.data
                    });
                  });
                }
                dispatch({
                  type: constState?.isLoadingCampaignDetail,
                  value: false
                });
              })
              .catch((err) => {
                dispatch({
                  type: constState?.isLoadingCampaignDetail,
                  value: false
                });
                let msg = handleMsgErr(err?.message);
                toast.error(msg);
              });
          }
        }
      } catch (error: any) {
        toast.error(error.message);
        dispatch({
          type: constState?.isLoadingCampaignDetail,
          value: false
        });
      }
    })();
  }, [campaignClientAddress, initialized, addressContract]);

  return (
    <CampaignServiceContext.Provider
      value={{
        ...states,
        fetchDataPublicCampaign,
        fetchDataStakedCampaign,
        fetchDataCreatedCampaign,
        fetchDataDetailCampaign,
        fetchDataStakingHistory,
        requestListStakeNFT,
        requestInforReward
      }}
    >
      {children}
    </CampaignServiceContext.Provider>
  );
};

export default CampaignServiceProvider;
