import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import classNames from 'classnames';

// const dataTerms = [
//   {
//     title: 'Flexible',
//     value: 0
//   },
//   {
//     title: 'Fixed term',
//     value: 15
//   },
//   {
//     title: 'Fixed term',
//     value: 30
//   },
//   {
//     title: 'Fixed term',
//     value: 60
//   }
// ];

interface LockupTermsProps {
  setValueTerm: (value: number) => void;
  data: any;
}

const LockupTerms: React.FC<LockupTermsProps> = ({
  setValueTerm = () => { },
  data,
}) => {
  const [active, setActive] = useState<number>(0);
  useEffect(() => {
    setValueTerm(data[active]?.value);
  }, [active, setValueTerm, data]);
  return (
    <div className={styles['lockup-terms']}>
      {data?.map((item: any, index: number) => {
        return (
          <div
            className={classNames(styles['term'], {
              [styles['active']]: active === index
            })}
            key={index}
            onClick={() => {
              setActive(index);
            }}
          >
            <label className={styles['title']}>Fixed term</label>
            {item?.value !== 0 && (
              <label className={styles['value']}>{(item?.value / 86400).toFixed(0)} days</label>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default LockupTerms;
