import React, { useState } from 'react';
import styles from './styles.module.scss';
import { formatNumber } from 'helpers/number';

interface FormProps {
  readonly currentAmount: number;
  readonly setCurrentAmount: (value: React.SetStateAction<number>) => void;
  readonly symbol: string;
}

export const Form: React.FC<FormProps> = ({
  currentAmount,
  setCurrentAmount,
  symbol
}) => {
  const [value, setValue] = useState('');
  return (
    <div className={styles['form']}>
      {/* <input
        placeholder="Enter deposit amount"
        type="number"
        value={Number(currentAmount) === 0 ? '' : currentAmount}
        onChange={(t) => {
          setCurrentAmount(Number(t.target.value));
        }}
      /> */}
      <input
        placeholder="Enter deposit amount"
        type="text"
        value={value}
        onChange={(t) => {
          const vl = t.target.value.replace(/,/g, '');
          const validNumber = /^\d*\.?\d*$/.test(vl);
          if (validNumber) {
            setCurrentAmount(Number(vl));
            setValue(formatNumber(Number(vl)));
          }
        }}
      />
      <label htmlFor="">{symbol}</label>
    </div>
  );
};
