import { configureStore, combineReducers } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import { apiMapping } from 'services';
import { reducer as common } from 'store/common';
import { reducer as user } from 'store/user.store';
import { reducer as global } from 'store/global.store';
import { reducer as tagsView } from 'store/tags-view.store';

const reducerList = { common, user, global, tagsView };

export const rootReducer = combineReducers(reducerList);

const store = configureStore({
  reducer: rootReducer,
  middleware: [thunk.withExtraArgument({ ...apiMapping })]
});

export default store;
export type AppState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;

export type AppStore = typeof store;
