

import { PageData, PageData2 } from 'interface';
import { request_new } from '../request';
import { CampaignCreated, CampaignService } from 'interface/campaign/campaign_service';

export const getCampaignService = async (params: any) => {
    // await fetchingFactory({});
    return request_new<PageData<CampaignService>>('get', '/api/public/CampaignService/all', params)
};

export const getCampaignServiceStaked = async (params: any) => {
    // await fetchingFactory({});
    return request_new<PageData<CampaignService>>('get', '/api/public/UserService/staked-campaign', params)
};

export const getCampaignServiceOwner = async (params: any) => {
    // await fetchingFactory({});
    return request_new<PageData2<CampaignCreated>>('get', '/api/public/UserService/created-campaign', params)
};
