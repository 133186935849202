import Tabs from 'components/common/Tabs';
import TabsDash from 'components/common/TabsDash';
// import Information from "components/pages/your-created-campaign/Information";
import { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import Dropdown from 'components/base/Dropdown';
import ListCampaign from 'components/pages/public-campaign/ListCampaign';

import InforCard from 'components/common/InforCard';
import { Calendar, ColorSwatch, Locked } from 'assets/svg-component';
import { useSdk } from 'core/logic';
import MascotNotice from 'components/common/MascotNotice';
import ButtonGradient from 'components/base/ButtonGradient';
import ListStakedCampaign from 'components/pages/public-campaign/ListStakedCampaign';
import { useClient } from 'core/logic/service/public';

import { UserService } from 'interface/campaign/user_service';
import { getUserService } from 'api/campaign/user_service.api';
import { formatNumber } from 'helpers/number';
import { appSetting } from 'constants/configuration';
import { useCampaignService } from 'context/CampaignService';
import { Footer, Loading } from 'components';


// import Fade from 'react-reveal/Fade';

const Fade = require('react-reveal/Fade');

interface PublicCampaignProps { }

const statuses = ['All', 'Active', 'Upcoming', 'Ended'];

const PublicCampaign: React.FC<PublicCampaignProps> = () => {
  const [indexTabDash, setIndexTabDash] = useState<number>(0);
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [indexTabStaked, setIndexTabStaked] = useState<number>(0);
  const [valueDropdown, setValueDropdown] = useState<string>('');
  const { initialized, address } = useSdk();
  const [dataTabPublicCampaign, setDataTabPublicCampaign] = useState<string[]>([
    'Public campaign'
  ]);

  const { factoryContract, createFactory, client } = useClient();
  const [userInfor, setUserInfor] = useState<UserService>();
  // const { factoryInstance } = useContractFactory();

  const {
    publicCampaign,
    isLoadingPublicCampaign,
    stakedCampaign,
    isLoadingStakedCampaign,
    fetchDataStakedCampaign,
    fetchDataPublicCampaign
  } = useCampaignService();

  useEffect(() => {
    if (initialized) {
      setDataTabPublicCampaign(['Public campaign', 'Staked campaign']);
      getUserService({ address }).then((dt) => setUserInfor(dt?.data));
    } else {
      setDataTabPublicCampaign(['Public campaign']);
      setIndexTabDash(0);
    }
  }, [initialized]);
  useEffect(() => {
    if (factoryContract) {
      createFactory(appSetting.factoryCampaign?.toString() || '');
    }
  }, [factoryContract]);
  useEffect(() => {
    if (indexTabDash === 1) {
      fetchDataStakedCampaign();
    } else {
      fetchDataPublicCampaign();
    }
  }, [indexTabDash]);

  return (
    <div className={styles['public-campaign']}>
      {/* <Information /> */}
      <TabsDash
        data={dataTabPublicCampaign}
        indexActive={indexTabDash}
        setIndexActive={setIndexTabDash}
      />
      {indexTabDash === 0 ? (
        <Fade>
          {isLoadingPublicCampaign ? (
            <Loading />
          ) : (
            <>
              <div className={styles['filter']}>
                <Tabs
                  tabs={statuses}
                  indexActive={activeIndex}
                  setIndexActive={setActiveIndex}
                />
                <Dropdown
                  data={[
                    {
                      label: 'Default',
                      value: 'default'
                    },
                    {
                      label: 'Rewards high to low',
                      value: 'high-to-low'
                    },
                    {
                      label: 'Most staked',
                      value: 'most-staked'
                    },
                    {
                      label: 'Recently added',
                      value: 'recently-added'
                    },
                    {
                      label: 'Ending soon',
                      value: 'ending-soon'
                    }
                  ]}
                  setValue={setValueDropdown}
                />
              </div>
              <ListCampaign
                data={publicCampaign}
                filterStatus={statuses[activeIndex]}
                filterSorting={valueDropdown}
              />
            </>
          )}
        </Fade>
      ) : (
        <Fade>
          <div className={styles['information']}>
            <InforCard
              icon={<Locked />}
              text={`${formatNumber(Number(userInfor?.total_stake_nft))} NFTs`}
              description="Total locked NFTs"
            />
            <InforCard
              icon={<Calendar />}
              text={`${formatNumber(Number(userInfor?.total_join_campaign))}`}
              description="Total joined campaigns"
            />
            <InforCard
              icon={<ColorSwatch />}
              text={`${formatNumber(Number(userInfor?.can_unstake_nft))} NFTs`}
              description="Can unstake"
            />
          </div>
          {isLoadingStakedCampaign ? (
            <Loading />
          ) : (
            <>
              <Tabs
                tabs={['Active', 'Ended']}
                indexActive={indexTabStaked}
                setIndexActive={setIndexTabStaked}
              />

              <div className={styles['body-staked-campaign']}>
                {stakedCampaign &&
                  (stakedCampaign?.length > 0 ? (
                    <ListStakedCampaign
                      data={stakedCampaign}
                      indexFilterStatus={
                        indexTabStaked === 0 ? 'Active' : 'Ended'
                      }
                    />
                  ) : (
                    <MascotNotice
                      text="You don’t have any campaign yet!"
                      img="/lotties/mascot-sleep.json"
                      className={styles['mascot-notice']}
                    >
                      <ButtonGradient
                        className={styles['start-staking']}
                        onClick={() => {
                          setIndexTabDash(0);
                        }}
                      >
                        Start Staking
                      </ButtonGradient>
                    </MascotNotice>
                  ))}
              </div>
            </>
          )}
        </Fade>
      )}
      <Footer isMobile={true} />
    </div>
  );
};

export default PublicCampaign;
