import StakeNFTsModal from 'components/modal/StakeNFTsModal';
import { useSdk } from 'core/logic';
import { CampaignService } from 'interface/campaign/campaign_service';
import React, { createContext, useState } from 'react';
import { toast } from 'react-toastify';

export type IStakeNFTs = {
  isOpen: boolean;
  openStakeNFTs: (data_campaign: CampaignService) => void;
  closeStakeNFTs: () => void;
  collectionAddress: string;
  campaignAddress: string;
  dataAfterStake: any;
  setDataAfterStake: (data: any) => void;
};

const initValues = {
  isOpen: false,
  openStakeNFTs: (data_campaign: CampaignService) => { },
  closeStakeNFTs: () => { },
  collectionAddress: '',
  campaignAddress: '',
  dataAfterStake: '',
  setDataAfterStake: (data: any) => { }
};

export const StakeNFTsContext = createContext<IStakeNFTs>(initValues);

const StakeNFTsProvider: React.FC<{ children: React.ReactNode }> = ({
  children
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { initialized } = useSdk();
  const [collectionAddress, setCollectionAddress] = useState<string>('');
  const [campaignAddress, setCampaignAddress] = useState<string>('');
  const [campaignDetail, setCampaignDetail] = useState<CampaignService | undefined>();
  const [dataAfterStake, setDataAfterStake] = useState<any>();
  function openStakeNFTs(data_campaign: CampaignService) {
    if (initialized) {
      setIsOpen(true);
      setCollectionAddress(data_campaign?.collection_address);
      setCampaignAddress(data_campaign?.address);
      setCampaignDetail(data_campaign);
    } else {
      toast.error('Please connect to your wallet');
    }
  }
  function closeStakeNFTs() {
    setIsOpen(false);
  }
  return (
    <StakeNFTsContext.Provider
      value={{ isOpen, openStakeNFTs, closeStakeNFTs, collectionAddress, campaignAddress, dataAfterStake, setDataAfterStake }}
    >
      <StakeNFTsModal isOpen={isOpen} setIsOpen={setIsOpen} campaignDetail={campaignDetail} />
      {children}
    </StakeNFTsContext.Provider>
  );
};

export default StakeNFTsProvider;
