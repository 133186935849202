import ModalBase from 'components/base/ModalBase';
import { useStoreModal } from 'context/StoreModal';
import React, { useEffect } from 'react';
import styles from './styles.module.scss';
import moment from 'moment';
import BaseButton from 'components/base/Button';
import ButtonGradient from 'components/base/ButtonGradient';
import classNames from 'classnames';
import { StatusClaim, useClaimReward } from 'context/ClaimReward';
import { formatNumberCoin } from 'helpers/number';
import { useClient } from 'core/logic/service/public';
import { useSdk } from 'core/logic';
import { toast } from 'react-toastify';
import { handleMsgErr } from 'utils/contract-utils';
import { useCampaignService } from 'context/CampaignService';

const ClaimRewardModal = () => {
  const { isOpen, setIsOpen } = useStoreModal('claim-reward');
  const { campaignDetail, reward, setStatusClaim } = useClaimReward();
  const { campaign } = useClient();
  const { address } = useSdk();
  const { requestListStakeNFT } = useCampaignService();

  const [loadingBtn, setLoadingBtn] = React.useState(false);

  const claimReward = async () => {
    setLoadingBtn(true);
    setStatusClaim(StatusClaim.Pending);
    // console.log({ reward });

    await campaign
      ?.claim_reward(address, reward)
      .then((data) => {
        setIsOpen(false);
        setLoadingBtn(false);
        setStatusClaim(StatusClaim.Success);
        requestListStakeNFT(undefined);
        toast.success('Claim successfully');
      })
      .catch((err) => {
        setLoadingBtn(false);
        setStatusClaim(StatusClaim.NotYet);
        let msg = handleMsgErr(err?.message);
        toast.error(msg);
      });
  };

  useEffect(() => {
    if (!isOpen) setLoadingBtn(false);
  }, [isOpen]);

  return (
    <ModalBase
      open={isOpen}
      onClose={() => {
        setIsOpen(false);
      }}
    >
      <div className={styles['claim-reward-modal']}>
        <h6 className={'title-modal'}>Claim rewards</h6>
        <div className={styles['infor-claim']}>
          <div className={styles['row-infor']}>
            <label className={styles['title']}>Claim date:</label>
            <label className={styles['value']}>
              {moment().format('hh:mm A, DD MMM YYYY')}
            </label>
          </div>
          <div className={styles['row-infor']}>
            <label className={styles['title']}>Reward receive:</label>
            <label className={classNames(styles['value'], styles['reward'])}>
              {formatNumberCoin(Number(reward), campaignDetail?.token_decimal)}{' '}
              {campaignDetail?.token_symbol}
            </label>
          </div>
        </div>
        <div className={styles['button-frame']}>
          <BaseButton
            outline="#000"
            onClick={() => {
              setIsOpen(false);
            }}
          >
            Cancel
          </BaseButton>
          <ButtonGradient onClick={claimReward} loading={loadingBtn}>
            Confirm
          </ButtonGradient>
        </div>
      </div>
    </ModalBase>
  );
};

export default ClaimRewardModal;
