import React, { useEffect, useMemo, useState } from 'react';
import styles from './style.module.scss';
import { Information } from 'assets/svg-component';
import Tooltip from 'components/base/Tooltip';
import { CampaignService } from 'interface/campaign/campaign_service';
import { checkStatus2 } from 'utils/contract-utils';

import RowCreatedCampaign from './RowCreatedCampaign';

interface TableCampaignProps {
  data: CampaignService[] | undefined;
  filterStatus: string;
}

const TableCampaign: React.FC<TableCampaignProps> = ({
  data = [],
  filterStatus = ''
}) => {
  const [dataList, setDataList] = useState(data || []);





  //loading state



  useEffect(() => {
    let dt = [...data];
    if (data?.length > 0) {
      if (filterStatus?.toLowerCase() === 'all') {
        dt = dt;
      } else {
        dt = dt?.filter((item, index) => {
          return (
            checkStatus2(item?.start, item?.end, item).toLowerCase() ===
            filterStatus?.toLowerCase()
          );
        });
      }
    }
    setDataList(dt);
  }, [data, filterStatus]);






  return (
    <div className={styles['table-campaign']}>
      <table className={styles['table']}>
        <thead>
          <tr>
            <th>Campaigns</th>
            <th>Total staked</th>
            <th>
              <div className={styles['th-infor']}>
                <label>Daily rewards</label>
                <Information />
                <Tooltip text="Estimated daily rewards per NFT" />
              </div>
            </th>
            <th>Status</th>
            <th>Remaining rewards</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {dataList?.map((item: CampaignService, index: number) => {
            return (
              <RowCreatedCampaign data={item} />
            );
          })}
        </tbody>
      </table>
      <div className={styles['list-mobile']}>
        {dataList?.map((item: CampaignService, index) => {
          return <RowCreatedCampaign data={item} isMobile />
        })}
      </div>
    </div>



  );
};

export default TableCampaign;
