import * as React from 'react';
const SvgLayer = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16px"
    height="16px"
    fill="none"
    viewBox="0 0 22 22"
    role={'icon-default'}
    {...props}
  >
    <path
      stroke={'currentColor'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="m20.6 10.975-9.6 4.92-9.6-4.92m19.2 4.704L11 20.6l-9.6-4.92M11 1.4l9.6 4.922-9.6 4.92-9.6-4.92L11 1.4Z"
    />
  </svg>
);
export default SvgLayer;
