// import Information from "components/pages/your-created-campaign/Information";
// import ListCampaign from "components/pages/public-campaign/ListCampaign";
import ButtonGradient from 'components/base/ButtonGradient';
import MascotNotice from 'components/common/MascotNotice';
import React, { useState } from 'react';
import styles from './styles.module.scss';
import { CoinHand, UserProfile, Wallet } from 'assets/svg-component';
import { useSdk } from 'core/logic';
import { useStoreModal } from 'context/StoreModal';
import InforCard from 'components/common/InforCard';
import Tabs from 'components/common/Tabs';
import TableCampaign from 'components/pages/created-campaign/TableCampaign';
import { CampaignService } from 'interface/campaign/campaign_service';
import { getCampaignServiceOwner } from 'api/campaign/campaign_service.api';
import { useCampaignService } from 'context/CampaignService';
import { Footer, Loading } from 'components';

interface CreatedCampaignProps { }

const CreatedCampaign: React.FC<CreatedCampaignProps> = () => {
  const statuses = ['All', 'Active', 'Pending', 'Upcoming', 'Ended'];

  const { initialized } = useSdk();
  const { setIsOpen } = useStoreModal('connect-wallet');
  const [indexActiveTab, setIndexActiveTab] = useState<number>(0);

  const { createdCampaign, isLoadingCreatedCampaign } = useCampaignService();
  return (
    <div className={styles['campaign-created']}>
      {!initialized ? (
        <MascotNotice
          className={styles['notice-no-connect']}
          img="/lotties/mascot-phone.json"
          text={
            <>
              Connect your wallet
              <br />
              to view your created campaigns
            </>
          }
        >
          <ButtonGradient
            className={styles['connect-wallet']}
            iconBefore={<Wallet />}
            onClick={() => {
              setIsOpen(true);
            }}
          >
            Connect Wallet
          </ButtonGradient>
        </MascotNotice>
      ) : (
        <>
          {isLoadingCreatedCampaign ? (
            <Loading />
          ) : (
            <div className={styles['connected-layout']}>
              <div className={styles['information']}>
                <InforCard
                  icon={<CoinHand />}
                  text={`${createdCampaign?.total_nft} NFTs`}
                  description="Total value locked in your campaigns"
                />
                <InforCard
                  icon={<UserProfile />}
                  text={`${createdCampaign?.total_staker}`}
                  description="Unique participants"
                />
              </div>
              {(createdCampaign?.campaigns?.length || 0) > 0 ? (
                <div className={styles['body']}>
                  <Tabs
                    tabs={statuses}
                    indexActive={indexActiveTab}
                    setIndexActive={setIndexActiveTab}
                  />
                  <TableCampaign
                    data={createdCampaign?.campaigns}
                    filterStatus={statuses[indexActiveTab]}
                  />
                </div>
              ) : (
                <MascotNotice
                  className={styles['no-campaign']}
                  text="You don’t have any campaign yet!"
                  img="/lotties/mascot-sleep.json"
                >
                  <a href='https://forms.gle/GritYiR3pQe4sfqVA' target='_blank'>
                    <ButtonGradient className={styles['add-campaign']}>
                      Add Campaign
                    </ButtonGradient>
                  </a>
                </MascotNotice>
              )}
            </div>
          )}
        </>
      )}
      <Footer isMobile={true} />
    </div>
  );
};

export default CreatedCampaign;
