import * as React from "react";
import styles from "./styles.module.scss";
import classNames from "classnames";



interface TabsProps {
    tabs: string[],
    indexActive: number,
    setIndexActive(number: number): void,
}

const Tabs: React.FC<TabsProps> = ({ tabs = [], indexActive, setIndexActive }) => {
    return <div className={styles['tabs']}>
        {
            tabs?.map((item, index) => {
                return <div key={index} className={classNames(styles['item'], {
                    [styles['active']]: indexActive === index
                })} onClick={() => {
                    setIndexActive(index);
                }}>{item}</div>
            })
        }
    </div>
}

export default Tabs;