import { useContext } from "react";
import { StoreModalContext } from "./StoreModalProvider";

const useStoreModal = (key = '') => {
    const store = useContext(StoreModalContext);
    let modal = store?.state;
    let modalNow = modal?.[key];
    function setIsOpen(value: boolean) {
        store?.setState((prev: object) => {
            let newVal = value;
            let newObj = { ...prev, [key]: { ...modalNow, isOpen: newVal } };
            return newObj;
        });
    }
    if (key !== '') {
        return { ...modalNow, setIsOpen, isOpen: modalNow?.isOpen };
    }
    return store?.state;
};

export default useStoreModal;