import React from 'react';
import styles from './styles.module.scss';
import classNames from 'classnames';
import TooltipBase from 'components/base/Tooltip';
import { collapseAddress } from 'utils/string';
import { Information } from 'assets/svg-component';
import moment from 'moment';
import { formatNumberCoin } from 'helpers/number';
import { appSetting } from 'constants/configuration';
import { Tooltip } from 'antd';

interface InformationCampaignProps {
  data: any;
  addressContract: string | undefined;
  createdCampaign?: boolean;
}

const InformationCampaign: React.FC<InformationCampaignProps> = ({
  data = {},
  addressContract = '',
  createdCampaign = false
}) => {
  return (
    <div className={styles['information-campaign-container']}>
      <div className={styles['information-campaign']}>
        <div className={classNames(styles['card'], styles['infor-base'])}>
          <div className={styles['head']}>
            <img
              src={data?.image || '/campaign-demo-big.png'}
              alt="campaign-demo"
              className={styles['logo']}
            />
            <div className={styles['infor']}>
              <div className={styles['name-campaign']}>
                <label>{data?.name}</label>
                <a
                  className={styles['aura-icon']}
                  href={`${appSetting.scan}/contracts/${addressContract}`}
                  target="_blank"
                  rel="noopener"
                >
                  <img src="/aura.svg" alt="aura" />
                  <TooltipBase text="View on Aurascan" />
                </a>
              </div>
              <label className={styles['owner']}>
                by{' '}
                <a
                  href={`${appSetting.scan}/account/${data?.owner_address}`}
                  title={data?.owner_address}
                  target="_blank"
                  rel="noopener"
                >
                  {collapseAddress(data?.owner_address)}
                </a>
              </label>
            </div>
          </div>
          <p className={styles['description']}>{data?.description}</p>
        </div>
        <div className={classNames(styles['card'], styles['infor-table'])}>
          <div className={styles['column']}>
            {/* <div className={styles['cell']}>
              <label className={styles['title']}>Collection staking</label>
              <label className={styles['value']}>{data?.collection_name}</label>
            </div> */}
            <div className={styles['cell']}>
              <label className={styles['title']}>Duration</label>
              <label className={styles['value']}>
                {moment(data?.start).format('DD MMM YYYY')} -{' '}
                {moment(data?.end).format('DD MMM YYYY')}
              </label>
            </div>
            <div className={styles['cell']}>
              <label className={styles['title']}>Total eligible</label>
              <label className={styles['value']}>
                {data?.collection_minted || 0} NFTs
              </label>
            </div>
            {/* <div className={styles['cell']}>
              <label className={styles['title']}>Start date</label>
              <label className={styles['value']}>
                {moment(data?.start).format('DD MMM YYYY')}
              </label>
            </div> */}
            <div className={styles['cell']}>
              <label className={styles['title']}>
                On stake
                <Tooltip
                  title="# of NFTs staking in campaign"
                  color={'#414141'}
                >
                  <Information />
                </Tooltip>
              </label>
              <label className={styles['value']}>
                {data?.total_staked} NFTs (
                {((data?.total_staked / data?.collection_minted) * 100).toFixed(
                  2
                )}{' '}
                %)
              </label>
            </div>
            <div className={classNames(styles['cell'], styles['reward-token'])}>
              <label className={styles['title']}>Reward token</label>
              <label className={styles['value']}>
                {/* <img src="/aura.svg" alt="symbol token" className={styles['aura-logo']} /> */}
                {data?.token_logo && (
                  <img
                    src={data?.token_logo}
                    alt="Symbol token"
                    className={styles['aura-logo']}
                  />
                )}
                ${data?.token_symbol}
              </label>
            </div>
            {/* <div className={styles['cell']}>
              <label className={styles['title']}>Total eligible NFTs</label>
              <label className={styles['value']}>
                {data?.collection_minted || 0} NFTs
              </label>
            </div> */}
            {createdCampaign && (
              <div className={styles['cell']}>
                <label className={styles['title']}>Staking period</label>
                <label className={styles['value']}>
                  {moment(data?.start).format('DD MMM YYYY')} -{' '}
                  {moment(data?.end).format('DD MMM YYYY')}
                </label>
              </div>
            )}
          </div>
          <div className={styles['column']}>
            {/* <div className={styles['cell']}>
              <label className={styles['title']}>Staked</label>
              <label className={styles['value']}>
                {data?.total_staked} NFTs (
                {((data?.total_staked / data?.collection_minted) * 100).toFixed(
                  2
                )}{' '}
                %)
              </label>
            </div> */}
            <div className={styles['cell']}>
              <label className={styles['title']}>Collection staking</label>
              <label
                className={`${styles['value']} ${styles['collection-name']}`}
              >
                {data?.collection_name}
              </label>
            </div>
            {/* <div className={styles['cell']}>
              <label className={styles['title']}>End date</label>
              <label className={styles['value']}>
                {moment(data?.end).format('DD MMM YYYY')}
              </label>
            </div> */}
            <div className={styles['cell']}>
              <label className={styles['title']}>
                Staked
                {/* <Information /> */}
                {/* <Tooltip
                  className={styles['tooltip-cell']}
                  text="# of NFTs staked from date to present"
                /> */}
                <Tooltip
                  title="# of NFTs staked from date to present"
                  color={'#414141'}
                >
                  <Information />
                </Tooltip>
              </label>
              <label className={styles['value']}>
                {/* {data?.total_staked} NFTs */}
              </label>
            </div>
            <div className={styles['cell']}>
              <label className={styles['title']}>
                Staking periods
                {/* <Information /> */}
                {/* <Tooltip
                  className={styles['tooltip-cell']}
                  text="Each period has a different reward 
                  distribution ratio"
                /> */}
                <Tooltip
                  title="Each period has a different reward 
                  distribution ratio"
                  color={'#414141'}
                >
                  <Information />
                </Tooltip>
              </label>
              <label className={styles['value']}>
                <div className={styles['staking-periods']}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={'100%'}
                    height="8"
                    viewBox="0 0 100 8"
                    fill="none"
                    preserveAspectRatio="none"
                  >
                    <rect width={'100%'} height="8" rx="2" fill="#414141" />
                    <rect
                      width={`${
                        ((moment().unix() - moment(data?.start).unix() > 0
                          ? moment().unix() - moment(data?.start).unix()
                          : 0) /
                          (moment(data?.end).unix() -
                            moment(data?.start).unix())) *
                          100 || 0
                      }%`}
                      height="8"
                      rx="2"
                      fill="url(#paint0_linear_2382_4719)"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_2382_4719"
                        x1={`${
                          ((moment().unix() - data?.start > 0
                            ? moment().unix() - data?.start
                            : 0) /
                            (data?.end - data?.start)) *
                          100
                        }%`}
                        y1="5.91367e-07"
                        x2="99.1938"
                        y2="42.5316"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#7F6AFF" />
                        <stop offset="0.5" stop-color="#9AFFD4" />
                        <stop offset="1" stop-color="white" />
                      </linearGradient>
                    </defs>
                  </svg>
                  <div className={styles['list-term']}>
                    {(data?.staking_term_available
                      ? JSON.parse(data?.staking_term_available)
                      : []
                    )?.map((term: any) => {
                      return (
                        <div>
                          {Number(term?.value) / 2592000}M -{' '}
                          {Number(term?.percent)}%
                        </div>
                      );
                    })}
                  </div>
                </div>
              </label>
            </div>
            <div className={styles['cell']}>
              <label className={styles['title']}>
                Est. daily rewards
                {/* <Information /> */}
                {/* <Tooltip
                  className={styles['tooltip-cell']}
                  text="Your estimate reward calculated by day according to your staking option"
                /> */}
                <Tooltip
                  title="Your estimate reward calculated by day according to your staking option"
                  color={'#414141'}
                >
                  <Information />
                </Tooltip>
              </label>
              <label className={styles['value']}>
                {formatNumberCoin(
                  Number(
                    (data?.reward_per_second * 86400) / data?.total_staked
                  ),
                  data?.token_decimal
                )}{' '}
                {data?.token_symbol}
              </label>
            </div>
            {/* <div className={styles['cell']}>
              <label className={styles['title']}>Limit NFT/staker</label>
              <label className={styles['value']}>
                {data?.limit_nft_stake} NFTs
              </label>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InformationCampaign;
