import * as React from 'react';
const SvgCopy = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16px"
    height="16px"
    fill="none"
    viewBox="0 0 16 17"
    role={'icon-default'}
    {...props}
  >
    <path
      stroke={'currentColor'}
      strokeLinecap="round"
      strokeWidth={1.5}
      d="M4 10.1H2.4a.8.8 0 0 1-.8-.8V4.1a2 2 0 0 1 2-2h5.2a.8.8 0 0 1 .8.8v1.6M8 14.9h4.8a1.6 1.6 0 0 0 1.6-1.6V8.5a1.6 1.6 0 0 0-1.6-1.6H8a1.6 1.6 0 0 0-1.6 1.6v4.8A1.6 1.6 0 0 0 8 14.9Z"
    />
  </svg>
);
export default SvgCopy;
