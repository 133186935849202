import * as React from 'react';
const SvgLocked = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16px"
    height="16px"
    viewBox="0 0 60 60"
    fill="none"
    role={'icon-default'}
    {...props}
  >
    <path
      d="M16.5 22.6398V19.6533C16.5 12.0897 22.5214 5.99976 30 5.99976C33.9903 5.99976 37.5657 7.73349 40.0331 10.4998M30 39.2136V33.2136M48 36.0799C48 45.977 39.9411 54.0002 30 54.0002C20.0589 54.0002 12 45.977 12 36.0799C12 26.1829 20.0589 18.1597 30 18.1597C39.9411 18.1597 48 26.1829 48 36.0799Z"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
);
export default SvgLocked;
