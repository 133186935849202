import React, { createContext, useState } from 'react';

export type ISearch = {
    valueSearch: string;
    setValueSearch: (value: string) => void;
};

const initValues = {
    valueSearch: '',
    setValueSearch: () => { }
};

export const SearchContext = createContext<ISearch>(initValues);

const SearchProvider: React.FC<{ children: React.ReactNode }> = ({
    children
}) => {
    const [valueSearch, setValueSearch] = useState('');
    return (
        <SearchContext.Provider
            value={{ valueSearch, setValueSearch }}
        >
            {children}
        </SearchContext.Provider>
    );
};

export default SearchProvider;
