import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import ActionIcon from 'components/base/ActionIcon';
import { Close, Information } from 'assets/svg-component';
import { useStakeNFTs } from 'context/StakeNFTs';
import classNames from 'classnames';
import LockupTerms from './LockupTerms';
import Checkbox from 'components/base/Checkbox';
import ButtonGradient from 'components/base/ButtonGradient';
import { useClient } from 'core/logic/service/public';
import { useSdk } from 'core/logic';
import { fetchNftInfo } from 'hooks/api/fetchNftInfo';
import { useStoreModal } from 'context/StoreModal';
import { toast } from 'react-toastify';
import moment from 'moment';
import { CampaignService } from 'interface/campaign/campaign_service';
import { formatNumberCoin } from 'helpers/number';
import MascotNotice from 'components/common/MascotNotice';
import Loading from 'components/Loading';
import { getApproveNft, postApproveNft } from 'api/approvenft.api';
import { handleMsgErr } from 'utils/contract-utils';
import { useCampaignService } from 'context/CampaignService';
import { fetchingCampaign } from 'api/campaign/fetching_campaign.api';
// import { getCampaignServiceDetail } from 'api/campaign/campaign_detail.api';
import { appSetting } from 'constants/configuration';
import notifyPNG from 'assets/images/notify.png';

const Bounce = require('react-reveal/Bounce');

interface StakeNFTsModalProps {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  campaignDetail: CampaignService | undefined;
}

const StakeNFTsModal: React.FC<StakeNFTsModalProps> = ({
  isOpen = false,
  setIsOpen = () => {},
  campaignDetail
}) => {
  const { setIsOpen: setIsOpenSuccessModal, isOpen: isOpenSuccessModal } =
    useStoreModal('stake-success-modal');
  const { address } = useSdk();
  const {
    closeStakeNFTs,
    collectionAddress,
    campaignAddress,
    setDataAfterStake
  } = useStakeNFTs();
  const { cw721, createCw721, createCampaign, campaign } = useClient();
  const [valueTerm, setValueTerm] = useState<number>(0);
  const [disabledStakeButton, setDisabledStakeButton] = useState(true);
  const [listNftChecked, setListNftChecked] = useState<any[]>([]);
  const [loadingButtonStake, setLoadingButtonStake] = useState<boolean>(false);
  const [loadingApprove, setLoadingApprove] = useState<boolean>(false);
  const [disabledChooseNft, setDisabledChooseNft] = useState<boolean>(false);

  const [isApproved, setIsApproved] = useState<boolean>(false);

  const [loadingNFTs, setLoadingNFTs] = useState<boolean>(false);

  const [nftStakedCount, setNftStakedCount] = useState<number>(0);

  const { requestListStakeNFT } = useCampaignService();

  useEffect(() => {
    const body = document.querySelector('body');
    if (body) {
      if (isOpen) {
        body.style.overflowY = 'hidden';
        if (collectionAddress) createCw721(collectionAddress);
        if (campaignAddress) createCampaign(campaignAddress);
      } else if (isOpenSuccessModal) {
        body.style.overflowY = 'hidden';
      } else {
        body.style.overflowY = 'auto';
      }
    }
  }, [isOpen, isOpenSuccessModal]);
  useEffect(() => {
    let arr = listNftChecked?.filter((item, index) => {
      return item?.checked;
    });
    if (
      arr?.length >= Number(campaignDetail?.limit_nft_stake) &&
      Number(campaignDetail?.limit_nft_stake) != 0
    ) {
      setDisabledChooseNft(true);
    } else {
      setDisabledChooseNft(false);
    }
    if (arr?.length > 0) setDisabledStakeButton(false);
    else setDisabledStakeButton(true);
  }, [listNftChecked, nftStakedCount]);

  useEffect(() => {
    setIsApproved(false);
    setLoadingNFTs(true);
    cw721
      ?.tokens(address)
      .then((data) => {
        const nfts = fetchNftInfo(data?.tokens, collectionAddress);
        nfts.then((data: any) => {
          var firstX;
          if (appSetting.prefix_network === 'xstaxy') {
            firstX = data?.data?.xstaxy;
          } else {
            firstX = data?.data?.euphoria;
          }
          const x = firstX?.data[0]?.cw721_contract?.cw721_tokens;
          const listNFTs = x?.map((item: any, index: number) => {
            return { ...item, checked: false };
          });
          setListNftChecked(listNFTs);
          setLoadingNFTs(false);
        });
      })
      .catch((err) => {
        console.log({ err });
        setLoadingNFTs(false);
      });
    getApproveNft({
      campaign_address: campaignAddress,
      owner_address: address
    })
      .then((data) => {
        if (!data?.error) {
          setIsApproved(true);
        } else {
          setIsApproved(false);
        }
      })
      .catch(() => {
        setIsApproved(false);
      });
  }, [cw721, isOpen]);
  useEffect(() => {
    if (campaign) {
      campaign.nft_staked(address).then(async (data) => {
        let length = data?.nfts?.length;
        setNftStakedCount(length);
      });
    }
  }, [isOpen, campaign]);

  function stakeNFTs() {
    setLoadingButtonStake(true);

    let nfts = listNftChecked?.filter((item) => {
      return item?.checked;
    });

    nfts = [...nfts]?.map((item, index) => item?.token_id);

    campaign
      ?.stake_nfts(address, { token_ids: nfts, lockup_term: valueTerm })
      .then(async (data) => {
        setLoadingButtonStake(false);
        setIsOpen(false);
        setDataAfterStake(data);
        setIsOpenSuccessModal(true);
        sessionStorage.setItem('hash-stake', data);
        await fetchingCampaign({
          campaign_address: campaignDetail?.address.toString()
        });
        requestListStakeNFT(campaignDetail);
      })
      .catch((err) => {
        setLoadingButtonStake(false);
        let msg = handleMsgErr(err?.message);
        toast.error(msg);
      });
  }

  function approveNFT() {
    setLoadingApprove(true);
    cw721
      ?.approve_all(address, campaignAddress)
      .then(async (data) => {
        setLoadingApprove(false);
        setIsApproved(true);
        await postApproveNft({
          campaign_address: campaignAddress,
          owner_address: address
        }).then((data) => {
          toast.success('Approve successfully');
        });
      })
      .catch((err) => {
        setLoadingApprove(false);
        let msg = handleMsgErr(err?.message);
        toast.error(msg);
      });
  }

  return (
    <Bounce bottom when={isOpen}>
      <div
        className={classNames(styles['stake-nfts-modal'], {
          [styles['open']]: isOpen
        })}
      >
        <div className={styles['container']}>
          <div className={styles['close']}>
            <ActionIcon
              icon={<Close />}
              className={styles['close-btn']}
              onClick={closeStakeNFTs}
            />
          </div>
          <h5 className={styles['title']}>Stake your NFTs</h5>
          <div className={styles['body']}>
            <div className={styles['step-staking']}>
              <div className={styles['box-step']}>
                <h6 className={styles['sub-title']}>1. Lock-up duration</h6>
                <LockupTerms
                  setValueTerm={setValueTerm}
                  data={
                    campaignDetail?.staking_term_available
                      ? JSON.parse(campaignDetail?.staking_term_available)
                      : []
                  }
                />
                <div className={styles['description']}>
                  <Information />
                  <span>
                    Stake your NFTs for a fixed term of{' '}
                    {(valueTerm / 86400).toFixed(0)} days. If you unstake your
                    assets before lock-up term ends, you will not receive any
                    rewards
                  </span>
                </div>
              </div>
              <div className={styles['box-step']}>
                <div className={styles['box-step__head']}>
                  <h6 className={styles['sub-title']}>
                    2. Select NFT(s) to stake
                  </h6>
                  {disabledStakeButton || (
                    <div className={styles['box-step__head-right']}>
                      <label className={styles['number-selected']}>
                        {
                          listNftChecked?.filter((item) => item?.checked)
                            ?.length
                        }{' '}
                        selected
                      </label>
                      <label
                        className={styles['clear-all']}
                        onClick={() => {
                          setListNftChecked((prev) => {
                            let newList = prev?.map((item) => {
                              return { ...item, checked: false };
                            });
                            return newList;
                          });
                        }}
                      >
                        Clear all
                      </label>
                    </div>
                  )}
                </div>
                {disabledChooseNft && (
                  <label className={styles['reach-limit-pool']}>
                    The number of staked NFTs has reached the pool limit
                  </label>
                )}

                {loadingNFTs ? (
                  <Loading />
                ) : (
                  <>
                    {listNftChecked?.length > 0 ? (
                      <div className={styles['list-nft']}>
                        {listNftChecked?.map((item: any, index: number) => {
                          return (
                            <div
                              className={classNames(styles['nft'], {
                                [styles['disabled']]:
                                  disabledChooseNft && !item?.checked
                              })}
                              key={index}
                            >
                              <div
                                className={styles['image']}
                                style={{
                                  backgroundImage: `url(${item?.media_info?.offchain?.image?.url})`
                                }}
                              >
                                <Checkbox
                                  checked={item?.checked}
                                  setChecked={() => {
                                    setListNftChecked((prev: any[]) => {
                                      const arr: any[] = [...prev];
                                      arr[index].checked = !arr[index].checked;
                                      return arr;
                                    });
                                  }}
                                />
                              </div>
                              <label className={styles['name']}>
                                {campaignDetail?.collection_name} #
                                {item?.token_id}
                                {/* {item?.media_info?.onchain?.metadata?.name} */}
                              </label>
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      <MascotNotice
                        img={'/lotties/mascot-thinking.json'}
                        text="You don’t have any NFTs of this collection."
                      >
                        <span className={styles['buy-nft-notice']}>
                          Want to buy one? Visit our marketplace{' '}
                          <a
                            href="https://beta.seekhype.io/collection/aura_boy_golf_club_41"
                            target="_blank"
                            rel="noopener"
                          >
                            here
                          </a>
                          .
                        </span>
                      </MascotNotice>
                    )}
                  </>
                )}
              </div>
            </div>
            <div className={styles['information']}>
              <h6 className={styles['sub-title']}>Staking information</h6>
              <div className={styles['body-infor']}>
                <div className={styles['infor-row']}>
                  <label className={styles['title-infor']}>Staking time</label>
                  <label className={styles['value-infor']}>
                    {moment().format('hh:mm A, DD MMM YYYY')}
                  </label>
                </div>
                <div className={styles['infor-row']}>
                  <label className={styles['title-infor']}>
                    Lock-up period
                  </label>
                  <label className={styles['value-infor']}>
                    {valueTerm === 0
                      ? 'Flexible'
                      : `Fixed term (${(valueTerm / 86400).toFixed(0)} days)`}
                  </label>
                </div>
                <div className={styles['infor-row']}>
                  <label className={styles['title-infor']}>Unlock date</label>
                  <label className={styles['value-infor']}>
                    {valueTerm === 0
                      ? '--'
                      : moment()
                          .add(valueTerm / 86400, 'days')
                          .format('hh:mm A, DD MMM YYYY')}
                  </label>
                </div>
                <div className={styles['infor-row']}>
                  <label className={styles['title-infor']}>
                    Campaign end date
                  </label>
                  <label className={styles['value-infor']}>
                    {moment(campaignDetail?.end).format('hh:mm A, DD MMM YYYY')}
                  </label>
                </div>
                <div className={styles['infor-row']}>
                  <label className={styles['title-infor']}>
                    Est. daily rewards
                  </label>
                  <div className={styles['value-div']}>
                    {campaignDetail?.token_logo && (
                      <img
                        src={campaignDetail?.token_logo}
                        alt="Symbol token"
                        className={styles['aura-logo']}
                      />
                    )}
                    <label className={styles['value-infor']}>
                      {campaignDetail?.reward_per_second
                        ? formatNumberCoin(
                            campaignDetail?.reward_per_second * 86400,
                            campaignDetail?.token_decimal
                          )
                        : 'N/A'}{' '}
                      {campaignDetail?.token_symbol}
                    </label>
                  </div>
                </div>
                <div className={styles['infor-row']}>
                  <label className={styles['title-infor']}>
                    Pool limit per user
                  </label>
                  <label className={styles['value-infor']}>
                    {campaignDetail?.limit_nft_stake} NFTs
                  </label>
                </div>
              </div>
              {!isApproved ? (
                <p className={styles['des-approve']}>
                  To proceed with staking, please grant us permission to
                  interact with NFTs by clicking the "Approve" button below.
                </p>
              ) : (
                moment().isBefore(moment(campaignDetail?.start)) && (
                  <div className={styles['notify']}>
                    <img src={notifyPNG} alt="" />
                    <p className={styles['des']}>
                      Rewards are only calculated when the campaign starts. You
                      can unstake your NFTs at any time before the campaign
                      becomes active{' '}
                    </p>
                  </div>
                )
              )}
              <div className={styles['btn-div']}>
                {!isApproved && (
                  <ButtonGradient
                    onClick={approveNFT}
                    loading={loadingApprove}
                    className={styles['btn']}
                    disabled={listNftChecked?.length <= 0}
                  >
                    Approve
                  </ButtonGradient>
                )}
                <ButtonGradient
                  disabled={disabledStakeButton || !isApproved}
                  className={styles['btn']}
                  onClick={() => {
                    stakeNFTs();
                  }}
                  loading={loadingButtonStake}
                >
                  Confirm and Stake
                </ButtonGradient>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Bounce>
  );
};

export default StakeNFTsModal;
