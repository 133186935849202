import * as React from 'react';
const SvgWallet = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16px"
    height="16px"
    fill="none"
    viewBox="0 0 16 16"
    role={'icon-default'}
    {...props}
  >
    <path
      fill={'currentColor'}
      d="M14.4 11.04v.75a.75.75 0 0 0 .75-.75h-.75Zm0-3.52h.75a.75.75 0 0 0-.75-.75v.75ZM4.715 4.009a.75.75 0 1 0 .81 1.262l-.81-1.262ZM9.6 1.759l.592-.46a.75.75 0 0 0-.997-.17l.405.63ZM11.248 5.1a.75.75 0 0 0 1.184-.92l-1.184.92Zm3.152 5.19h-2.08v1.5h2.08v-1.5Zm-2.08-2.02h2.08v-1.5h-2.08v1.5Zm1.33-.75v3.52h1.5V7.52h-1.5Zm-2.34 1.76c0-.558.452-1.01 1.01-1.01v-1.5a2.51 2.51 0 0 0-2.51 2.51h1.5Zm1.01 1.01a1.01 1.01 0 0 1-1.01-1.01h-1.5a2.51 2.51 0 0 0 2.51 2.51v-1.5ZM5.526 5.27l4.48-2.88-.811-1.261-4.48 2.88.81 1.262Zm3.482-3.05 2.24 2.88 1.184-.92-2.24-2.88-1.184.92ZM2.24 5.39h10.88v-1.5H2.24v1.5Zm10.88 8.1H2.24v1.5h10.88v-1.5Zm-10.77.11V5.28H.85v8.32h1.5Zm-.11-.11c.06 0 .11.049.11.11H.85c0 .767.622 1.39 1.39 1.39v-1.5Zm10.77.11c0-.061.05-.11.11-.11v1.5a1.39 1.39 0 0 0 1.39-1.39h-1.5Zm.11-8.21a.11.11 0 0 1-.11-.11h1.5a1.39 1.39 0 0 0-1.39-1.39v1.5ZM2.24 3.89A1.39 1.39 0 0 0 .85 5.28h1.5c0 .06-.05.11-.11.11v-1.5Zm10.77 7.63v2.08h1.5v-2.08h-1.5Zm0-6.24v1.68h1.5V5.28h-1.5Z"
    />
  </svg>
);
export default SvgWallet;
