import { Button } from 'antd';
import classNames from 'classnames'
import React from 'react'
import styles from './styles.module.scss'
import Tooltip from '../Tooltip';
import { Player } from '@lottiefiles/react-lottie-player';

interface ActionIconProps {
    icon: any;
    disable?: boolean;
    tooltip?: string;
    onClick?: (...args: any[]) => void;
    className?: string;
    loading?: boolean;
}

const ActionIcon: React.FC<ActionIconProps> = ({ icon, disable = false, tooltip = '', onClick = () => { }, className = '', loading = false }) => {
    return (
        <Button className={classNames(styles['action'], className, {
            [styles['disable']]: disable,
        })} onClick={onClick} role='action-icon'>
            {
                loading ? <div className={styles['loading']}><Player
                    src='/lotties/loading.json'
                    className={styles['player']}
                    loop
                    autoplay
                /></div> : icon

            }
            <Tooltip text={tooltip} /></Button>
    )
}

export default ActionIcon