

import { PageData2 } from 'interface';
import { request_new } from '../request';
import { UserService } from 'interface/campaign/user_service';

export const getUserService = (params: any) => {
    return request_new<PageData2<UserService>>('get', '/api/public/UserService/info', params)
};

