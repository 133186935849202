import ComingSoon from 'components/modal/ComingSoon';
import ConnectWalletModal from 'components/modal/ConnectWalletModal';
import StakeSuccessModal from 'components/modal/StakeSuccessModal';
import SwitchAccountModal from 'components/modal/SwitchAccountModal';
import TosPolicyModal from 'components/modal/TosPolicyModal';
import React, { createContext, useState } from 'react';

type IStoreModalContext = {
  state: any;
  setState(value: any): void;
};

export const StoreModalContext = createContext<IStoreModalContext | null>(null);

const StoreModalProvider: React.FC<{ children: React.ReactNode }> = ({
  children
}) => {
  const [modal, setModal] = useState({});
  return (
    <StoreModalContext.Provider value={{ state: modal, setState: setModal }}>
      <ConnectWalletModal />
      <StakeSuccessModal />
      <ComingSoon />
      <TosPolicyModal />
      <SwitchAccountModal />
      {children}
    </StoreModalContext.Provider>
  );
};

export default StoreModalProvider;
