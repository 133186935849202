import * as React from 'react';
const SvgLinkExternal = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16px"
    height="16px"
    fill="none"
    viewBox="0 0 16 17"
    role={'icon-default'}
    {...props}
  >
    <path
      stroke={'currentColor'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M7.2 2.1H4a2.4 2.4 0 0 0-2.4 2.4v8A2.4 2.4 0 0 0 4 14.9h8a2.4 2.4 0 0 0 2.4-2.4V9.3m-4-7.2h4m0 0v3.6m0-3.6L7.6 8.9"
    />
  </svg>
);
export default SvgLinkExternal;
