import { createSlice } from '@reduxjs/toolkit';
import { Themes } from '../constants/constants';

const initialState: CommonState = {
  lang: 'en',
  wallet: '',
};
const { reducer, actions } = createSlice({
  name: 'common',
  initialState,
  reducers: {
    changeChooseWallet: (state, action) => {
      state.wallet = action.payload.wallet;
    }
  },
  extraReducers: (builder) => { }
});

export { reducer, actions as commonActions };
