import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import {
  // formatEthNumber,
  formatNumber,
  formatNumberCoin
} from 'helpers/number';
import { useNavigate } from 'react-router-dom';
import BaseButton from 'components/base/Button';
import ActionIcon from 'components/base/ActionIcon';
import { FileEyes } from 'assets/svg-component';
import { checkStatus2, useCreateCampaign } from 'utils/contract-utils';
import { CampaignService, Staker } from 'interface/campaign/campaign_service';
import { useSdk } from 'core/logic';
import { toast } from 'react-toastify';
// import CustomToastWithLink from 'components/base/CustomToastWithLink';
import { useClaimReward } from 'context/ClaimReward';
import { useClient } from 'core/logic/service/public';
import classNames from 'classnames';
// import { toTitleCase } from 'utils/string';
import { NftKey } from 'core/logic/service/campaign';
import Decimal from 'decimal.js';
import { addLargeNumbers } from 'utils/string';

interface RowStakedCampaignProps {
  data: CampaignService;
  classMobile?: string;
}

const RowStakedCampaign: React.FC<RowStakedCampaignProps> = ({
  data,
  classMobile = ''
}) => {
  const navigate = useNavigate();
  const status = data ? checkStatus2(data?.start, data?.end, data) : undefined;
  const { initialized, address } = useSdk();
  const [rewardDebt, setRewardDebt] = useState('0');
  const [rewardClaimed, setRewardClaimed] = useState<string>('0');
  const [loadingClaimButton, setLoadingClaimButton] = useState(false);
  const [claimRewardCampaign, setClaimRewardCampaign] = useState<string>('');
  const campaignClient = useCreateCampaign(data?.address);
  const { open, reward, isOpen } = useClaimReward();
  const { campaign, createCampaign, createCw20 } = useClient();

  function getDataReward() {
    (async function updateCampaignInfor(): Promise<void> {
      try {
        if (campaignClient && initialized) {
          await campaignClient.nft_staked(address).then(async (data) => {
            let staker: Staker = { ...data, nfts: [] };

            let keys: NftKey[] = [...data?.keys];

            for (const key of keys) {
              await campaignClient
                .nft_info(key)
                .then((data) => {
                  staker.nfts.push(data);
                })
                .catch((err) => {
                  console.log(err);
                });
            }

            let pending_reward = staker.reward_debt;
            staker.nfts.forEach((nft) => {
              pending_reward = addLargeNumbers(
                pending_reward,
                nft?.pending_reward
              );
            });

            setRewardDebt(pending_reward.toString());
            setRewardClaimed(staker?.reward_claimed);
          });
        }
      } catch (error: any) {
        toast.error(error.message);
      }
    })();
  }

  useEffect(() => {
    if (!isOpen) getDataReward();
  }, [campaignClient, initialized, isOpen]);

  async function claimReward() {
    if (Number(rewardDebt) != 0) {
      setLoadingClaimButton(true);
      // campaignClient
      //   ?.claim_reward(address, reward.toString())
      //   .then((data) => {
      //     setLoadingClaimButton(false);
      //     toast.success(
      //       <CustomToastWithLink
      //         text="Claim success"
      //         transaction={`https://euphoria.aurascan.io/transaction/${data}`}
      //       />
      //     );
      //     console.log(data);
      //   })
      //   .catch((err) => {
      //     setLoadingClaimButton(false);
      //     toast.error(err);
      //     console.log(err);
      //   });
      createCampaign(data?.address);
      setClaimRewardCampaign(data?.address);
    } else {
      toast.error('Pending reward must be greater than 0');
    }
  }

  useEffect(() => {
    if (campaign?.contractAddress == claimRewardCampaign) {
      (async function claimReward(): Promise<void> {
        open(rewardDebt, data);
        setClaimRewardCampaign('');
      })();
    }
  }, [claimRewardCampaign]);

  useEffect(() => {
    if (!isOpen) setLoadingClaimButton(false);
  }, [isOpen]);
  if (data) {
    return (
      <>
        {!classMobile && (
          <tr className={classMobile}>
            <td>
              <div className={styles['infor-campaign']}>
                <img
                  src={data?.image}
                  alt="campaign-demo"
                  className={styles['logo']}
                />
                <div className={styles['information']}>
                  <label className={styles['name-campaign']}>
                    {data?.name}
                  </label>
                  <label className={styles['name-collection']}>
                    {data?.collection_name}
                  </label>
                </div>
              </div>
            </td>
            <td>{formatNumber(data?.locked_nfts)}</td>
            <td>{formatNumber(data?.can_unstake_nfts)}</td>
            <td>
              {formatNumberCoin(Number(rewardDebt), data?.token_decimal)}{' '}
              {data?.token_symbol}
            </td>
            {/* data?.pending_rewards */}
            <td>
              <div className={styles['frame-btn']}>
                <BaseButton
                  outline
                  disabled={status == 'Ended' || Number(rewardDebt) <= 0}
                  onClick={claimReward}
                  loading={loadingClaimButton}
                >
                  Claim Reward
                </BaseButton>
                <ActionIcon
                  icon={<FileEyes />}
                  tooltip="View campaign detail"
                  onClick={() => {
                    navigate(`/detail-campaign/${data?.address || ''}`);
                  }}
                />
              </div>
            </td>
          </tr>
        )}

        {classMobile && (
          <div className={classNames(styles['row-mobile'], classMobile)}>
            <img src={data?.image} className={styles['logo']} />
            <div className={styles['infor']}>
              <label className={styles['campaign-name']}>{data?.name}</label>
              <label className={styles['collection-name']}>
                {data?.description}
              </label>
              <div className={styles['row-infor']}>
                <label className={styles['row-infor__title']}>
                  Amount NFT locked
                </label>
                <label className={styles['row-infor__value']}>
                  {formatNumber(data?.locked_nfts)}
                </label>
              </div>
              <div className={styles['row-infor']}>
                <label className={styles['row-infor__title']}>
                  Can unstake
                </label>
                <label className={styles['row-infor__value']}>
                  {formatNumber(data?.can_unstake_nfts)}
                </label>
              </div>
              <div className={styles['row-infor']}>
                <label className={styles['row-infor__title']}>
                  Pending rewards
                </label>
                <label className={styles['row-infor__value']}>
                  {formatNumberCoin(Number(rewardDebt), data?.token_decimal)}{' '}
                  {data?.token_symbol}
                </label>
              </div>
              <div className={styles['footer']}>
                {/* <div
                  className={classNames(
                    'status',
                    status.toLowerCase()
                  )}
                >
                  <div className={classNames('circle')}></div>
                  {toTitleCase(status)}
                </div> */}
                <div className={classNames(styles['interaction'])}>
                  <BaseButton
                    outline
                    disabled={Number(rewardDebt) <= 0}
                    onClick={claimReward}
                    loading={loadingClaimButton}
                  >
                    Claim Reward
                  </BaseButton>
                  <ActionIcon
                    icon={<FileEyes />}
                    tooltip="View campaign detail"
                    onClick={() => {
                      navigate(`/detail-campaign/${data?.address || ''}`);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
  return <></>;
};

export default RowStakedCampaign;
