import React from 'react'
import styles from './styles.module.scss';
import classNames from 'classnames';
import { useStoreModal } from 'context/StoreModal';
import ActionIcon from 'components/base/ActionIcon';
import { Close } from 'assets/svg-component';
import MascotNotice from 'components/common/MascotNotice';
import BaseButton from 'components/base/Button';
import { appSetting } from 'constants/configuration';
import { useNavigate } from 'react-router-dom';
const Bounce = require('react-reveal/Bounce');

interface StakeSuccessModalInterface {

}

const StakeSuccessModal: React.FC<StakeSuccessModalInterface> = () => {
    const { isOpen, setIsOpen } = useStoreModal('stake-success-modal');
    const navigate = useNavigate();
    React.useEffect(() => {
        const body = document.querySelector('body');
        if (body) {
            if (isOpen) {
                body.style.overflowY = 'hidden';
            } else {
                body.style.overflowY = 'auto';
            }
        }
    }, [isOpen]);
    return (
        <Bounce bottom when={isOpen}>
            <div className={classNames(styles['popup'], {
                [styles['open']]: isOpen
            })}>
                <div className={styles['container']}>
                    <div className={styles['close']}>
                        <ActionIcon
                            icon={<Close />}
                            className={styles['close-btn']}
                            onClick={() => { setIsOpen(false) }}
                        />
                    </div>
                    <MascotNotice text="Your NFT(s) have been staked successfully." img='/lotties/mascot-love.json'>
                        <div className={styles['child-mascot']}>
                            <span className={styles['view-on']}>
                                View on {' '}
                                <a href={`${appSetting.scan}/transaction/${sessionStorage.getItem("hash-stake")}`} target="_blank" rel='noopener'>
                                    Aurascan
                                </a>
                                .
                            </span>
                            <BaseButton outline onClick={() => {
                                navigate("/")
                                setIsOpen(false);
                            }}>Back to home</BaseButton>
                        </div>
                    </MascotNotice>
                </div>
            </div>
        </Bounce>
    )
}

export default StakeSuccessModal