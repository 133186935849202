

import { PageData } from 'interface';
import { request_new } from '../request';
import { StakingHistory } from 'interface/campaign/staking_history';
import { fetchingCampaign } from './fetching_campaign.api';

export const getStakingHistory = async (params: any) => {
    // await fetchingCampaign({ campaign_address: params?.campaign_address });
    return request_new<PageData<StakingHistory>>('get', '/api/public/CampaignService/staking-history', params)
};
