import * as React from 'react';
const SvgClose = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16px"
    height="16px"
    fill="none"
    viewBox="0 0 24 24"
    role={'icon-default'}
    {...props}
  >
    <path
      stroke={'currentColor'}
      strokeLinecap="round"
      strokeWidth={1.5}
      d="M19 5 5 19m14 0L5 5"
    />
  </svg>
);
export default SvgClose;
