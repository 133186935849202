import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { checkStatus2 } from 'utils/contract-utils';
import RowStakedCampaign from './RowStakedCampaign';

interface ListStakedCampaignProps {
  data: any[];
  indexFilterStatus?: string;
}

const ListStakedCampaign: React.FC<ListStakedCampaignProps> = ({
  data = [],
  indexFilterStatus = 'Active'
}) => {
  const [dataList, setDataList] = useState<any[]>([]);
  useEffect(() => {
    let dt = [...data];

    if (indexFilterStatus === 'Active') {
      dt = dt?.filter((item) => {
        const status = checkStatus2(item?.start, item?.end, item);
        return status === 'Active';
      });
    } else if (indexFilterStatus === 'Ended') {
      dt = dt?.filter((item) => {
        const status = checkStatus2(item?.start, item?.end, item);
        return status === 'Ended';
      });
    }
    // else {
    //   dt = dt;
    // }
    // console.log({ dt });

    setDataList(dt);
  }, [data, indexFilterStatus]);
  return (
    <div className={styles['list-staked-campaign']}>
      <table className={styles['table']}>
        <thead>
          <tr>
            <th>Campaigns</th>
            <th>Amount NFT locked</th>
            <th>Can unstake</th>
            <th>Pending rewards</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {dataList?.map((item, index) => {
            return <RowStakedCampaign key={index} data={item} />;
          })}
        </tbody>
      </table>

      <div className={styles['list-mobile']}>
        {dataList?.map((item, index) => {
          return (
            <RowStakedCampaign
              key={index}
              data={item}
              classMobile={styles['list-mobile__item']}
            />
          );
        })}
      </div>
    </div>
  );
};

export default ListStakedCampaign;
// const status = checkStatus2(item?.start, item?.end);
//             return (
//               <tr key={index}>
//                 <td>
//                   <div className={styles['infor-campaign']}>
//                     <img
//                       src={item?.image}
//                       alt="campaign-demo"
//                       className={styles['logo']}
//                     />
//                     <div className={styles['information']}>
//                       <label className={styles['name-campaign']}>
//                         {item?.name}
//                       </label>
//                       <label className={styles['name-collection']}>
//                         {item?.collection_name}
//                       </label>
//                     </div>
//                   </div>
//                 </td>
//                 <td>{formatNumber(item?.total_staked)}</td>
//                 <td>{formatEthNumber(item?.pending_rewards)} AURA</td>
//                 <td>
//                   <div className={styles['frame-btn']}>
//                     <BaseButton outline disabled={status == 'Ended'}>
//                       Claim Reward
//                     </BaseButton>
//                     <ActionIcon
//                       icon={<FileEyes />}
//                       tooltip="View campaign detail"
//                       onClick={() => {
//                         navigate(`/detail-campaign/${item?.address || ''}`);
//                       }}
//                     />
//                   </div>
//                 </td>
//               </tr>
//             );
