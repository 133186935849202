import { useSdk } from 'core/logic';
import { useNavigate } from 'react-router-dom';
import styles from './styles.module.scss';
import ActionIcon from 'components/base/ActionIcon';
import { Back, Wallet } from 'assets/svg-component';
import InformationCampaign from 'components/common/InformationCampaign';
import MascotNotice from 'components/common/MascotNotice';
import ButtonGradient from 'components/base/ButtonGradient';
import { useStoreModal } from 'context/StoreModal';
import ChartStakingAnalysis from 'components/pages/created-campaign/ChartStakingAnalysis';

import YourRewards from 'components/pages/detail-campaign/YourRewards';

import { useCampaignService } from 'context/CampaignService';
import { Loading } from 'components';

const CampaignCreatedDetail = () => {
  const { setIsOpen } = useStoreModal('connect-wallet');
  const navigate = useNavigate();
  const { initialized } = useSdk();
  const { addressContract, campaignDetail, campaignAnalysis, isLoadingCampaignDetail } = useCampaignService();
  return (
    <div>
      {initialized ? (
        <>
          <div className={styles['back']}>
            <ActionIcon
              icon={<Back />}
              onClick={() => {
                navigate('/created-campaign');
              }}
            />
            <label>Back</label>
          </div>
          {
            isLoadingCampaignDetail ? <Loading /> : <><InformationCampaign
              data={campaignDetail}
              addressContract={addressContract}
              createdCampaign
            />
              <YourRewards
                is_po={true}
                campaignDetail={campaignDetail}
                nfts={[]}
              />
              <ChartStakingAnalysis analysis={campaignAnalysis} campaignDetail={campaignDetail} /></>
          }
        </>
      ) : (
        <MascotNotice
          className={styles['notice-no-connect']}
          img="/lotties/mascot-phone.json"
          text={
            <>
              Connect your wallet
              <br />
              to view your created campaigns
            </>
          }
        >
          <ButtonGradient
            className={styles['connect-wallet']}
            iconBefore={<Wallet />}
            onClick={() => {
              setIsOpen(true);
            }}
          >
            Connect Wallet
          </ButtonGradient>
        </MascotNotice>
      )}
    </div>
  );
};

export default CampaignCreatedDetail;
