import React, { useEffect, useRef, useState } from 'react';
import styles from './styles.module.scss';
import BaseButton from '../Button';
import { ArrowDown } from 'assets/svg-component';
import classNames from 'classnames';

interface DataDropdownProps {
  label?: string;
  value?: string;
}

interface DropdownProps {
  data: DataDropdownProps[];
  setValue(value: string): void;
}

const Dropdown: React.FC<DropdownProps> = ({
  data = [],
  setValue = () => { }
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [indexActive, setIndexActive] = useState<number>(0);
  const dropDownRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    function closeDropdown(e: any) {
      const cond = dropDownRef?.current ? (e.target === dropDownRef.current || dropDownRef.current.contains(e.target)) : null;
      if (open && !cond) setOpen(false);
    }
    window.addEventListener('click', closeDropdown);
    return () => {
      window.removeEventListener('click', closeDropdown);
    };
  }, [open]);
  useEffect(() => {
    let value: string | undefined = data[indexActive]?.value;
    if (value) {
      setValue(value);
    } else {
      setValue('');
    }
  }, [data, indexActive, setValue]);
  return (
    <div className={styles['dropdown']}>
      <div onClick={() => {
        setOpen(prev => {
          return !prev;
        });
      }} ref={dropDownRef}>
        <BaseButton
          outline
          iconAfter={<ArrowDown />}
          className={styles['dropdown-btn']}

        >
          {data[indexActive]?.label}
        </BaseButton>
      </div>
      <div
        className={classNames(styles['dropdown-list'], {
          [styles['open']]: open
        })}
      >
        {data?.map((item, index) => {
          if (indexActive !== index)
            return (
              <label
                key={index}
                className={styles['item']}
                onClick={() => {
                  setIndexActive(index);
                }}
                data-value={item?.value}
              >
                {item?.label}
              </label>
            );
          return null;
        })}
      </div>
    </div>
  );
};

export default Dropdown;
