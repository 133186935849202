import type { ButtonProps } from 'antd/es/button';
import { FC } from 'react';
import cn from 'classnames';
import { Button } from 'antd';
// import { useSelector } from 'react-redux';
import styles from './styles.module.scss';
import { Link } from 'react-router-dom';
import { Player } from '@lottiefiles/react-lottie-player';

export interface MyButtonProps extends ButtonProps {
  center?: boolean;
  to?: string;
  targetHref?: string;
  disabled?: any;
  outline?: boolean | string;
  iconAfter?: any;
  loading?: boolean;
}

const BaseButton: FC<MyButtonProps> = ({
  className,
  center,
  disabled,
  outline,
  to,
  targetHref,
  onClick,
  style,
  children,
  iconAfter,
  loading,
  ...props
}) => {
  let upperCaseClass = '';
  // const { device } = useSelector((state) => state.user);
  // const isMobile = device === 'MOBILE';
  return (
    <Button
      className={cn('btn', className, styles['btn-primary'], upperCaseClass, {
        [styles['center']]: center,
        [styles['disabled']]: disabled,
        [styles['outline']]: outline,
        [styles['loading']]: loading
      })}
      onClick={onClick}
      style={{
        ...style,
        color: outline && typeof outline === 'string' ? outline : ''
      }}
      {...props}
    >
      {to ? (
        <Link to={to}>
          <span>
            {' '}
            {loading ? (
              <div className={styles['loading-frame']}>
                <Player
                  src="/lotties/loading.json"
                  className={styles['player']}
                  loop
                  autoplay
                />
              </div>
            ) : (
              children
            )}
          </span>
        </Link>
      ) : (
        <>
          {' '}
          {loading ? (
            <div className={styles['loading-frame']}>
              <Player
                src="/lotties/loading.json"
                className={styles['player']}
                loop
                autoplay
              />
            </div>
          ) : (
            children
          )}
        </>
      )}
      {iconAfter}
    </Button>
  );
};

// const MyButton = Object.assign(Button, BaseButton);

export default BaseButton;
