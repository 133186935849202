export function setLocalStorage(cookieName: string, cookieValue: any) {
  window.localStorage.setItem(cookieName, JSON.stringify(cookieValue));
}
export function getLocalStorage(cookieName: string) {
  const result = window.localStorage.getItem(cookieName);
  if (result) {
    return JSON.parse(result || '');
  }
  return undefined
}
export function deleteLocalStorage(cookieName: string) {
  window.localStorage.removeItem(cookieName);
  return true;
}
