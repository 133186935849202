import classnames from 'classnames';
import { SidebarProps } from './interface';

import logo from 'assets/images/logo.svg';

import styles from './styles.module.scss';
import data, { ActiveRoutesObjectProps, ScopeEnum } from './data';

import fileQuestionIcon from './icon-sidebar/file-question.svg';
import { Link, useLocation } from 'react-router-dom';
import { useStoreModal } from 'context/StoreModal';
import { Footer } from 'components';
// import { formatNumberCoin } from 'helpers/number';

const Sidebar: React.FC<SidebarProps> = ({ className, ...props }) => {
  const classes = classnames(styles['sidebar'], className);
  const location = useLocation();
  const { setIsOpen } = useStoreModal('coming-soon');
  function detectActive(routes: (string | ActiveRoutesObjectProps)[] = []) {
    const path = location.pathname;
    for (let route in routes) {
      if (typeof routes[route] === 'string') {
        if (routes[route] === path) {
          return true;
        }
      } else {
        const routeObject = routes[route] as ActiveRoutesObjectProps;
        if (
          path.includes(routeObject?.route) &&
          routeObject?.scope === ScopeEnum.Includes
        ) {
          return true;
        } else if (
          routeObject?.route === path &&
          routeObject?.scope === ScopeEnum.Strict
        ) {
          return true;
        }
      }
    }
    return false;
  }
  return (
    <div className={classes}>
      <div className={styles['content']}>
        <Link to="/" className={styles['logo-sidebar']}>
          <img src={logo} alt="logo" />
        </Link>
        <div className={styles['navigate']}>
          {data?.map((item: any, index: number) => {
            return (
              <div key={index}>
                {item?.to && item?.to !== 'comingsoon' ? (
                  <Link
                    className={classnames(
                      styles['item-sidebar'],
                      styles[item?.className],
                      {
                        [styles['active']]: detectActive(item?.active)
                      }
                    )}
                    to={item?.to}
                    target={item?.active === '_blank' ? '_blank' : ''}
                  >
                    <img src={item?.icon} alt="sidebar-icon" />
                    <label className={styles['label-pc']}>{item?.label}</label>
                    <label className={styles['label-mobile']}>
                      {item?.label_mobile}
                    </label>
                  </Link>
                ) : (
                  <div
                    className={classnames(
                      styles['item-sidebar'],
                      styles[item?.className],
                      {
                        [styles['active']]: detectActive(item?.active)
                      }
                    )}
                    onClick={() => {
                      if (item?.to === 'comingsoon') {
                        setIsOpen(true);
                      } else {
                        item?.onClick();
                      }
                    }}
                  >
                    <img src={item?.icon} alt="sidebar-icon" />
                    <label className={styles['label-pc']}>{item?.label}</label>
                    <label className={styles['label-mobile']}>
                      {item?.label_mobile}
                    </label>
                  </div>
                )}
              </div>
            );
          })}
        </div>

        <div className={styles['bottom']}>
          <Link
            className={styles['how-to']}
            to={'https://stakify-2.gitbook.io/user-guide-for-stakify/'}
            target="_blank"
          >
            <img src={fileQuestionIcon} alt="how-to?" />
            <label>How to</label>
          </Link>
          <Footer isMobile={false} />
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
