import { Link } from 'react-router-dom';
import styles from './styles.module.scss';
import telegram from 'layout/SideBar/icon-sidebar/telegram.svg';
import twitter from 'layout/SideBar/icon-sidebar/twitter.svg';
import github from 'layout/SideBar/icon-sidebar/github.svg';

interface FooterProps {
  isMobile: boolean;
}

const Footer: React.FC<FooterProps> = ({ isMobile = false }) => {
  return (
    <div className={`${styles['copy-right']} ${isMobile && styles['mobile']}`}>
      <label>© Stakify 2023</label>
      <div className={styles['follow']}>
        <Link to={''}>
          <img src={telegram} alt="telegram" />
        </Link>
        <Link to={'https://twitter.com/Stakify_zone'} target="_blank">
          <img src={twitter} alt="twitter" />
        </Link>
        <Link
          to={'https://github.com/buzz-space/stakify-smart-contract'}
          target="_blank"
        >
          <img src={github} alt="github" />
        </Link>
      </div>
    </div>
  );
};

export default Footer;
