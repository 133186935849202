import { Player } from '@lottiefiles/react-lottie-player';
import { Spin } from 'antd';
import styles from './styles.module.scss';
import { classnames } from 'helpers';

interface LoadingProps {
  screen?: boolean
}

const Loading: React.FC<LoadingProps> = ({ screen = false }) => {
  return (
    <div className={classnames(styles['loading'], {
      [styles['screen']]: screen,
    })}>
      {/* <Spin size="large" /> */}
      <Player
        src='/lotties/loading-screen.json'
        className={styles['player']}
        loop
        autoplay
      />
    </div>
  );
};

export default Loading;
