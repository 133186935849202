import * as React from 'react';
const SvgInformation = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16px"
    height="16px"
    fill="none"
    viewBox="0 0 12 13"
    role={'icon-default'}
    {...props}
  >
    <path
      stroke={'currentColor'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M6 6.5v2.4m0-4.179V4.7M1.2 6.5a4.8 4.8 0 1 1 9.6 0 4.8 4.8 0 0 1-9.6 0Z"
    />
  </svg>
);
export default SvgInformation;
