import { CosmWasmClient, SigningCosmWasmClient } from "@cosmjs/cosmwasm-stargate";
import { CW20, CW20Instance, createClient, createClientPublic, useError, useSdk } from "core/logic";
import moment from "moment";
import { config } from 'config';
import { Campaign, CampaignInstance } from "core/logic/service/campaign";
import { useEffect, useState } from "react";
import { CampaignService } from "interface/campaign/campaign_service";

export function getTimestampInSeconds() {
    return Date.now() / 1000;
}


export function checkStatus(start_time: number, end_time: number) {
    if (getTimestampInSeconds() < start_time) {
        return 'Upcoming';
    } else if (
        start_time < getTimestampInSeconds() &&
        getTimestampInSeconds() < end_time
    ) {
        return 'Active';
    } else {
        return 'Ended';
    }
}

export function checkStatus2(start_time: string | undefined, end_time: string | undefined, campaignDetail: CampaignService | undefined | null) {
    let startTime = moment(start_time).unix();
    let endTime = moment(end_time).unix();
    let nowTime = getTimestampInSeconds();
    if (Number(campaignDetail?.reward_per_second) === 0) {
        return "Pending";
    }
    else if (nowTime < startTime) {
        return 'Upcoming';
    }
    else if (
        startTime < nowTime &&
        nowTime < endTime
    ) {
        return 'Active';
    } else {
        return 'Ended';
    }
}



export function useCreateCampaign(addressCampaign: string | undefined = '') {
    const { initialized, getSigner } = useSdk();
    const { setError } = useError();
    const [campaignClient, setCampaignClient] = useState<CampaignInstance>();
    useEffect(() => {
        (async function updateClient(): Promise<void> {
            try {
                let clientLocal: SigningCosmWasmClient | CosmWasmClient;
                if (initialized) {
                    clientLocal = await createClient(config, getSigner());
                } else {
                    clientLocal = await createClientPublic(config);
                }
                // setClient(clientLocal);
                const cpContractClient = Campaign(clientLocal);
                const cpClient = cpContractClient.use(addressCampaign);
                setCampaignClient(cpClient);
            } catch (error: any) {
                setError(error.message);
            }
        })();
    }, [addressCampaign, initialized])
    return campaignClient;
}

export function useCreateCw20(address: string | undefined = '') {
    const { initialized, getSigner } = useSdk();
    const { setError } = useError();
    const [cw20Client, setCw20Client] = useState<CW20Instance>();
    useEffect(() => {
        (async function updateClient(): Promise<void> {
            try {
                let clientLocal: SigningCosmWasmClient | CosmWasmClient;
                if (initialized) {
                    clientLocal = await createClient(config, getSigner());
                } else {
                    clientLocal = await createClientPublic(config);
                }
                // setClient(clientLocal);
                const cw20ContractClient = CW20(clientLocal);
                const cw20 = cw20ContractClient.use(address);
                setCw20Client(cw20);
            } catch (error: any) {
                setError(error.message);
            }
        })();
    }, [address, initialized])
    return cw20Client;
}

export function handleMsgErr(msg: string): string | undefined {
    const match = msg.match(/##(.*?)##/);
    console.log(msg);
    if (match) {
        return match[1];
    } else {
        return msg;
    }
}