import Decimal from "decimal.js";

export function formatEthNumber(number: Number): string {
    // Convert the number to a floating-point number with two decimal places
    let base = 10 ** 3;
    let result = Math.round(Number(number) * base) / base;
    // var formattedNumber = ().toPrecision();

    // Convert the number to the desired format with commas
    var parts = (result.toString()).split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    return parts.join('.');
}

export function formatNumber(number: Number): string {
    var formattedNumber = Number(number).toLocaleString('en-US', {
        minimumIntegerDigits: 1,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    });

    return formattedNumber;
}

export function formatNumberCoin(number: number | null, decimal: number | null | undefined): string {
    if (number) {
        let x = new Decimal(number);
        let result: Number = Number(x.div(Math.pow(10, Number(decimal))));
        return formatEthNumber(result);
    }
    return '0';
}

export function formatNumberCoinNum(number: number | null, decimal: number | null | undefined, typeNum = false): number {
    if (number) {
        let x = new Decimal(number);
        let result: number = Number(x.div(Math.pow(10, Number(decimal))));
        return result;
    }
    return 0;
}