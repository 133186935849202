import { Back } from 'assets/svg-component';
import ActionIcon from 'components/base/ActionIcon';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import styles from './styles.module.scss';
import InformationCampaign from 'components/common/InformationCampaign';
import YourRewards from 'components/pages/detail-campaign/YourRewards';
import ListStakeNFTs from 'components/pages/detail-campaign/ListStakeNFTs';
import { useSdk } from 'core/logic';
// import useContractCampaign from 'hooks/api/useContractCampaign';
// import { useClient } from 'core/logic/service/public';
import { useCampaignService } from 'context/CampaignService';
import { Loading } from 'components';
import { useClient } from 'core/logic/service/public';

const Fade = require('react-reveal/Fade');

interface DetailCampaignProps { }

const DetailCampaign: React.FC<DetailCampaignProps> = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { initialized } = useSdk();
  const {
    campaignDetail,
    addressContract,
    isLoadingCampaignDetail,
    nftStaked,
    fetchDataDetailCampaign
  } = useCampaignService();

  React.useEffect(() => {
    let addr = location?.pathname?.replace('/detail-campaign/', '');
    addr = addr?.replace('/created-campaign/campaign-created-detail/', '');
    fetchDataDetailCampaign(addr);
  }, [])

  return (
    <div>
      <div className={styles['back']}>
        <ActionIcon
          icon={<Back />}
          onClick={() => {
            navigate('/');
          }}
        />
        <label>Back</label>
      </div>
      {isLoadingCampaignDetail ? (
        <Loading />
      ) : (
        <Fade>
          {
            <InformationCampaign
              data={campaignDetail}
              addressContract={addressContract}
            />
          }
          {initialized ? (
            <>
              <YourRewards campaignDetail={campaignDetail} nfts={nftStaked} />
              <ListStakeNFTs
                nfts={nftStaked}
                campaignDetail={campaignDetail}
                campaignAddress={addressContract}
              />
            </>
          ) : (
            <></>
          )}
        </Fade>
      )}
    </div>
  );
};

export default DetailCampaign;
