import {
  CosmWasmClient,
  SigningCosmWasmClient
} from '@cosmjs/cosmwasm-stargate';
// import { Coin } from '@cosmjs/stargate';
// import { AssetTokenInfo } from './factory';

export type Expiration =
  | { readonly at_height: number }
  | { readonly at_time: number }
  | { readonly never: unknown };

export interface LockupTerm {
  readonly name: string;
  readonly value: number;
  readonly percent: string; // number
}

export interface NftStake {
  readonly token_ids: string[];
  readonly lockup_term: number;
}
export interface NftKey {
  readonly key: number;
  readonly lockup_term: number;
}

export interface NftKey {
  readonly key: number;
  readonly lockup_term: number;
}

export interface UnStakeNft {
  readonly key: number;
  readonly token_id: string;
  readonly lockup_term: number;
}

export interface CampaignInstance {
  readonly contractAddress: string;

  // queries
  campaign_info: () => Promise<any>;
  nft_info: (nft_key: NftKey) => Promise<any>;
  nft_staked: (owner: string) => Promise<any>;
  total_pending_reward: () => Promise<any>;
  has_un_stake_and_claim: () => Promise<any>;

  // actions
  add_reward_token: (sender: string, amount: string) => Promise<any>;
  stake_nfts: (sender: string, stake_info: NftStake) => Promise<any>;
  un_stake_nft: (
    sender: string,
    unstake_info: NftKey,
    token_id: string
  ) => Promise<any>;
  un_stake_and_claim_nft: (
    sender: string,
    un_stakes: UnStakeNft[],
    amount: string
  ) => Promise<any>;
  claim_reward: (sender: string, amount: string) => Promise<any>;
  withdraw_reward: (sender: string) => Promise<any>;
}

export interface CampaignContract {
  use: (contractAddress: string) => CampaignInstance;
  clientTest: any;
}

export const Campaign = (
  client: SigningCosmWasmClient | CosmWasmClient
): CampaignContract => {
  const clientTest = client;
  const use = (contractAddress: string): CampaignInstance => {
    // query
    const campaign_info = async (): Promise<any> => {
      return client.queryContractSmart(contractAddress, { campaign_info: {} });
    };

    const nft_info = async (nft_key: NftKey): Promise<any> => {
      return client.queryContractSmart(contractAddress, {
        nft_info: { nft_key }
      });
    };

    const nft_staked = async (owner: string): Promise<any> => {
      return client.queryContractSmart(contractAddress, {
        nft_staked: { owner }
      });
    };

    const total_pending_reward = async (): Promise<any> => {
      return client.queryContractSmart(contractAddress, {
        total_pending_reward: {}
      });
    };

    const has_un_stake_and_claim = async (): Promise<any> => {
      return client.queryContractSmart(contractAddress, {
        has_un_stake_and_claim: {}
      });
    };

    // action
    const add_reward_token = async (
      sender: string,
      amount: string
    ): Promise<string> => {
      if (client instanceof SigningCosmWasmClient) {
        const result = await client.execute(
          sender,
          contractAddress,
          { add_reward_token: { amount } },
          'auto',
          'memo'
        );
        return result.transactionHash;
      }
      return 'Unauthorized';
    };

    const stake_nfts = async (
      sender: string,
      stake_info: NftStake
    ): Promise<string> => {
      if (client instanceof SigningCosmWasmClient) {
        const result = await client.execute(
          sender,
          contractAddress,
          { stake_nfts: { stake_info } },
          'auto',
          'memo'
        );
        return result.transactionHash;
      }
      return 'Unauthorized';
    };
    const un_stake_nft = async (
      sender: string,
      unstake_info: NftKey,
      token_id: string
    ): Promise<string> => {
      if (client instanceof SigningCosmWasmClient) {
        const result = await client.execute(
          sender,
          contractAddress,
          { un_stake_nft: { unstake_info, token_id } },
          'auto',
          'memo'
        );
        return result.transactionHash;
      }
      return 'Unauthorized';
    };

    const un_stake_and_claim_nft = async (
      sender: string,
      un_stakes: UnStakeNft[],
      amount: string
    ): Promise<string> => {
      if (client instanceof SigningCosmWasmClient) {
        const result = await client.execute(
          sender,
          contractAddress,
          { un_stake_and_claim_nft: { un_stakes, amount } },
          'auto',
          'memo'
        );
        return result.transactionHash;
      }
      return 'Unauthorized';
    };

    const claim_reward = async (
      sender: string,
      amount: string
    ): Promise<string> => {
      if (client instanceof SigningCosmWasmClient) {
        const result = await client.execute(
          sender,
          contractAddress,
          { claim_reward: { amount: amount } },
          'auto',
          'memo'
        );
        return result.transactionHash;
      }
      return 'Unauthorized';
    };

    const withdraw_reward = async (sender: string): Promise<string> => {
      if (client instanceof SigningCosmWasmClient) {
        const result = await client.execute(
          sender,
          contractAddress,
          { withdraw_reward: {} },
          'auto',
          'memo'
        );
        return result.transactionHash;
      }
      return 'Unauthorized';
    };

    return {
      contractAddress,
      campaign_info,
      nft_info,
      total_pending_reward,
      has_un_stake_and_claim,
      nft_staked,
      add_reward_token,
      stake_nfts,
      un_stake_nft,
      un_stake_and_claim_nft,
      claim_reward,
      withdraw_reward
    };
  };
  return { use, clientTest };
};
