import type { ButtonProps } from 'antd/es/button';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { FC } from 'react';
import Button from 'antd/es/button';
// import Lottie from 'react-lottie';
// import * as animationData from 'assets/lotties/loading.json';

import { Player } from '@lottiefiles/react-lottie-player';

// import Button from 'antd/es/button';

export interface GradientButtonProps extends ButtonProps {
  backgroundSize?: number;
  iconBefore?: any;
  title?: string;
  disabled?: boolean;
  loading?: boolean;
}

const ButtonGradient: FC<GradientButtonProps> = ({
  children,
  onClick = () => {},
  className = '',
  iconBefore,
  title = '',
  disabled = false,
  loading = false
}) => {
  return (
    <Button
      className={classNames('btn', styles['btn-gradient'], className, {
        [styles['disabled']]: disabled,
        [styles['loading']]: loading
      })}
      onClick={onClick}
      style={
        {
          // backgroundSize: `${backgroundSize}% ${backgroundSize}%`
        }
      }
      title={title}
    >
      {iconBefore}
      {loading ? (
        <div className={styles['loading-frame']}>
          <Player
            src="/lotties/loading.json"
            className={styles['player']}
            loop
            autoplay
          />
        </div>
      ) : (
        children
      )}
    </Button>
  );
};

export default ButtonGradient;
