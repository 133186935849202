import ModalBase from 'components/base/ModalBase';
import { useStoreModal } from 'context/StoreModal';
import React from 'react';
import styles from './styles.module.scss';

// import { Button, Space, Typography } from 'antd';
import {
  configKeplr,
  loadKeplrWallet,
  useError,
  useSdk,
  WalletLoader
} from 'core/logic';

import { config } from 'config';
import { deleteLocalStorage, getLocalStorage, setLocalStorage } from 'helpers/localStorage';
import moment from 'moment';
import { getFirstConnect } from 'api/firstconnect.api';
import { useDispatch, useSelector } from 'react-redux';
import { commonActions } from 'store/common';
import { selectChooseWallet } from 'store/selectors';
import { checkExistedCoin98, getKeplr } from 'core/logic/utils/connect';
// const { Text } = Typography;

const ConnectWalletModal: React.FC = () => {
  const { isOpen, setIsOpen } = useStoreModal('connect-wallet');
  const dispatch = useDispatch();
  const { isOpen: isOpenTos, setIsOpen: setIsOpenTos } = useStoreModal('tos-policy');
  const { isOpen: isOpenSwitch, setIsOpen: setIsOpenSwitch } = useStoreModal('switch-account');
  const { setInitializing, initialized, clear } = useSdk();
  const { error, setError, clearError } = useError();
  const sdk = useSdk();
  // const chooseWallet = useSelector(selectChooseWallet);

  const [lastProviderState, setLastProviderState] = React.useState<any>(undefined);

  React.useEffect(() => {
    let lastProvider = getLocalStorage("LAST_USED_PROVIDER");
    setLastProviderState(lastProvider);
    const currentTimestamp = (moment().subtract(1, 'd').toDate().getTime());
    if (lastProvider && currentTimestamp < lastProvider?.timestamp) {
      const { provider, address } = lastProvider;
      loadKeplrWallet(config.chainId).then(signer => {
        signer.getAccounts().then(async (data) => {
          const addr = data[0]?.address;
          if (addr === address) {
            if (provider === 'Kelpr') {
              initKeplr();
            } else {
              initCoin98();
            }
          } else {
            deleteLocalStorage('LAST_USED_PROVIDER');
          }
        })
      });

    } else if (currentTimestamp > lastProvider?.timestamp) {
      deleteLocalStorage('LAST_USED_PROVIDER');
    }
  }, [])
  React.useEffect(() => {
    function keplrChanged() {
      if (initialized) {
        clear();
        deleteLocalStorage("LAST_USED_PROVIDER")
        setIsOpenSwitch(true);
      }
    }
    window.addEventListener('keplr_keystorechange', keplrChanged);
    return () => {
      window.removeEventListener('keplr_keystorechange', keplrChanged);
    }
  }, [initialized])
  async function init(loadWallet: WalletLoader, chooseWallet: string) {
    setInitializing(true);
    clearError();
    try {
      const signer = await loadWallet(config.chainId, config.addressPrefix);
      await signer.getAccounts().then(async (data) => {
        const addr = data[0]?.address;
        await getFirstConnect({ wallet: addr }).then((dt) => {
          if (dt?.data && data[0]) {
            setIsOpenTos(true);
            deleteLocalStorage('LAST_USED_PROVIDER');
          } else {
            sdk.init(signer);
            setInitializing(false);
            setLocalStorage('LAST_USED_PROVIDER', { provider: chooseWallet, timestamp: moment().toDate().getTime(), address: addr });
          }
        }).catch((err) => {
          console.log(err);
        })
      })
    } catch (error) {
      console.error(error);
      setError(Error(error as any).message);
      setInitializing(false);
    }
  }




  async function initKeplr() {
    try {
      const keplr = await getKeplr();

      if (keplr) {
        try {
          await keplr.experimentalSuggestChain(configKeplr(config));
          await keplr.enable(config.chainId);
          const account = await keplr.getKey(config.chainId);
          // keplr.signAmino(config.chainId,)
          await init(loadKeplrWallet, 'Keplr');
        } catch (error) {
          console.error(error);
          setError(Error(error as any).message);
        }
      } else {
        window.open(
          'https://chrome.google.com/webstore/detail/keplr/dmkamcknogkgcdfhhbddcghachkejeap?hl=en'
        );
      }
    } catch (error) {
      console.error(error);
      setError(Error(error as any).message);
    }
  }

  async function initCoin98() {
    const coin98 = checkExistedCoin98();
    if (coin98) {
      try {
        await coin98.experimentalSuggestChain(configKeplr(config));
        await coin98.enable(config.chainId);
        const account = await coin98.getKey(config.chainId);
        await init(loadKeplrWallet, 'Coin98');
      } catch (error) {
        console.error(error);
        setError(Error(error as any).message);
      }
    } else {
      window.open(
        'https://chrome.google.com/webstore/detail/coin98-wallet/aeachknmefphepccionboohckonoeemg'
      );
    }
  }

  if (error) {
    console.log(error);
  }

  async function walletClick(fn: any, wallet: string) {
    setIsOpen(false);
    dispatch(commonActions.changeChooseWallet({ wallet }));
    await fn();

  }

  return (
    <>
      {!initialized && (
        <ModalBase
          open={isOpen}
          onClose={() => {
            setIsOpen(false);
          }}
        >
          <div className={styles['connect-wallet']}>
            <h6 className={'title-modal'}>Connect wallet</h6>
            <div className={styles['des']}>
              Start by connecting with one of the wallets below. Be sure to
              store your private keys or seed phrase securely.
              <br />
              Never share them with anyone.
            </div>
            <div className={styles['list-wallet']}>
              <div
                className={styles['wallet']}
                onClick={() => {
                  walletClick(initCoin98, 'Coin98');
                }}
              >
                <img
                  src="/coin98.svg"
                  alt="coin98"
                  className={styles['logo']}
                />
                <label className={styles['name']}>Coin98</label>
              </div>
              <div
                className={styles['wallet']}
                onClick={() => {
                  walletClick(initKeplr, 'Keplr');
                }}
              >
                <img src="/keplr.png" alt="keplr" className={styles['logo']} />
                <label className={styles['name']}>Keplr</label>
              </div>
            </div>
          </div>
        </ModalBase>
      )}
    </>
  );
};

export default ConnectWalletModal;
