import AddRewardModal from 'components/modal/AddRewardModal';
import { useStoreModal } from 'context/StoreModal';
import { useSdk } from 'core/logic';
import { useClient } from 'core/logic/service/public';
import { CampaignService } from 'interface/campaign/campaign_service';
import { createContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { handleMsgErr } from 'utils/contract-utils';

export type IAddRewardContext = {
  open: (campaign: CampaignService | undefined) => void;
  balance: number;
  campaignDetail: CampaignService | undefined;
};

const initValues = {
  open: (campaign: CampaignService | undefined) => { },
  balance: 0,
  campaignDetail: undefined
};

export const AddRewardContext = createContext<IAddRewardContext>(initValues);

const AddRewardProvider: React.FC<{ children: React.ReactNode }> = ({
  children
}) => {
  const { isOpen, setIsOpen } = useStoreModal('add-reward');
  const [openState, setOpenState] = useState(false);
  const [campaignDetail, setCampaignDetail] = useState<CampaignService>();
  const [balance, setBalance] = useState<number>(0);
  const { campaign, cw20 } = useClient();
  const { address, initialized } = useSdk();
  function open(item: CampaignService | undefined) {
    // setReward(reward);
    setCampaignDetail(item);
    setOpenState(true);
  }
  useEffect(() => {
    if (openState) {
      setIsOpen(true);
      cw20
        ?.balance(address)
        .then((data) => {
          setBalance(Number(data));
        })
        .catch((err) => {
          let msg = handleMsgErr(err?.message);
          toast.error(msg);
        });
    }
  }, [openState, cw20]);
  useEffect(() => {
    setOpenState(isOpen);
  }, [isOpen]);
  return (
    <AddRewardContext.Provider value={{ open, balance, campaignDetail }}>
      {children}
      <AddRewardModal />
    </AddRewardContext.Provider>
  );
};

export default AddRewardProvider;
