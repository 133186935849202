import {
  CosmWasmClient,
  SigningCosmWasmClient
} from '@cosmjs/cosmwasm-stargate';
import { Coin } from '@cosmjs/stargate';

export type Expiration =
  | { readonly at_height: number }
  | { readonly at_time: number }
  | { readonly never: unknown };

export interface AllowanceResponse {
  readonly allowance: string; // integer as string
  readonly expires: Expiration;
}

export interface AllowanceInfo {
  readonly allowance: string; // integer as string
  readonly spender: string; // bech32 address
  readonly expires: Expiration;
}

export interface AllAllowancesResponse {
  readonly allowances: readonly AllowanceInfo[];
}

export interface TokenInfo {
  readonly name: string;
  readonly symbol: string;
  readonly decimals: number;
  readonly total_supply: string;
}

export interface Investment {
  readonly exit_tax: string;
  readonly min_withdrawal: string;
  readonly nominal_value: string;
  readonly owner: string;
  readonly staked_tokens: Coin;
  readonly token_supply: string;
  readonly validator: string;
}

export interface Claim {
  readonly amount: string;
  readonly release_at: { readonly at_time: number };
}

export interface Claims {
  readonly claims: readonly Claim[];
}

export interface AllAccountsResponse {
  // list of bech32 address that have a balance
  readonly accounts: readonly string[];
}

export interface Cw721Instance {
  readonly contractAddress: string;

  // queries
  owner_of: (token_id: string, include_expired?: boolean) => Promise<any>;
  approval: (
    spender: string,
    token_id: string,
    include_expired?: boolean
  ) => Promise<any>;
  approvals: (token_id: string, include_expired?: boolean) => Promise<any>;
  all_operators: (
    owner: string,
    start_after?: string,
    limit?: number | null,
    include_expired?: boolean
  ) => Promise<any>;
  num_tokens: () => Promise<any>;
  contract_info: () => Promise<any>;
  nft_info: (token_id: string) => Promise<any>;
  all_nft_info: (token_id: string, include_expired?: boolean) => Promise<any>;
  tokens: (owner: string, start_after?: string, limit?: number) => Promise<any>;
  all_tokens: (start_after?: string, limit?: number) => Promise<any>;
  minter: () => Promise<any>;

  // actions
  transfer_nft: (
    sender: string,
    recipient: string,
    token_id: string,
    fund?: any
  ) => Promise<any>;
  send_nft: (
    sender: string,
    contract: string,
    msg: string,
    token_id: string,
    fund?: any
  ) => Promise<any>;
  approve: (
    sender: string,
    spender: string,
    token_id: string,
    expires?: any,
    fund?: any
  ) => Promise<any>;
  revoke: (
    sender: string,
    spender: string,
    token_id: string,
    fund?: any
  ) => Promise<any>;
  approve_all: (
    sender: string,
    operator: string,
    expires?: any,
    fund?: any
  ) => Promise<any>;
  revoke_all: (sender: string, operator: string, fund?: any) => Promise<any>;
  mint: (
    sender: string,
    owner: string,
    token_id: string,
    extension?: any,
    token_uri?: string,
    fund?: any
  ) => Promise<any>;
  burn: (sender: string, token_id: string, fund?: any) => Promise<any>;
}

export interface Cw721Contract {
  use: (contractAddress: string) => Cw721Instance;
}

export const CW721 = (
  client: SigningCosmWasmClient | CosmWasmClient
): Cw721Contract => {
  const use = (contractAddress: string): Cw721Instance => {
    // query
    const owner_of = async (
      token_id: string,
      include_expired?: boolean
    ): Promise<any> => {
      return client.queryContractSmart(contractAddress, {
        owner_of: { token_id, include_expired }
      });
    };

    const approval = async (
      spender: string,
      token_id: string,
      include_expired?: boolean
    ): Promise<any> => {
      return client.queryContractSmart(contractAddress, {
        approval: { spender, token_id, include_expired }
      });
    };

    const approvals = async (
      token_id: string,
      include_expired?: boolean
    ): Promise<any> => {
      return client.queryContractSmart(contractAddress, {
        approvals: { token_id, include_expired }
      });
    };
    const all_operators = async (
      owner: string,
      start_after?: string,
      limit?: number | null,
      include_expired?: boolean
    ): Promise<any> => {
      return client.queryContractSmart(contractAddress, {
        all_operators: { owner, start_after, limit, include_expired }
      });
    };

    const num_tokens = async (): Promise<any> => {
      return client.queryContractSmart(contractAddress, {
        num_tokens: {}
      });
    };

    const contract_info = async (): Promise<any> => {
      return client.queryContractSmart(contractAddress, {
        contract_info: {}
      });
    };

    const nft_info = async (token_id: string): Promise<any> => {
      return client.queryContractSmart(contractAddress, {
        nft_info: { token_id }
      });
    };

    const all_nft_info = async (
      token_id: string,
      include_expired?: boolean
    ): Promise<any> => {
      return client.queryContractSmart(contractAddress, {
        all_nft_info: { token_id, include_expired }
      });
    };

    const tokens = async (
      owner: string,
      start_after?: string,
      limit?: number
    ): Promise<any> => {
      return client.queryContractSmart(contractAddress, {
        tokens: { owner, start_after, limit }
      });
    };

    const all_tokens = async (
      start_after?: string,
      limit?: number
    ): Promise<any> => {
      return client.queryContractSmart(contractAddress, {
        all_tokens: { start_after, limit }
      });
    };

    const minter = async (): Promise<any> => {
      return client.queryContractSmart(contractAddress, {
        minter: {}
      });
    };

    // action
    const transfer_nft = async (
      sender: string,
      recipient: string,
      token_id: string,
      fund?: any
    ): Promise<string> => {
      if (client instanceof SigningCosmWasmClient) {
        const result = await client.execute(
          sender,
          contractAddress,
          { transfer_nft: { recipient, token_id, fund } },
          'auto',
          'memo'
        );
        return result.transactionHash;
      }
      return 'Unauthorized';
    };

    const send_nft = async (
      sender: string,
      contract: string,
      msg: string,
      token_id: string,
      fund?: any
    ): Promise<string> => {
      if (client instanceof SigningCosmWasmClient) {
        const result = await client.execute(
          sender,
          contractAddress,
          { send_nft: { contract, token_id, msg, fund } },
          'auto',
          'memo'
        );
        return result.transactionHash;
      }
      return 'Unauthorized';
    };
    const approve = async (
      sender: string,
      spender: string,
      token_id: string,
      expires?: any,
      fund?: any
    ): Promise<string> => {
      if (client instanceof SigningCosmWasmClient) {
        const result = await client.execute(
          sender,
          contractAddress,
          { approve: { spender, token_id, expires, fund } },
          'auto',
          'memo'
        );
        return result.transactionHash;
      }
      return 'Unauthorized';
    };
    const revoke = async (
      sender: string,
      spender: string,
      token_id: string,
      fund?: any
    ): Promise<string> => {
      if (client instanceof SigningCosmWasmClient) {
        const result = await client.execute(
          sender,
          contractAddress,
          { revoke: { spender, token_id, fund } },
          'auto',
          'memo'
        );
        return result.transactionHash;
      }
      return 'Unauthorized';
    };
    const approve_all = async (
      sender: string,
      operator: string,
      expires?: any,
      fund?: any
    ): Promise<string> => {
      if (client instanceof SigningCosmWasmClient) {
        const result = await client.execute(
          sender,
          contractAddress,
          { approve_all: { operator, expires, fund } },
          'auto',
          'memo'
        );
        return result.transactionHash;
      }
      return 'Unauthorized';
    };
    const revoke_all = async (
      sender: string,
      operator: string,
      fund?: any
    ): Promise<string> => {
      if (client instanceof SigningCosmWasmClient) {
        const result = await client.execute(
          sender,
          contractAddress,
          { revoke_all: { operator, fund } },
          'auto',
          'memo'
        );
        return result.transactionHash;
      }
      return 'Unauthorized';
    };
    const mint = async (
      sender: string,
      owner: string,
      token_id: string,
      extension?: any,
      token_uri?: string,
      fund?: any
    ): Promise<string> => {
      if (client instanceof SigningCosmWasmClient) {
        const result = await client.execute(
          sender,
          contractAddress,
          { mint: { owner, token_id, extension, token_uri, fund } },
          'auto',
          'memo'
        );
        return result.transactionHash;
      }
      return 'Unauthorized';
    };
    const burn = async (
      sender: string,
      token_id: string,
      fund?: any
    ): Promise<string> => {
      if (client instanceof SigningCosmWasmClient) {
        const result = await client.execute(
          sender,
          contractAddress,
          { burn: { token_id, fund } },
          'auto',
          'memo'
        );
        return result.transactionHash;
      }
      return 'Unauthorized';
    };

    return {
      contractAddress,
      owner_of,
      approval,
      approvals,
      all_operators,
      num_tokens,
      contract_info,
      nft_info,
      all_nft_info,
      tokens,
      all_tokens,
      minter,
      transfer_nft,
      send_nft,
      approve,
      revoke,
      approve_all,
      revoke_all,
      mint,
      burn
    };
  };
  return { use };
};
