import React from 'react';
import styles from './styles.module.scss';
// import MascotNotice from 'components/common/MascotNotice';
import { collapseAddress } from 'utils/string';
import { StakingHistory } from 'interface/campaign/staking_history';
import moment from 'moment';
import { appSetting } from 'constants/configuration';
import { useCampaignService } from 'context/CampaignService';
import { getStakingHistory } from 'api/campaign/staking_history.api';

interface TableStakingHistoryProps {
}

const TableStakingHistory: React.FC<TableStakingHistoryProps> = () => {
  const { stakingHistory, campaignDetail } = useCampaignService();

  return (
    <div className={styles['table-staking-history']}>
      {/* <MascotNotice text='Cannot find any staking data.' img='/sleepToday.svg' /> */}
      <table className={styles['table']}>
        <thead>
          <tr>
            <th>Time</th>
            <th>NFT</th>
            <th>Owner</th>
            <th>Action</th>
            <th>Status</th>
            <th>Transaction hash</th>
          </tr>
        </thead>
        <tbody>
          {stakingHistory?.map((item: StakingHistory, index: number) => {
            const action = item?.action === 'stake_nfts' ? 'Stake NFTs' : 'Unstake NFTs';
            return (
              <tr key={index}>
                <td>
                  <div className={styles['time-date']}>
                    <label className={styles['time']}>{moment(item?.created_date).format('hh:mm A')}</label>
                    <label className={styles['date']}>{moment(item?.created_date).format('DD MMM YYYY')}</label>
                  </div>
                </td>
                <td>
                  <div className={styles['nft']}>
                    <img
                      src={item?.token_image || campaignDetail?.image || '/cloud.svg'}
                      alt="logo"
                      className={styles['logo']}
                    />
                    {/* <label className={styles['name']}>{item?.name}</label> */}
                  </div>
                </td>
                <td><a target='__blank' href={`${appSetting.scan}/account/${item?.owner}`}>
                  {collapseAddress(item?.owner, 7, 3)}
                </a></td>
                <td>
                  {action}
                </td>
                <td>
                  Success
                </td>
                <td><a target='__blank' href={`${appSetting.scan}/transaction/${item?.transaction_hash}`}>
                  {collapseAddress(item?.transaction_hash, 6, 3)}
                </a></td>
              </tr>
            );
          })}
        </tbody>
      </table>

      <div className={styles['list-mobile']}>
        {stakingHistory?.map((item: StakingHistory, index: number) => {
          const action = item?.action === 'stake_nfts' ? 'Stake NFTs' : 'Unstake NFTs';
          return <div className={styles['list-mobile__item']} key={index}>
            <img src={item?.token_image || campaignDetail?.image || '/cloud.svg'} className={styles['logo']} alt={item?.token_image} />
            <div className={styles['infor']}>
              {/* <label className={styles['campaign-name']}>{item?.collection_name}</label> */}
              <label className={styles['collection-name']}>{moment(item?.created_date).format('hh:mm A, DD MMM YYYY')}</label>
              <div className={styles['row-infor']}>
                <label className={styles['row-infor__title']}>Owner</label>
                <a className={styles['row-infor__value']} href={`${appSetting.scan}/account/${item?.owner}`}>
                  {collapseAddress(item?.owner, 7, 3)}</a>
              </div>
              <div className={styles['row-infor']}>
                <label className={styles['row-infor__title']}>Action</label>
                <label className={styles['row-infor__value']}>{action}</label>
              </div>
              <div className={styles['row-infor']}>
                <label className={styles['row-infor__title']}>Transaction hash</label>
                <a target='__blank' href={`${appSetting.scan}/transaction/${item?.transaction_hash}`} className={styles['row-infor__value']}>
                  {collapseAddress(item?.transaction_hash, 6, 3)}
                </a>
              </div>
              <div className={styles['footer']}>
                <label className={styles['footer-label']}>Success</label>
              </div>
            </div>
          </div>
        })}
      </div>
    </div>
  );
};

export default TableStakingHistory;
