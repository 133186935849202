import React from 'react'
import styles from './styles.module.scss';

interface ListBoxInforProps {
    title: string;
    value: any;
}

interface BoxInforProps {
    title: string;
    list: ListBoxInforProps[];
}

const BoxInfor: React.FC<BoxInforProps> = ({ title = '', list = [] }) => {
    return (
        <div className={styles['box-infor']}>
            <h6 className={styles['title']}>{title}</h6>
            <div className={styles['list-box']}>
                {
                    list?.map((item, index) => {
                        return <div className={styles['row']} key={index}>
                            <label className={styles['row-title']}>{item?.title}</label>
                            <label className={styles['row-value']}>{item?.value}</label>
                        </div>
                    })
                }
            </div>
        </div>
    )
}

export default BoxInfor