import * as React from 'react';
import styles from './styles.module.scss';

interface InforCardProps {
  icon: any;
  description: string;
  text: string;
}

const InforCard: React.FC<InforCardProps> = ({ icon, description, text }) => {
  return (
    <div className={styles['infor-card']}>
      {icon}
      <div className={styles['information']}>
        <label className={styles['text']}>{text}</label>
        <label className={styles['des']}>{description}</label>
      </div>
    </div>
  );
};

export default InforCard;
