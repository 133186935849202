import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { CampaignService } from 'interface/campaign/campaign_service';
import { formatNumberCoin } from 'helpers/number';
import classNames from 'classnames';
import { toTitleCase } from 'utils/string';
import {
  checkStatus2,
  handleMsgErr,
  useCreateCw20
} from 'utils/contract-utils';
import ActionIcon from 'components/base/ActionIcon';
import { Add, Check, Dash, FileEyes } from 'assets/svg-component';
import { useClient } from 'core/logic/service/public';
import { useSdk } from 'core/logic';
import { useCampaignService } from 'context/CampaignService';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useAddReward } from 'context/AddReward';
import { fetchingCampaign } from 'api/campaign/fetching_campaign.api';

interface RowCreatedCampaignProps {
  data: CampaignService;
  isMobile?: boolean;
}

const RowCreatedCampaign: React.FC<RowCreatedCampaignProps> = ({
  data,
  isMobile = false
}) => {
  const status = checkStatus2(data?.start, data?.end, data);
  const cw20Client = useCreateCw20(data?.token_address);
  const [loadingWithdraw, setLoadingWithdraw] = useState<boolean>(false);
  const [loadingApprove, setLoadingApprove] = useState<boolean>(false);
  const { campaign, createCampaign, createCw20 } = useClient();
  const { address } = useSdk();
  const navigate = useNavigate();
  const { open } = useAddReward();
  const { fetchDataCreatedCampaign } = useCampaignService();
  const [isApproved, setIsApproved] = useState<boolean>(false);
  const [balance, setBalance] = useState<number>(0);

  const handleWithdrawReward = async () => {
    setLoadingWithdraw(true);
    createCampaign(data?.address);
  };

  useEffect(() => {
    if (status === 'Pending' || status === 'Upcoming') {
      let balance = 0;
      cw20Client
        ?.balance(address)
        .then(async (dataBalance) => {
          balance = Number(dataBalance);
          setBalance(balance);
          cw20Client
            ?.allowance(address, data?.address)
            .then((dataAllowance) => {
              if (Number(dataAllowance?.allowance) < balance) {
                setIsApproved(false);
              } else {
                setIsApproved(true);
              }
            })
            .catch((err) => {
              let msg = handleMsgErr(err?.message);
              toast.error(msg);
            });
        })
        .catch((err) => {
          console.log({ err });
          let msg = handleMsgErr(err?.message);
          toast.error(msg);
        });
    }
  }, [data, cw20Client]);

  useEffect(() => {
    if (loadingWithdraw) {
      campaign
        ?.withdraw_reward(address)
        .then(async (data) => {
          toast.success('Withdraw successfully');
          await fetchingCampaign({
            campaign_address: campaign.contractAddress
          });
          fetchDataCreatedCampaign();
          setLoadingWithdraw(false);
        })
        .catch((err) => {
          setLoadingWithdraw(false);
          console.log(err);
          let msg = handleMsgErr(err?.message);
          toast.error(msg);
        });
    }
  }, [loadingWithdraw, campaign]);

  function approveToken() {
    setLoadingApprove(true);
    cw20Client
      ?.increaseAllowance(
        address,
        data?.address,
        balance.toLocaleString('fullwide', { useGrouping: false })
      )
      .then((data) => {
        toast.success('Approve successfully');
        setIsApproved(true);
        setLoadingApprove(false);
      })
      .catch((err) => {
        setLoadingApprove(false);
        let msg = handleMsgErr(err?.message);
        toast.error(msg);
      });
  }

  const handleAddReward = (item: CampaignService) => {
    createCampaign(item?.address);
    createCw20(item?.token_address);
    open(item);
  };

  if (isMobile) {
    return (
      <div className={styles['list-mobile__item']}>
        <img src={data?.image} className={styles['logo']} />
        <div className={styles['infor']}>
          <label className={styles['campaign-name']}>{data?.name}</label>
          <label className={styles['collection-name']}>
            {data?.description}
          </label>
          <div className={styles['row-infor']}>
            <label className={styles['row-infor__title']}>Total staked</label>
            <label className={styles['row-infor__value']}>
              {((data?.total_staked / data?.collection_minted) * 100).toFixed(
                2
              )}{' '}
              %
            </label>
          </div>
          <div className={styles['row-infor']}>
            <label className={styles['row-infor__title']}>Daily rewards</label>
            <label className={styles['row-infor__value']}>
              {
                formatNumberCoin(
                  Number(data?.reward_per_second) * 86400,
                  data?.token_decimal
                )
              }{' '}
              {data?.token_symbol}
            </label>
          </div>
          <div className={styles['row-infor']}>
            <label className={styles['row-infor__title']}>
              Remaining rewards
            </label>
            <label
              className={styles['row-infor__value']}
              style={{ color: '#7F6AFF' }}
            >
              {
                formatNumberCoin(
                  Number(data?.token_amount_remaining),
                  data?.token_decimal
                )
              }{' '}
              {data?.token_symbol}
            </label>
          </div>
          <div className={styles['footer']}>
            <div className={classNames('status', status.toLowerCase())}>
              <div className={classNames('circle')}></div>
              {toTitleCase(status)}
            </div>
            <div className={classNames(styles['interaction'])}>
              {status === 'Ended' ? (
                <ActionIcon
                  icon={<Dash />}
                  onClick={() => handleWithdrawReward()}
                  tooltip="Withdraw rewards"
                  loading={loadingWithdraw}
                  disable={data?.token_amount_remaining == 0}
                />
              ) : isApproved || status === 'Active' ? (
                <ActionIcon
                  icon={<Add />}
                  disable={status === 'Active'}
                  tooltip="Add rewards"
                  onClick={() => handleAddReward(data)}
                />
              ) : (
                <ActionIcon
                  icon={<Check />}
                  // disable={status === 'Active'}
                  loading={loadingApprove}
                  tooltip="Approve token"
                  onClick={() => approveToken()}
                />
              )}
              <ActionIcon
                icon={<FileEyes />}
                tooltip="View campaign detail"
                onClick={() => {
                  navigate(
                    `/created-campaign/campaign-created-detail/${data?.address || ''
                    }`
                  );
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <tr className={styles['row-created-campaign']}>
      <td>
        <div className={styles['infor-campaign']}>
          <img src={data?.image} className={styles['logo']} />
          <div className={styles['information']}>
            <label className={styles['name-campaign']}>{data?.name}</label>
            <label className={styles['name-collection']}>
              {data?.collection_name}
            </label>
          </div>
        </div>
      </td>
      <td>
        {((data?.total_staked / data?.collection_minted) * 100).toFixed(2)} %
      </td>
      <td>
        {formatNumberCoin(data?.reward_per_second * 86400, data?.token_decimal)}{' '}
        {data?.token_symbol}
      </td>
      <td>
        <div className={classNames('status', status?.toLowerCase())}>
          <div className={'circle'}></div>
          {toTitleCase(status)}
        </div>
      </td>
      <td>
        <div className={styles['remaining-rewards']}>
          <label className={styles['label-remaining']}>
            {
              formatNumberCoin(
                Number(data?.token_amount_remaining),
                data?.token_decimal
              )}{' '}
            {data?.token_symbol}
          </label>
          <div className={styles['progress']}>
            <div
              className={styles['active-progress']}
              style={{
                width: `${(Number(data?.token_amount_remaining) /
                  Number(data?.token_amount)) *
                  100
                  }%`
              }}
            ></div>
          </div>
        </div>
      </td>
      <td>
        <div className={styles['interaction']}>
          {status === 'Ended' ? (
            <ActionIcon
              icon={<Dash />}
              onClick={() => handleWithdrawReward()}
              tooltip="Withdraw rewards"
              loading={loadingWithdraw}
              disable={data?.token_amount_remaining == 0}
            />
          ) : isApproved || status === 'Active' ? (
            <ActionIcon
              icon={<Add />}
              disable={status === 'Active'}
              tooltip="Add rewards"
              onClick={() => handleAddReward(data)}
            />
          ) : (
            <ActionIcon
              icon={<Check />}
              // disable={status === 'Active'}
              loading={loadingApprove}
              tooltip="Approve token"
              onClick={() => approveToken()}
            />
          )}
          <ActionIcon
            icon={<FileEyes />}
            tooltip="View campaign detail"
            onClick={() => {
              navigate(
                `/created-campaign/campaign-created-detail/${data?.address || ''
                }`
              );
            }}
          />
        </div>
      </td>
    </tr>
  );
};

export default RowCreatedCampaign;
