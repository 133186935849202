import { Suspense, useLayoutEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { NotFound, Loading } from './components';
import ErrorBoundary from './components/ErrorBoundary';
import { config } from './config';
import { ErrorProvider, SdkProvider } from './core/logic';
import AddCampaign from 'pages/add-campaign';
import { StoreModalProvider } from 'context/StoreModal';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { StakeNFTsProvider } from 'context/StakeNFTs';
import { ClientProvider } from 'core/logic/service/public';
import { ClaimRewardProvider } from 'context/ClaimReward';
import { AddRewardProvider } from 'context/AddReward';
import { SearchProvider } from 'context/SearchContext';
import { CampaignServiceProvider } from 'context/CampaignService';

import LayoutPage from './layout';
import PublicCampaign from './pages/public-campaign';
import CreatedCampaign from './pages/created-campaign';
import DetailCampaign from './pages/detail-campaign';
import CampaignCreatedDetail from './pages/created-campaign/campaign-created-detail';
import SearchPage from './pages/search';
import { UnstakeAllProvider } from 'context/UnstakeAll';

function App() {
  useLayoutEffect(() => {
    const bodyClass = document.body.classList;
    !bodyClass.contains('stakify') && bodyClass.add('stakify');
  }, []);

  return (
    <div>
      <ErrorProvider>
        <SdkProvider config={config}>
          <ClientProvider>
            <ErrorBoundary fallback={<ErrorBoundary.Fallback />}>
              <CampaignServiceProvider>
                <StoreModalProvider>
                  <StakeNFTsProvider>
                    <ClaimRewardProvider>
                      <UnstakeAllProvider>
                        <AddRewardProvider>
                          <SearchProvider>
                            <Suspense fallback={<Loading screen />}>
                              <Routes>
                                <Route path="" Component={LayoutPage}>
                                  <Route path="/" Component={PublicCampaign} />
                                  <Route
                                    path="/add-campaign"
                                    Component={AddCampaign}
                                  />
                                  <Route
                                    path="/created-campaign"
                                    Component={CreatedCampaign}
                                  />
                                  <Route
                                    path="/created-campaign/campaign-created-detail/:address"
                                    Component={CampaignCreatedDetail}
                                  />
                                  <Route
                                    path="/public-campaign"
                                    Component={PublicCampaign}
                                  />
                                  <Route
                                    path="/detail-campaign/:address"
                                    Component={DetailCampaign}
                                  />
                                  <Route
                                    path="/search"
                                    Component={SearchPage}
                                  />

                                  <Route Component={NotFound} />
                                </Route>
                              </Routes>
                            </Suspense>
                          </SearchProvider>
                        </AddRewardProvider>
                      </UnstakeAllProvider>
                    </ClaimRewardProvider>
                  </StakeNFTsProvider>
                </StoreModalProvider>
              </CampaignServiceProvider>
            </ErrorBoundary>
          </ClientProvider>
        </SdkProvider>
      </ErrorProvider>
      <ToastContainer position="bottom-right" />
    </div>
  );
}

export default App;
