import * as React from 'react';
const SvgUserProfile = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16px"
    height="16px"
    fill="none"
    viewBox="0 0 60 60"
    role={'icon-default'}
    {...props}
  >
    <path
      stroke={'currentColor'}
      strokeLinecap="round"
      strokeWidth={1.5}
      d="M48 34.214c3.168 2.367 6 8.328 6 12 0 1.144-.835 2.072-1.864 2.072H51M39 24.51a6.854 6.854 0 0 0 3.429-5.94A6.854 6.854 0 0 0 39 12.631M7.864 48.287h32.843c1.03 0 1.864-.928 1.864-2.072 0-7.191-6.008-13.021-18.285-13.021C12.008 33.193 6 39.023 6 46.214c0 1.144.835 2.072 1.864 2.072ZM31.143 18.57a6.857 6.857 0 1 1-13.715 0 6.857 6.857 0 0 1 13.715 0Z"
    />
  </svg>
);
export default SvgUserProfile;
