export function getKeplr() {
    const anyWindow: any = window;
    if (anyWindow.keplr) {
        return anyWindow.keplr;
    }

    if (document.readyState === 'complete') {
        return anyWindow.keplr;
    }

    return new Promise((resolve) => {
        const documentStateChange = (event: Event) => {
            if (
                event.target &&
                (event.target as Document).readyState === 'complete'
            ) {
                resolve(anyWindow.keplr);
                document.removeEventListener('readystatechange', documentStateChange);
            }
        };

        document.addEventListener('readystatechange', documentStateChange);
    });
}

export function checkExistedCoin98() {
    const anyWindow: any = window;
    if (anyWindow.coin98) {
        if (anyWindow.coin98.keplr) {
            return anyWindow.coin98.keplr;
        } else {
            return undefined; // c98 not override keplr
        }
    }

    return null; // not found coin98
}