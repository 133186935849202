import * as React from 'react';
const SvgCalendar = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16px"
    height="16px"
    fill="none"
    viewBox="0 0 60 60"
    role={'icon-default'}
    {...props}
  >
    <path
      stroke={'currentColor'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M16.286 18.75H43.5m-29.071-15v4.5M45 3.75v4.5m9 3v42a3 3 0 0 1-3 3H9a3 3 0 0 1-3-3v-42a3 3 0 0 1 3-3h42a3 3 0 0 1 3 3Z"
    />
  </svg>
);
export default SvgCalendar;
