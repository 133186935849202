import { IStateCampaignService } from './IStateCampaignService';

export const initialState: IStateCampaignService = {
  campaignDetail: undefined,
  isLoadingCampaignDetail: false,
  campaignAnalysis: undefined,

  publicCampaign: undefined,
  isLoadingPublicCampaign: false,

  stakedCampaign: undefined,
  isLoadingStakedCampaign: false,

  createdCampaign: undefined,
  isLoadingCreatedCampaign: false,

  stakingHistory: undefined,

  rewardDebt: '0',
  rewardClaimed: '0',
  nftStaked: [],

  addressContract: ''
};
