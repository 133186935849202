import { CheckboxGroupProps } from 'antd/es/checkbox';
import React from 'react';
import styles from './styles.module.scss';

interface CheckboxProps extends CheckboxGroupProps {
  label?: string;
  checked: boolean;
  setChecked(value: any): void;
}

const Checkbox: React.FC<CheckboxProps> = ({ label, checked, setChecked }) => {
  return (
    <div className={styles['checkbox']} onClick={setChecked}>
      {checked ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          className={styles['checkbox']}
        >
          <path
            d="M5.9999 21.6C4.01168 21.6 2.3999 19.9882 2.3999 18V5.9999C2.3999 4.01168 4.01168 2.3999 5.9999 2.3999H17.9999C19.9881 2.3999 21.5999 4.01168 21.5999 5.9999L21.5999 18C21.5999 19.9882 19.9881 21.6 17.9999 21.6H5.9999Z"
            fill="#7F6AFF"
          />
          <path
            d="M15.5999 9.5999L12.9151 11.9999L10.2303 14.3999L8.3999 12.7637M21.5999 5.9999L21.5999 18C21.5999 19.9882 19.9881 21.6 17.9999 21.6H5.9999C4.01168 21.6 2.3999 19.9882 2.3999 18V5.9999C2.3999 4.01168 4.01168 2.3999 5.9999 2.3999H17.9999C19.9881 2.3999 21.5999 4.01168 21.5999 5.9999Z"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ) : (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M18 2.3999C19.9882 2.3999 21.6 4.01168 21.6 5.9999V18C21.6 19.9882 19.9882 21.6 18 21.6H6.00002C4.0118 21.6 2.40002 19.9882 2.40002 18L2.40003 5.9999C2.40003 4.01168 4.0118 2.3999 6.00003 2.3999L18 2.3999Z"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )}
    </div>
  );
};

export default Checkbox;
