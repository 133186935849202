import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import classNames from 'classnames';
import { FileEyes, Layer } from 'assets/svg-component';
import { toTitleCase } from 'utils/string';
import ActionIcon from 'components/base/ActionIcon';
import { useNavigate } from 'react-router-dom';
import { useSdk } from 'core/logic';
import { useStakeNFTs } from 'context/StakeNFTs';
import { checkStatus2 } from 'utils/contract-utils';
import { formatNumber, formatNumberCoin } from 'helpers/number';
import { CampaignService } from 'interface/campaign/campaign_service';
import moment from 'moment';
interface ListCampaignProps {
  data: CampaignService[] | undefined;
  filterStatus?: string;
  filterSorting?: string;
  search?: string;
}

const ListCampaign: React.FC<ListCampaignProps> = ({
  data = [],
  filterStatus = 'all',
  filterSorting = '',
  search = ''
}) => {
  const { openStakeNFTs } = useStakeNFTs();

  const navigate = useNavigate();

  // const [activeIndex, setActiveIndex] = useState(0);
  const [dataList, setDataList] = useState(data || []);
  const { initialized } = useSdk();

  useEffect(() => {
    let dt = [...data];

    dt = dt?.map((item, index) => {
      return { ...item, status: checkStatus2(item?.start, item?.end, item) };
    });

    if (data?.length > 0) {
      if (filterStatus?.toLowerCase() === 'all') {
        // dt = dt;
      } else {
        dt = dt?.filter((item: any, index) => {
          return item?.status?.toLowerCase() === filterStatus?.toLowerCase();
        });
      }

      //sorting

      if (filterSorting === 'most-staked') {
        dt = dt?.sort((a, b) => {
          return b?.total_staked - a?.total_staked;
        });
      } else if (filterSorting === 'high-to-low') {
        dt = dt?.sort((a, b) => {
          return b?.reward_per_second - a?.reward_per_second;
        });
      } else if (filterSorting === 'recently-added') {
        dt = dt?.sort((a, b) => {
          return b?.id - a?.id;
        });
      } else if (filterSorting === 'ending-soon') {
        dt = dt?.sort((a, b) => {
          return moment(a?.end).diff(b?.end);
        });
      } else {
        dt = dt?.sort((a, b) => {
          return a?.name?.localeCompare(b?.name);
        });
      }
    }

    setDataList(dt);
  }, [data, filterStatus, filterSorting]);

  return (
    <div className={styles['list-campaign']}>
      <table className={styles['table']}>
        <thead>
          <tr>
            <th>Campaigns</th>
            <th>Total staked</th>
            <th>Daily rewards</th>
            <th>Status</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {dataList?.map((item: CampaignService, index) => {
            const status = checkStatus2(item?.start, item?.end, item);
            const isActive = status.toLowerCase() === 'active';
            return (
              <tr key={index}>
                <td>
                  <div className={styles['infor-campaign']}>
                    <img
                      src={item?.image}
                      alt="campaign-demo"
                      className={styles['logo']}
                    />
                    <div className={styles['information']}>
                      <label className={styles['name-campaign']}>
                        {item?.name}
                      </label>
                      <label className={styles['name-collection']}>
                        {item?.collection_name}
                      </label>
                    </div>
                  </div>
                </td>
                <td>
                  {(
                    (item?.total_staked / item?.collection_minted) *
                    100
                  ).toFixed(2)}{' '}
                  %
                </td>
                <td>
                  {formatNumberCoin(
                    item?.reward_per_second * 86400,
                    item?.token_decimal
                  )}{' '}
                  {item?.token_symbol}
                </td>
                <td>
                  <div className={classNames('status', status.toLowerCase())}>
                    <div className={classNames('circle')}></div>
                    {toTitleCase(status)}
                  </div>
                </td>
                <td>
                  <div className={classNames(styles['interaction'])}>
                    <ActionIcon
                      icon={<Layer />}
                      disable={!initialized || !isActive}
                      tooltip={
                        initialized
                          ? isActive
                            ? 'Stake NFTs'
                            : 'Campaign is not active'
                          : 'Connect wallet to stake NFTs'
                      }
                      onClick={() => {
                        if (item?.collection_address && item?.address) {
                          openStakeNFTs(item);
                        }
                      }}
                    />
                    <ActionIcon
                      icon={<FileEyes />}
                      tooltip="View campaign detail"
                      onClick={() => {
                        navigate(`/detail-campaign/${item?.address || ''}`);
                      }}
                    />
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      <div className={styles['list-mobile']}>
        {dataList?.map((item: CampaignService, index) => {
          const status = checkStatus2(item?.start, item?.end, item);
          const isActive = status.toLowerCase() === 'active';
          return (
            <div className={styles['list-mobile__item']} key={index}>
              <img src={item?.image} className={styles['logo']} />
              <div className={styles['infor']}>
                <label className={styles['campaign-name']}>{item?.name}</label>
                <label className={styles['collection-name']}>
                  {item?.description}
                </label>
                <div className={styles['row-infor']}>
                  <label className={styles['row-infor__title']}>
                    Total staked
                  </label>
                  <label className={styles['row-infor__value']}>
                    {(
                      (item?.total_staked / item?.collection_minted) *
                      100
                    ).toFixed(2)}{' '}
                    %
                  </label>
                </div>
                <div className={styles['row-infor']}>
                  <label className={styles['row-infor__title']}>
                    Daily rewards
                  </label>
                  <label className={styles['row-infor__value']}>
                    {formatNumberCoin(
                      Number(item?.reward_per_second) * 86400,
                      item?.token_decimal
                    )}{' '}
                    {item?.token_symbol}
                  </label>
                </div>
                <div className={styles['footer']}>
                  <div className={classNames('status', status.toLowerCase())}>
                    <div className={classNames('circle')}></div>
                    {toTitleCase(status)}
                  </div>
                  <div className={classNames(styles['interaction'])}>
                    <ActionIcon
                      icon={<Layer />}
                      disable={!initialized || !isActive}
                      tooltip={
                        initialized
                          ? isActive
                            ? 'Stake NFTs'
                            : 'Campaign is not active'
                          : 'Connect wallet to stake NFTs'
                      }
                      onClick={() => {
                        if (item?.collection_address && item?.address) {
                          openStakeNFTs(item);
                        }
                      }}
                    />
                    <ActionIcon
                      icon={<FileEyes />}
                      tooltip="View campaign detail"
                      onClick={() => {
                        navigate(`/detail-campaign/${item?.address || ''}`);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ListCampaign;
