// import stakingIcon from './icon-sidebar/staking.svg';
import campaignIcon from './icon-sidebar/add-campaign.svg';
// import coinBreakIcon from './icon-sidebar/coin-break.svg';
// import yourCreatedCampagin from './icon-sidebar/your-created-campaign.svg';
import publicCampaign from './icon-sidebar/public-campaign.svg';
import createdCampaign from './icon-sidebar/star.svg';
import fileQuestionIcon from './icon-sidebar/file-question.svg';
import { useStoreModal } from 'context/StoreModal';

export enum ScopeEnum {
    Includes = 'includes', // path can include this route
    Strict = 'strict', //path must strict equal this route
}


export interface ActiveRoutesObjectProps {
    route: string;
    scope?: ScopeEnum | undefined,
}


export interface itemProp {
    label?: string,
    icon?: string,
    to?: string,
    active?: (string | ActiveRoutesObjectProps)[],
}



const data = [
    {
        label: 'Public campaign',
        label_mobile: 'Public',
        icon: publicCampaign,
        to: '/public-campaign',
        active: ['/public-campaign', '/', {
            route: 'detail-campaign',
            scope: 'includes',
        }]
    },
    {
        label: 'My created campaign',
        label_mobile: 'Created',
        icon: createdCampaign,
        to: '/created-campaign',
        active: ['/created-campaign',
            {
                route: 'campaign-created-detail',
                scope: 'includes',
            }]
    },
    {
        label: 'Add campaign',
        label_mobile: 'Add',
        icon: campaignIcon,
        to: 'https://forms.gle/GritYiR3pQe4sfqVA',
        // active: ['/add-campaign']
        active: '_blank',
    },
    {
        className: 'howto',
        label_mobile: 'How to',
        icon: fileQuestionIcon,
        to: 'https://stakify-2.gitbook.io/user-guide-for-stakify/',
        active: ['/none'],
        onClick: () => { }
    }
]

export default data;