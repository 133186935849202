import * as React from 'react';
const SvgColorSwatch = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16px"
    height="16px"
    fill="none"
    viewBox="0 0 60 60"
    role={'icon-default'}
    {...props}
  >
    <path
      stroke={'currentColor'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M15 24.686C8.916 26.597 4.5 32.283 4.5 38.999c0 8.284 6.716 15 15 15 4.197 0 7.99-1.723 10.713-4.5A14.951 14.951 0 0 0 34.5 39M45 24.686c6.086 1.91 10.5 7.597 10.5 14.313 0 8.285-6.715 15-15 15a14.934 14.934 0 0 1-9-3M45 21c0 8.284-6.716 15-15 15-8.285 0-15-6.716-15-15 0-8.284 6.716-15 15-15 8.284 0 15 6.716 15 15Z"
    />
  </svg>
);
export default SvgColorSwatch;
