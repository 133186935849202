import React, { useEffect } from 'react'
import Chart from './Chart'
import styles from './styles.module.scss';
import Tabs from 'components/common/Tabs';
import classNames from 'classnames';
import BoxInfor from './BoxInfor';
import TableStakingHistory from 'components/common/TableStakingHistory';
import { CampaignService } from 'interface/campaign/campaign_service';
import { CampaignAnalysis } from 'interface/campaign/campaign_analysis';
import { formatNumberCoin } from 'helpers/number';

interface ChartStakingAnalysisProps {
  campaignDetail: CampaignService | undefined,
  analysis: CampaignAnalysis[] | undefined,
}

const ChartStakingAnalysis: React.FC<ChartStakingAnalysisProps> = ({ analysis, campaignDetail }) => {
  const [indexTabsTerm, setIndexTabsTerm] = React.useState<number>(0);
  const [lockupTerm, setLockupTerm] = React.useState<any>([]);

  useEffect(() => {
    setLockupTerm(() => {
      let data = campaignDetail?.staking_term_available
        ? JSON.parse(campaignDetail?.staking_term_available)
        : []
      return data?.map((item: any, index: number) => {
        return `${(item?.value / 86400).toFixed(0)} days`;
      })
    })
  }, [campaignDetail])

  // useEffect(() => {
  //   if (analysis) {
  //     console.log({ indexTabsTerm: analysis[indexTabsTerm], analysis })
  //   }
  // }, [indexTabsTerm])

  return (
    <div>
      <h4 className={classNames('sub-title', styles['title'])}>Staking analysis</h4>
      <div className={styles['lockup-term-note']}>
        <Tabs tabs={
          lockupTerm
        } indexActive={indexTabsTerm} setIndexActive={setIndexTabsTerm} />
        <div
          className={classNames(
            'status',
          )}
        >
          <div className={'circle'} style={{ backgroundColor: '#7F6AFF' }}></div>
          Staked NFTs in term
        </div>
      </div>
      <div className={styles['chart']}>
        {analysis && <Chart data={analysis[indexTabsTerm]?.chart} />}
      </div>
      <div className={styles['box-infor-frame']}>
        {
          analysis && <>
            <BoxInfor title={`Rewards: $${campaignDetail?.token_symbol}`} list={
              [
                {
                  title: 'Term allocation',
                  value: analysis[indexTabsTerm]?.percent + '%',
                },
                {
                  title: 'Term reward',
                  value: `${formatNumberCoin(analysis[indexTabsTerm]?.term_reward, campaignDetail?.token_decimal)} ${campaignDetail?.token_symbol}`,
                },
                {
                  title: 'Daily rewards/user',
                  value: `${formatNumberCoin(analysis[indexTabsTerm]?.value * 86400, campaignDetail?.token_decimal)} ${campaignDetail?.token_symbol}`,
                }
              ]
            } />
            <BoxInfor title="Acitivity" list={
              [
                {
                  title: 'NFTs staked in this term',
                  value: `${analysis[indexTabsTerm]?.nft_staked} NFTs (${analysis[indexTabsTerm]?.nft_staked_percent || 0}%)`,
                },
                {
                  title: 'Changes in 24h',
                  value: `${analysis[indexTabsTerm]?.nft_changed_24h} NFTs`,
                },
                {
                  title: 'Reward claimed',
                  value: `${formatNumberCoin(analysis[indexTabsTerm]?.reward_claimed, campaignDetail?.token_decimal)} ${campaignDetail?.token_symbol} (${Number(analysis[indexTabsTerm]?.reward_claimed_percent).toFixed(2)}% total reward)`,
                }
              ]
            } />
            <BoxInfor title="Stakers" list={
              [
                {
                  title: 'Total stakers',
                  value: `${analysis[indexTabsTerm]?.stakers}`,
                },
                {
                  title: 'Changes in 24h',
                  value: `${analysis[indexTabsTerm]?.stakers_changed_24h}`,
                },
                {
                  title: 'Average rewards/staker',
                  value: `${formatNumberCoin(analysis[indexTabsTerm]?.reward_per_stakers, campaignDetail?.token_decimal)} ${campaignDetail?.token_symbol}`,
                }
              ]
            } />
          </>
        }
      </div>
      <TableStakingHistory />
    </div>
  )
}

export default ChartStakingAnalysis