import ModalBase from 'components/base/ModalBase';
import styles from './styles.module.scss';
import { useStoreModal } from 'context/StoreModal'
import React from 'react'
import ButtonGradient from 'components/base/ButtonGradient';
import { loadKeplrWallet, useSdk } from 'core/logic';
import { config } from 'config';
import { deleteLocalStorage, setLocalStorage } from 'helpers/localStorage';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { selectChooseWallet } from 'store/selectors';
import { getFirstConnect, postFirstConnect } from 'api/firstconnect.api';
import { Player } from '@lottiefiles/react-lottie-player';
import { getKeplr } from 'core/logic/utils/connect';
import { appSetting } from 'constants/configuration';

const SwitchAccountModal = () => {
    const { isOpen, setIsOpen } = useStoreModal("switch-account");
    const { isOpen: isOpenTos, setIsOpen: setIsOpenTos } = useStoreModal('tos-policy');
    const { init, setInitializing } = useSdk();
    const chooseWallet = useSelector(selectChooseWallet);
    return (
        <ModalBase
            open={isOpen}
            onClose={() => {
                setIsOpen(false);
            }}>
            <div className={styles['body']}>
                <div className={styles['content']}>
                    <h3 className={styles['title']}>Switch Account</h3>
                    {/* <label className={styles['description']}>Welcome to Stakify!</label> */}
                    {/* <img src="/christmas-comingsoon.svg" className={styles['christmas-mobile']} />
                     */}
                    <Player
                        src={'/lotties/mascot-phone.json'}
                        className={styles['christmas-mobile']}
                        loop
                        autoplay
                    />
                    <label className={styles['des-2']}>It seems you switched to a different Keplr account, click "Switch account" to connect with the new address!</label>
                    <div className={styles['btn-frame']}>
                        <ButtonGradient onClick={
                            async () => {
                                setIsOpen(false);
                                try {
                                    const keplr = await getKeplr();
                                    if (keplr) {
                                        const signer = await loadKeplrWallet(config.chainId);
                                        setInitializing(false);
                                        signer.getAccounts().then((data) => {
                                            const addr = data[0]?.address;
                                            const currentTimestamp = new Date().getTime();
                                            keplr.signArbitrary(config.chainId, addr,
                                                `Welcome to Stakify!

This message is only to authenticate yourself. Please sign to proceed with using Stakify.
Signing this message will not trigger a blockchain transaction or cost any gas fees.
Wallet address:
${addr}
Timestamp:
${currentTimestamp}

Network: ${appSetting.chain}`).then(async (data2: any) => {

                                                    await getFirstConnect({ wallet: addr }).then((dt) => {
                                                        if (dt?.data && data[0]) {
                                                            setIsOpenTos(true);
                                                            deleteLocalStorage('LAST_USED_PROVIDER');
                                                        } else {
                                                            init(signer);
                                                            setInitializing(false);
                                                            postFirstConnect({
                                                                wallet: addr
                                                            })
                                                            setLocalStorage('LAST_USED_PROVIDER', { provider: chooseWallet, timestamp: moment().toDate().getTime(), address: addr });

                                                        }
                                                    }).catch((err) => {
                                                        console.log(err);
                                                    })
                                                }).catch((err: any) => {
                                                    console.log(err)
                                                })
                                        })
                                    }
                                } catch (error) {
                                    console.error(error);
                                }


                            }
                        }>Switch account</ButtonGradient>
                        <ButtonGradient onClick={
                            () => { setIsOpen(false) }
                        }>
                            Cancel
                        </ButtonGradient>
                    </div>
                </div>
                <Player
                    src={'/lotties/mascot-phone.json'}
                    className={styles['christmas']}
                    loop
                    autoplay
                />
                {/* <img src="/christmas-comingsoon.svg" className={styles['christmas']} /> */}
            </div>
        </ModalBase >
    )
}

export default SwitchAccountModal