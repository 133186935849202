import TabsDash from 'components/common/TabsDash'
import ListCampaign from 'components/pages/public-campaign/ListCampaign';
import { useSdk } from 'core/logic';
import { CampaignService } from 'interface/campaign/campaign_service';
import React, { useEffect, useState } from 'react'
import { getCampaignService, getCampaignServiceOwner } from 'api/campaign/campaign_service.api';
import ListStakedCampaign from 'components/pages/public-campaign/ListStakedCampaign';
import styles from './styles.module.scss';
import TableCampaign from 'components/pages/created-campaign/TableCampaign';
import Tabs from 'components/common/Tabs';
import { useLocation } from 'react-router-dom';
import { useSearch } from 'context/SearchContext';
import MascotNotice from 'components/common/MascotNotice';
import { useCampaignService } from 'context/CampaignService';
const Fade = require('react-reveal/Fade');

interface SearchPageProps {

}

const SearchPage: React.FC<SearchPageProps> = () => {
    const [indexTabDashActive, setIndexTabDashActive] = useState<number>(0);
    const [dataTabPublicCampaign, setDataTabPublicCampaign] = useState<string[]>([
        'Public campaign'
    ]);
    const [indexActiveTab, setIndexActiveTab] = useState<number>(0);
    const { initialized, address } = useSdk();
    const { valueSearch, setValueSearch } = useSearch();
    const [dataSearch, setDataSearch] = useState([[], [], []]);

    const { publicCampaign, stakedCampaign, createdCampaign } = useCampaignService();

    const statuses = [
        'All',
        'Active',
        'Upcoming',
        'Ended',
    ]
    const location = useLocation();
    useEffect(() => {
        // if (!valueSearch) {
        setValueSearch(decodeURI(location?.search?.replace("?name=", '')));
        // }
    }, [location])
    useEffect(() => {
        if (initialized) {
            setDataTabPublicCampaign(['Public campaign', 'Staked campaign', 'Created campaign']);
        } else {
            setDataTabPublicCampaign(['Public campaign']);
            setIndexTabDashActive(0);
        }
    }, [initialized]);

    useEffect(() => {
        setDataSearch(() => {
            let arr: any = [];
            arr[0] = publicCampaign?.filter((item) => { return item?.name?.toLowerCase()?.includes(valueSearch.toLowerCase()) });
            arr[1] = stakedCampaign?.filter((item) => { return item?.name?.toLowerCase()?.includes(valueSearch.toLowerCase()) });
            arr[2] = createdCampaign?.campaigns?.filter((item) => { return item?.name?.toLowerCase()?.includes(valueSearch.toLowerCase()) })
            return arr;
        })
    }, [valueSearch])
    return (
        <div>
            {
                valueSearch ? <>
                    <h3 className={styles['search-title']}>Search result for “{valueSearch}”</h3>
                    <TabsDash data={
                        dataTabPublicCampaign
                    } indexActive={indexTabDashActive} setIndexActive={setIndexTabDashActive} />
                    <div className={styles['tabs']}>
                        {
                            // ((indexActiveTab == 0 && dataSearch[0]?.length > 0)
                            //     || (indexActiveTab == 1 && dataSearch[1]?.length > 0)
                            //     || (indexActiveTab == 2 && dataSearch[2]?.length > 0))
                            // && 
                            <Tabs tabs={statuses} indexActive={indexActiveTab} setIndexActive={setIndexActiveTab} />
                        }
                    </div>
                    <Fade>
                        {
                            indexTabDashActive === 0 ? <div>
                                {
                                    dataSearch[0]?.length > 0 ? <ListCampaign
                                        data={dataSearch[0]}
                                        filterStatus={statuses[indexActiveTab]}
                                    /> : <MascotNotice img='/lotties/mascot-thinking.json' text={`No campaigns matching “${valueSearch}”.`} />
                                }
                            </div> : indexTabDashActive === 1 ? <div>
                                {
                                    dataSearch[1]?.length > 0 ? <ListStakedCampaign
                                        data={dataSearch[1]}
                                        indexFilterStatus={statuses[indexActiveTab]}
                                    /> : <MascotNotice img='/lotties/mascot-thinking.json' text={`No campaigns matching “${valueSearch}”.`} />
                                }
                            </div> : <div>
                                {
                                    dataSearch[2]?.length > 0 ? <TableCampaign data={
                                        dataSearch[2]
                                    } filterStatus={statuses[indexActiveTab]} /> : <MascotNotice img='/lotties/mascot-thinking.json' text={`No campaigns matching “${valueSearch}”.`} />
                                }
                            </div>
                        }
                    </Fade>
                </> : <MascotNotice img='/lotties/mascot-thinking.json' text='No result.' />
            }

        </div>
    )
}

export default SearchPage