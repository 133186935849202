import UnstakeAllModal from 'components/modal/UnstakeAllModal';
import { useStoreModal } from 'context/StoreModal';
import { CampaignService } from 'interface/campaign/campaign_service';
import { createContext, useState } from 'react';

export enum StatusClaim {
  Success = 'success',
  Fail = 'fail',
  Cancel = 'cancel',
  NotYet = 'not yet',
  Pending = 'pending'
}

export type IUnstakeAllContext = {
  isOpen: boolean;
  open: (listUnstake: any[]) => void;
  listUnstake: any[];
  statusClaim: StatusClaim;
  setStatusClaim: (value: StatusClaim) => void;
};

const initValues = {
  isOpen: false,
  open: (listUnstake: any[]) => {},
  listUnstake: [],
  statusClaim: StatusClaim.NotYet,
  setStatusClaim: () => {}
};

export const UnstakeAllContext = createContext<IUnstakeAllContext>(initValues);

const UnstakeAllProvider: React.FC<{ children: React.ReactNode }> = ({
  children
}) => {
  const { isOpen, setIsOpen } = useStoreModal('unstake-all-modal');
  const [listUnstake, setListUnstake] = useState<any[]>([]);
  const [statusClaim, setStatusClaim] = useState<StatusClaim>(
    StatusClaim.NotYet
  );
  function open(listUnstake: any[]) {
    setListUnstake(listUnstake);
    setIsOpen(true);
  }
  return (
    <UnstakeAllContext.Provider
      value={{
        open,
        listUnstake,
        isOpen,
        statusClaim,
        setStatusClaim
      }}
    >
      {children}
      <UnstakeAllModal />
    </UnstakeAllContext.Provider>
  );
};

export default UnstakeAllProvider;
