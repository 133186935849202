import React from 'react';
import styles from './styles.module.scss';
import classNames from 'classnames';
import { Player } from '@lottiefiles/react-lottie-player';

interface MascotNoticeProps {
  text: string | JSX.Element;
  img: string;
  className?: string;
  children?: React.ReactNode;
}

const MascotNotice: React.FC<MascotNoticeProps> = ({
  text = '',
  img = '',
  className = '',
  children
}) => {
  return (
    <div className={classNames(styles['mascot-notice'], className)}>
      <div className={styles['aura']} role={'aura-mn' || undefined}></div>
      <label className={styles['notice']} role={'notice-mn' || undefined}>
        {text}
      </label>
      {/* <img
        src={img}
        alt="mascot"
        className={styles['mascot']}
        role={'mascot-mn' || undefined}
      /> */}
      <Player
        src={img}
        className={styles['mascot']}
        loop
        autoplay
      />
      {children}
    </div>
  );
};

export default MascotNotice;
