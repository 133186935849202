import React from 'react'
import styles from './styles.module.scss'
import { useStoreModal } from 'context/StoreModal'
import ModalBase from 'components/base/ModalBase';
import ButtonGradient from 'components/base/ButtonGradient';

const ComingSoon = () => {
    const { isOpen, setIsOpen } = useStoreModal('coming-soon');
    return (
        <ModalBase
            open={isOpen}
            onClose={() => {
                setIsOpen(false);
            }}>
            <div className={styles['body']}>
                <div className={styles['content']}>
                    <h3 className={styles['title']}>Coming soon</h3>
                    <label className={styles['description']}>Stakify.zone will launch soon on Aura Mainnet. Stay tune!</label>
                    <img src="/christmas-comingsoon.svg" className={styles['christmas-mobile']} />
                    <label className={styles['des-2']}>Don’t want to miss our launch? Get updates from Aura Boy Golf Club Twitter!</label>
                    <ButtonGradient onClick={
                        () => { setIsOpen(false) }
                    }>Stay Updated</ButtonGradient>
                </div>
                <img src="/christmas-comingsoon.svg" className={styles['christmas']} />
            </div>
        </ModalBase >
    )
}

export default ComingSoon