import type { FC } from 'react';

import { Suspense, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Outlet } from 'react-router';

// import TagsView from './tagView';
// import { getFirstPathCode } from 'utils/getFirstPathCode';
import { userActions } from 'store/user.store';

import { getGlobalState } from 'utils/getGloabal';
import Sidebar from './SideBar';
import { Header } from 'components';
// import { useStoreModal } from 'context/StoreModal';
// import { loadKeplrWallet, useSdk } from 'core/logic';
// import { getFirstConnect } from 'api/firstconnect.api';
// import { config } from 'config';

// const { Sider, Content } = Layout;
const WIDTH = 992;

const LayoutPage: FC = () => {
  // const location = useLocation();
  // const [openKey, setOpenkey] = useState<string>();
  // const [selectedKey, setSelectedKey] = useState<string>(location.pathname);
  // const { collapsed } = useSelector((state) => state.user);
  // const token = antTheme.useToken();

  // const isMobile = device === 'MOBILE';
  const dispatch = useDispatch();
  // const { setIsOpen } = useStoreModal("tos-policy");
  // const { address } = useSdk();
  // useEffect(() => {
  //   // const code = getFirstPathCode(location.pathname);
  //   // setOpenkey(code);
  //   // setSelectedKey(location.pathname);
  // }, [location.pathname]);

  // const toggle = () => {
  //   dispatch(
  //     userActions.setUserItem({
  //       collapsed: !collapsed
  //     })
  //   );
  // };
  useEffect(() => {
    window.onresize = () => {
      const { device } = getGlobalState();
      const rect = document.body.getBoundingClientRect();
      const needCollapse = rect.width < WIDTH;

      dispatch(
        userActions.setUserItem({
          device,
          collapsed: needCollapse
        })
      );
    };
  }, [dispatch]);

  // useEffect(() => {
  //   (async function checkFirstConnect(): Promise<void> {
  //     try {
  //       const signer = await loadKeplrWallet(config.chainId);
  //       signer.getAccounts().then((data) => {
  //         getFirstConnect({ wallet: data[0]?.address }).then((dt) => {
  //           if (dt?.data && data[0]) {
  //             setIsOpen(true);
  //           }
  //         })
  //       })

  //     } catch (error: any) {

  //     }
  //   })();

  // }, [address])

  return (
    <div className="layout-main">
      <Sidebar />
      <div className="outlet-main">
        <div className="container-outlet">
          <Header />
          <Suspense fallback={null}>
            <Outlet />
          </Suspense>
          {/* <Footer /> */}
        </div>
      </div>
    </div>
  );
};

export default LayoutPage;

// return (
//   <Layout className="layout-page">
//     {/* <HeaderComponent collapsed={collapsed} toggle={toggle} /> */}
//     <Layout>
//       {/* {!isMobile ? (
//         <Sider
//           className="layout-page-sider"
//           trigger={null}
//           collapsible
//           style={{ backgroundColor: token.token.colorBgContainer }}
//           collapsedWidth={isMobile ? 0 : 80}
//           collapsed={collapsed}
//           breakpoint="md"
//         >

//         </Sider>
//       ) : (
//         <Drawer
//           width="200"
//           placement="left"
//           bodyStyle={{ padding: 0, height: '100%' }}
//           closable={false}
//           onClose={toggle}
//           open={!collapsed}
//         >

//         </Drawer>
//       )} */}
//       <Content className="layout-page-content">
//         {/* <TagsView /> */}
//         <Suspense fallback={null}>
//           <Outlet />
//         </Suspense>
//       </Content>
//     </Layout>
//   </Layout>
// );
