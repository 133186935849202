import * as React from 'react';
const SvgCheck = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16px"
    height="16px"
    fill="none"
    viewBox="0 0 24 24"
    role={'icon-default'}
    {...props}
  >
    <path
      stroke={'currentColor'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="m16.8 8.4-7.16 7.2-2.44-2.454"
    />
  </svg>
);
export default SvgCheck;
