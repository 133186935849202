import * as React from 'react';
const SvgLogout = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16px"
    height="16px"
    fill="none"
    viewBox="0 0 16 17"
    role={'icon-default'}
    {...props}
  >
    <path
      stroke={'currentColor'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M5.853 2.9h-2.47c-.375 0-.734.147-.998.41a1.394 1.394 0 0 0-.414.99v8.4c0 .371.149.727.414.99.264.262.623.41.998.41h2.47m.176-5.6h8m0 0-3.057-3.2m3.057 3.2-3.057 3.2"
    />
  </svg>
);
export default SvgLogout;
