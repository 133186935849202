import { CosmWasmClient, SigningCosmWasmClient } from "@cosmjs/cosmwasm-stargate";
import { config } from "config";
import { createClient, createClientPublic, useError, useSdk } from "core/logic";
import { Campaign, CampaignInstance } from "core/logic/service/campaign";
import { useEffect, useState } from "react";

export default function useContractCampaign(address: string) {
  // const [data, setData] = useState(undefined);
  const [loaded, setLoaded] = useState(false);
  const { setError } = useError();
  const [campaignInstance, setCampaignInstance] = useState<CampaignInstance>();
  const { initialized, getSigner } = useSdk();

  useEffect(() => {
    (async function updateClient(): Promise<void> {
      try {
        let client: SigningCosmWasmClient | CosmWasmClient;
        if (initialized) {
          client = await createClient(config, getSigner());
        } else {
          client = await createClientPublic(config);
        }
        const campaign = Campaign(client);
        const campaignInstance = campaign.use(address);
        setCampaignInstance(campaignInstance);
        setLoaded(true)
      } catch (error: any) {
        setError(error.message);
      }
    })();
  }, [address, initialized, setError, getSigner])

  return { campaignInstance, loaded };
}