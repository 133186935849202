import {
  CosmWasmClient,
  SigningCosmWasmClient
} from '@cosmjs/cosmwasm-stargate';
import { config } from 'config';
import {
  CW20,
  CW20Contract,
  CW20Instance,
  createClient,
  createClientPublic,
  useError,
  useSdk
} from 'core/logic';
import {
  Factory,
  FactoryContract,
  FactoryInstance
} from 'core/logic/service/factory';
import React, { useEffect, useState } from 'react';
import { Campaign, CampaignContract, CampaignInstance } from './campaign';
import { CW721, Cw721Contract, Cw721Instance } from './cw721';

interface ClientContextType {
  readonly factoryContract: FactoryContract | undefined;
  readonly campaignContract: CampaignContract | undefined;
  readonly cw20Contract: CW20Contract | undefined;
  readonly cw721Contract: Cw721Contract | undefined;
  readonly factory: FactoryInstance | undefined;
  readonly campaign: CampaignInstance | undefined;
  readonly cw20: CW20Instance | undefined;
  readonly cw721: Cw721Instance | undefined;
  readonly createFactory: (value: string) => void;
  readonly createCampaign: (value: string) => void;
  readonly createCw20: (value: string) => void;
  readonly createCw721: (value: string) => void;
  readonly campaignClientAddress: string | undefined;
  readonly cw20ClientAddress: string | undefined;
  readonly cw721ClientAddress: string | undefined;
  readonly client: SigningCosmWasmClient | CosmWasmClient | undefined;
}

function throwNotInitialized(): any {
  throw new Error('Not yet initialized');
}

const defaultContext: ClientContextType = {
  factoryContract: undefined,
  campaignContract: undefined,
  cw20Contract: undefined,
  cw721Contract: undefined,
  factory: undefined,
  campaign: undefined,
  cw20: undefined,
  cw721: undefined,
  createFactory: throwNotInitialized,
  createCampaign: throwNotInitialized,
  createCw20: throwNotInitialized,
  createCw721: throwNotInitialized,
  campaignClientAddress: undefined,
  cw20ClientAddress: undefined,
  cw721ClientAddress: undefined,
  client: undefined,
};

const ClientContext = React.createContext<ClientContextType>(defaultContext);

export const useClient = (): ClientContextType =>
  React.useContext(ClientContext);

interface ClientProviderProps extends React.HTMLAttributes<HTMLOrSVGElement> { }

export function ClientProvider({ children }: ClientProviderProps): JSX.Element {
  const { setError } = useError();
  const { initialized, getSigner } = useSdk();

  const [factory, setFactory] = useState<FactoryInstance>();
  const [campaign, setCampaign] = useState<any>();
  const [cw20, setCw20] = useState<CW20Instance>();
  const [cw721, setCw721] = useState<Cw721Instance>();

  const [campaignClientAddress, setCampaignClientAddress] = useState<string | undefined>(undefined);
  const [cw20ClientAddress, setCw20ClientAddress] = useState<string | undefined>(undefined);
  const [cw721ClientAddress, setCw721ClientAddress] = useState<string | undefined>(undefined);

  // const [value, setValue] = useState<ClientContextType>({
  //   ...defaultContext,
  //   factory,
  //   campaign,
  //   cw20,
  //   cw721,
  //   createFactory,
  //   createCampaign,
  //   createCw20,
  //   createCw721
  // });
  const [client, setClient] = useState<
    SigningCosmWasmClient | CosmWasmClient
  >();
  const [factoryContractClient, setFactoryContractClient] = useState<
    FactoryContract | undefined
  >(undefined);
  const [cw721ContractClient, setCw721ContractClient] = useState<
    Cw721Contract | undefined
  >(undefined);
  const [cw20ContractClient, setCw20ContractClient] = useState<
    CW20Contract | undefined
  >(undefined);
  const [campaignContractClient, setCampaignContractClient] = useState<
    CampaignContract | undefined
  >(undefined);

  function createFactory(address: string): void {
    if (factoryContractClient) setFactory(factoryContractClient.use(address));
  }
  function createCampaign(address: string): void {
    if (campaignContractClient) {
      setCampaign(campaignContractClient.use(address));
    }
  }

  function createCw20(address: string): void {
    if (cw20ContractClient) setCw20(cw20ContractClient.use(address));
  }
  function createCw721(address: string): void {
    if (cw721ContractClient) setCw721(cw721ContractClient.use(address));
  }

  useEffect(() => {
    (async function updateClient(): Promise<void> {
      try {
        let clientLocal: SigningCosmWasmClient | CosmWasmClient;
        if (initialized) {
          clientLocal = await createClient(config, getSigner());
        } else {
          clientLocal = await createClientPublic(config);
        }
        setClient(clientLocal);
        setFactoryContractClient(Factory(clientLocal));
        setCw20ContractClient(CW20(clientLocal));
        setCw721ContractClient(CW721(clientLocal));
        setCampaignContractClient(Campaign(clientLocal));
      } catch (error: any) {
        setError(error.message);
      }
    })();
  }, [initialized, getSigner, setError]);

  useEffect(() => {
    setCampaignClientAddress(campaign?.contractAddress);
  }, [campaign])

  useEffect(() => {
    setCw20ClientAddress(cw20?.contractAddress);
  }, [cw20])

  useEffect(() => {
    setCw721ClientAddress(cw721?.contractAddress);
  }, [cw721])

  // const a = useMemo(
  //   () => ({
  //     factoryContract: factoryContractClient,
  //     campaignContract: campaignContractClient,
  //     cw20Contract: cw20ContractClient,
  //     cw721Contract: cw721ContractClient,
  //     factory,
  //     campaign,
  //     cw20,
  //     cw721,
  //     createFactory: createFactory,
  //     createCampaign: createCampaign,
  //     createCw20: createCw20,
  //     createCw721: createCw721
  //   }),
  //   [
  //     client,
  //     factory,
  //     cw721,
  //     campaign,
  //     factoryContractClient,
  //     cw20ContractClient,
  //     cw721ContractClient,
  //     campaignContractClient
  //   ]
  // );

  // useEffect(() => {
  //   // if (client) {
  //   setValue({
  //     factoryContract: factoryContractClient,
  //     campaignContract: campaignContractClient,
  //     cw20Contract: cw20ContractClient,
  //     cw721Contract: cw721ContractClient,
  //     factory,
  //     campaign,
  //     cw20,
  //     cw721,
  //     createFactory: createFactory,
  //     createCampaign: createCampaign,
  //     createCw20: createCw20,
  //     createCw721: createCw721
  //   });

  //   // }
  // }, [
  //   client,
  //   factory,
  //   cw721,
  //   campaign,
  //   factoryContractClient,
  //   cw20ContractClient,
  //   cw721ContractClient,
  //   campaignContractClient
  // ]);

  // client, initialized, getSigner, setError, factoryContractClient, factory, cw721, campaign
  return (
    <ClientContext.Provider
      value={{
        factoryContract: factoryContractClient,
        campaignContract: campaignContractClient,
        cw20Contract: cw20ContractClient,
        cw721Contract: cw721ContractClient,
        factory,
        campaign,
        cw20,
        cw721,
        createFactory: createFactory,
        createCampaign: createCampaign,
        createCw20: createCw20,
        createCw721: createCw721,
        campaignClientAddress,
        cw20ClientAddress,
        cw721ClientAddress,
        client
      }}
    >
      {children}
    </ClientContext.Provider>
  );
}
