import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { Gift } from 'assets/svg-component';
import ButtonGradient from 'components/base/ButtonGradient';
import { useSdk } from 'core/logic';

import { formatNumberCoin } from 'helpers/number';

import { useClaimReward } from 'context/ClaimReward';
import { useClient } from 'core/logic/service/public';
import { useAddReward } from 'context/AddReward';
import { useCampaignService } from 'context/CampaignService';
import {
  checkStatus2,
  handleMsgErr,
  useCreateCw20
} from 'utils/contract-utils';
import { CampaignService } from 'interface/campaign/campaign_service';
import { toast } from 'react-toastify';
import { Tooltip } from 'antd';

interface YourRewardsProps {
  campaignDetail: CampaignService | undefined;
  nfts: any[] | undefined;
  is_po?: boolean;
}

const YourRewards: React.FC<YourRewardsProps> = ({
  campaignDetail,
  nfts = [],
  is_po = false
}) => {
  const { address, initialized } = useSdk();
  const [loadingClaimButton, setLoadingClaimButton] =
    React.useState<boolean>(false);
  const [loadingWithdraw, setLoadingWithdraw] = React.useState<boolean>(false);
  const [pendingReward, setPendingReward] = React.useState<string>('0');
  const [totalPendingReward, setTotalPendingReward] =
    React.useState<string>('0');
  const [claimedReward, setClaimedReward] = React.useState<string>('0');
  const [balanceReward, setBalanceReward] = React.useState<string>('0');
  const { open } = useClaimReward();

  const [isApproved, setIsApproved] = useState<boolean>(false);
  const { campaign, createCampaign, createCw20 } = useClient();
  const [balance, setBalance] = useState<string>('0');
  const [loadingApprove, setLoadingApprove] = useState<boolean>(false);
  const { open: openDeposit } = useAddReward();
  const cw20 = useCreateCw20(campaignDetail?.token_address);

  const {
    rewardDebt,
    rewardClaimed,
    requestListStakeNFT,
    addressContract,
    fetchDataDetailCampaign
  } = useCampaignService();

  const status = checkStatus2(
    campaignDetail?.start,
    campaignDetail?.end,
    campaignDetail
  );

  const handleDepositReward = () => {
    if (addressContract && campaignDetail?.token_address) {
      createCampaign(addressContract);
      createCw20(campaignDetail?.token_address);
      openDeposit(campaignDetail);
    }
  };

  function handleApprove() {
    if (campaignDetail) {
      setLoadingApprove(true);
      cw20
        ?.increaseAllowance(address, campaignDetail?.address, balance)
        .then((data) => {
          toast.success('Approve successfully');
          setIsApproved(true);
          setLoadingApprove(false);
        })
        .catch((err) => {
          setLoadingApprove(false);
          let msg = handleMsgErr(err?.message);
          toast.error(msg);
        });
    }
  }

  useEffect(() => {
    setPendingReward(rewardDebt);
  }, [rewardDebt, pendingReward]);

  useEffect(() => {
    setClaimedReward(rewardClaimed);
  }, [rewardClaimed]);

  useEffect(() => {
    if (!is_po) {
      const check =
        nfts?.filter((item, index) => {
          return !item?.is_end_reward;
        })?.length > 0;
      const second = 5;
      let id = setInterval(() => {
        if (check) {
          requestListStakeNFT(undefined);
        }
      }, second * 2 * 1000);
      return () => {
        clearInterval(id);
      };
    }
  }, [pendingReward, nfts]);

  useEffect(() => {
    if (is_po && initialized) {
      campaign
        ?.total_pending_reward()
        .then((data) => {
          setTotalPendingReward(data.toString());
        })
        .catch((err) => {
          let msg = handleMsgErr(err?.message);
          toast.error(msg);
        });
    }
  }, [is_po, initialized]);

  async function claimReward() {
    return false;
    open(pendingReward, campaignDetail);
  }

  async function handleWithdrawReward() {
    setLoadingWithdraw(true);
    if (addressContract) {
      createCampaign(addressContract);
    }
  }

  useEffect(() => {
    if (loadingWithdraw) {
      if (campaign && campaign?.contractAddress === addressContract) {
        campaign
          ?.withdraw_reward(address)
          .then((data) => {
            toast.success('Withdraw successfully');
            fetchDataDetailCampaign(undefined);
            setLoadingWithdraw(false);
          })
          .catch((err) => {
            setLoadingWithdraw(false);
            console.log(err);
            let msg = handleMsgErr(err?.message);
            toast.error(msg);
          });
      } else {
        if (addressContract) {
          createCampaign(addressContract);
        }
      }
    }
  }, [loadingWithdraw, campaign]);

  useEffect(() => {
    if (is_po && campaignDetail && cw20) {
      if (status === 'Pending' || status === 'Upcoming') {
        let balance = 0;
        cw20
          ?.balance(address)
          .then(async (dataBalance) => {
            balance = Number(dataBalance);
            setBalance(dataBalance.toString());
            cw20
              ?.allowance(address, campaignDetail?.address)
              .then((dataAllowance) => {
                if (Number(dataAllowance?.allowance) < balance) {
                  setIsApproved(false);
                } else {
                  setIsApproved(true);
                }
              })
              .catch((err) => {
                let msg = handleMsgErr(err?.message);
                toast.error(msg);
              });
          })
          .catch((err) => {
            let msg = handleMsgErr(err?.message);
            toast.error(msg);
          });
      }
    }
  }, [cw20, campaignDetail]);

  useEffect(() => {
    if (cw20 && address) {
      cw20
        .balance(address)
        .then((balance) => {
          setBalanceReward(balance);
        })
        .catch((err) => {
          let msg = handleMsgErr(err?.message);
          toast.error(msg);
        });
    }
  }, [cw20, address]);

  return (
    <div className={styles['your-rewards-container']}>
      <div className={styles['your-rewards']}>
        <div className={styles['frame-reward']}>
          <Gift />
          <label>Your rewards</label>
        </div>

        <div className={styles['your-rewards__child']}>
          {is_po && (
            <div className={styles['data-reward']}>
              <label className={styles['title']}>Total deposited</label>
              <label className={styles['value']}>
                {formatNumberCoin(
                  Number(campaignDetail?.token_amount),
                  campaignDetail?.token_decimal
                )}{' '}
                {campaignDetail?.token_symbol}
              </label>
            </div>
          )}
          <div className={styles['data-reward']}>
            <label className={styles['title']}>Rewards claimed</label>
            <label className={styles['value']}>
              {formatNumberCoin(
                Number(balanceReward),
                Number(campaignDetail?.token_decimal)
              )}{' '}
              {campaignDetail?.token_symbol}
            </label>
          </div>
          <div className={styles['data-reward']}>
            <label className={styles['title']}>Pending rewards</label>
            <label className={styles['value']}>
              {/* <CountUp
              start={formatNumberCoinNum(Number(startPendingReward), Number(campaignDetail?.token_decimal))}
              end={formatNumberCoinNum(Number(pendingReward), Number(campaignDetail?.token_decimal))}
              duration={2}
            />{' '} */}
              <label>
                {is_po
                  ? formatNumberCoin(
                      Number(totalPendingReward),
                      Number(campaignDetail?.token_decimal)
                    )
                  : formatNumberCoin(
                      Number(0),
                      // Number(pendingReward),
                      Number(campaignDetail?.token_decimal)
                    )}
              </label>{' '}
              {campaignDetail?.token_symbol}
            </label>
          </div>
          {is_po ? (
            <>
              {isApproved || (status != 'Pending' && status != 'Upcoming') ? (
                <>
                  {status === 'Ended' ? (
                    <ButtonGradient
                      onClick={handleWithdrawReward}
                      loading={loadingWithdraw}
                      disabled={
                        !campaignDetail?.token_amount_remaining ||
                        campaignDetail?.token_amount_remaining <= 0
                      }
                    >
                      Withdraw Rewards
                    </ButtonGradient>
                  ) : (
                    <ButtonGradient
                      onClick={handleDepositReward}
                      loading={loadingClaimButton}
                      disabled={status != 'Pending' && status != 'Upcoming'}
                    >
                      Deposit Rewards
                    </ButtonGradient>
                  )}
                </>
              ) : (
                <div className={styles['approve-frame']}>
                  <p className={styles['approve-note']}>
                    To proceed with depositing, please grant us permission to
                    interact with tokens by clicking the "Approve" button below.
                  </p>
                  <div className={styles['approve-btns']}>
                    <ButtonGradient
                      onClick={handleApprove}
                      loading={loadingApprove}
                    >
                      Approve
                    </ButtonGradient>
                    <ButtonGradient disabled={true}>
                      Deposit Rewards
                    </ButtonGradient>
                  </div>
                </div>
              )}
            </>
          ) : (
            <ButtonGradient
              onClick={claimReward}
              loading={loadingClaimButton}
              disabled={true}
              // disabled={Number(pendingReward) <= 0}
            >
              <Tooltip
                title={
                  <span>
                    All rewards have been sent to your wallet, view on{' '}
                    <a
                      href={`https://aurascan.io/account/${address}`}
                      target="_blank"
                      style={{ textDecoration: 'underline' }}
                    >
                      Aurascan
                    </a>
                  </span>
                }
                color={'#414141'}
              >
                Claim Rewards
              </Tooltip>
            </ButtonGradient>
          )}
        </div>
        {/* <ButtonGradient
        onClick={claimReward}
        loading={loadingClaimButton}
        disabled={reward_debt == 0}
      >
        Claim Rewards
      </ButtonGradient> */}
      </div>
    </div>
  );
};

export default YourRewards;
