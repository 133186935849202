import React, { useState, useEffect } from 'react';
import styles from './styles.module.scss';
import Tabs from 'components/common/Tabs';
import ButtonGradient from 'components/base/ButtonGradient';
import TableStakedNFTs from './TableStakedNFTs';
import TableStakingHistory from 'components/common/TableStakingHistory';
import { useStakeNFTs } from 'context/StakeNFTs';
import { useSdk } from 'core/logic';
import { checkStatus2 } from 'utils/contract-utils';
import { CampaignService } from 'interface/campaign/campaign_service';
import { useCampaignService } from 'context/CampaignService';
import notifyPNG from 'assets/images/notify.png';
import moment from 'moment';
import BaseButton from 'components/base/Button';
import { NftKey } from 'core/logic/service/campaign';
import { useUnstakeAll } from 'context/UnstakeAll';
import { useClient } from 'core/logic/service/public';

interface ListStakeNFTsProps {
  nfts: any[] | undefined;
  campaignDetail: any;
  campaignAddress: string | undefined;
}

const ListStakeNFTs: React.FC<ListStakeNFTsProps> = ({
  nfts,
  campaignDetail = {},
  campaignAddress = ''
}) => {
  const [indexTabs, setIndexTabs] = useState<number>(0);
  const { initialized } = useSdk();
  const { openStakeNFTs } = useStakeNFTs();
  const { fetchDataStakingHistory } = useCampaignService();
  const { open } = useUnstakeAll();
  const { campaign } = useClient();

  const [disabledStaked, setDisabledStaked] = useState<boolean>(false);
  const [disabledUnstakeAll, setDisabledUnStakeAll] = useState<boolean>(false);
  const [loadingUnstake, setLoadingUnstake] = useState<boolean>(false);
  const [listNFTs, setListNFTs] = useState<any[] | undefined>([]);
  const [listNFTsUnstake, setListNFTsUnstake] = useState<any[]>([]);
  const [hasUnStakeAll, setHasUnStakeAll] = useState<boolean>(false);

  useEffect(() => {
    if (nfts) {
      setListNFTs(nfts);
      let canUnstake = false;
      let listUnstake: any[] = [];
      nfts?.forEach((nft) => {
        if (nft?.is_end_reward) {
          canUnstake = true;
          listUnstake.push(nft);
        }
        return nft;
      });
      if (!canUnstake) {
        setDisabledUnStakeAll(true);
      } else {
        setDisabledUnStakeAll(false);
      }
      setListNFTsUnstake(listUnstake);
    }
  }, [nfts]);

  useEffect(() => {
    setDisabledStaked(
      !initialized ||
        checkStatus2(
          campaignDetail?.start,
          campaignDetail?.end,
          campaignDetail
        ) != 'Active'
    );
  }, [campaignDetail]);

  useEffect(() => {
    if (indexTabs === 1) {
      fetchDataStakingHistory();
    }
  }, [indexTabs]);

  useEffect(() => {
    if (initialized && campaign) {
      campaign
        ?.has_un_stake_and_claim()
        .then((data) => {
          setHasUnStakeAll(true);
        })
        .catch((err) => {
          return true;
        });
    }
  }, [initialized, campaign]);

  async function unStakeAll() {
    open(listNFTsUnstake);
  }

  return (
    <div className={styles['list-stake-nfts']}>
      <div className={styles['head']}>
        <Tabs
          tabs={['Your staked NFTs', 'Staking history']}
          indexActive={indexTabs}
          setIndexActive={setIndexTabs}
        />
        {indexTabs === 0 && (
          <div className={styles['list-btn']}>
            <ButtonGradient
              onClick={() => {
                if (campaignDetail?.collection_address && campaignAddress) {
                  openStakeNFTs(campaignDetail);
                }
              }}
              disabled={disabledStaked}
            >
              Stake Now
            </ButtonGradient>
            {listNFTs && listNFTs?.length > 0 && hasUnStakeAll && (
              <BaseButton
                outline
                // loading={loadingUnstake === index}
                disabled={disabledUnstakeAll}
                onClick={unStakeAll}
              >
                Unstake All
              </BaseButton>
            )}
          </div>
        )}
      </div>
      {moment().isBefore(moment(campaignDetail?.start)) &&
        listNFTs &&
        listNFTs?.length > 0 && (
          <div className={styles['notify']}>
            <img src={notifyPNG} alt="" />
            <p className={styles['des']}>
              Rewards are only calculated when the campaign starts. <br />
              You can unstake your NFTs at any time before the campaign becomes
              active{' '}
            </p>
          </div>
        )}

      <div className={styles['body']}>
        {indexTabs === 0 ? (
          <TableStakedNFTs
            nfts={listNFTs}
            campaignDetail={campaignDetail}
            setNfts={setListNFTs}
          />
        ) : (
          <TableStakingHistory />
        )}
      </div>
    </div>
  );
};

export default ListStakeNFTs;
