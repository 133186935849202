export const appSetting = {
  apiUrl: process.env.REACT_APP_API || '/api',
  requestTimeout: process.env.REACT_APP_REQUEST_TIMEOUT || 50000,
  chain: process.env.REACT_APP_CHAIN || "...",
  network: process.env.REACT_APP_NETWORK || "auraeuphoria",
  factoryCampaign: process.env.REACT_APP_FACTORY_CAMPAIGN || "",
  scan: process.env.REACT_APP_SCAN || "https://euphoria.aurascan.io",
  query: process.env.REACT_APP_QUERY || "https://indexer-v2.staging.aurascan.io/api/v2/graphql",
  prefix_network: process.env.REACT_APP_PREFIX_NETWORK || "euphoria",
};
