import React, { useState } from 'react';
import styles from './styles.module.scss';
// import MascotNotice from 'components/common/MascotNotice';
import { formatNumberCoin } from 'helpers/number';
import BaseButton from 'components/base/Button';
import { useSdk } from 'core/logic';
import useContractCampaign from 'hooks/api/useContractCampaign';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import { toast } from 'react-toastify';
import { CampaignService } from 'interface/campaign/campaign_service';
import { handleMsgErr } from 'utils/contract-utils';
import { useCampaignService } from 'context/CampaignService';

// enum StatusEnum {
//   Locked = 'Locked',
//   CanUnstake = 'Can unstake'
// }

interface TableStakedNFTsProps {
  nfts: any[] | undefined;
  campaignDetail: CampaignService | undefined;
  setNfts: (value: any[] | undefined) => void;
}

// const data = [
//   {
//     logo: '/campaign-demo.png',
//     name: 'BBB #000',
//     staking_terms: 15,
//     unstake_after_days: 12,
//     rewards: 100,
//     status: 'Locked'
//   },
//   {
//     logo: '/campaign-demo.png',
//     name: 'BBB #000',
//     staking_terms: 60,
//     unstake_after_days: 1,
//     rewards: 100,
//     status: 'Can unstake'
//   },
//   {
//     logo: '/campaign-demo.png',
//     name: 'BBB #000',
//     staking_terms: 30,
//     unstake_after_days: 10,
//     rewards: 100,
//     status: 'Locked'
//   }
// ];

const TableStakedNFTs: React.FC<TableStakedNFTsProps> = ({
  nfts,
  campaignDetail,
  setNfts
}) => {
  const { initialized, address } = useSdk();
  const location = useLocation();
  const { campaignInstance } = useContractCampaign(
    location?.pathname?.replace('/detail-campaign/', '')
  );
  const [loadingUnstake, setLoadingUnstake] = useState<number>(-1);
  // const [nftsTable, setNftsTable] = useState(nfts);
  const { fetchDataStakingHistory } = useCampaignService();

  function unstake(item: any, index: number) {
    setLoadingUnstake(index);

    campaignInstance
      ?.un_stake_nft(
        address,
        {
          key: item?.key,
          lockup_term: item?.lockup_term?.value
        },
        item?.token_id
      )
      .then((data) => {
        // console.log(data);
        setLoadingUnstake(-1);
        let arr: any[] = [];
        if (nfts) {
          arr = [...nfts];
          arr.splice(index, 1);
        }
        setNfts(arr);
        fetchDataStakingHistory();
        toast.success('Unstake successfully');
      })
      .catch((err) => {
        // console.log(err);
        setLoadingUnstake(-1);
        let msg = handleMsgErr(err?.message);
        // toast.error(msg);
        toast.success('Unstake successfully');
      });

  }

  return (
    <div className={styles['table-staked-nfts']}>
      {/* <MascotNotice text='You haven’t staked any NFTs yet!' img='/depressed.svg' /> */}
      <table className={styles['table']}>
        <thead>
          <tr>
            <th>Your NFT(s)</th>
            <th>Staked date</th>
            <th>Staking terms</th>
            <th>Acc.rewards</th>
            <th>Status</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {nfts?.map((item, index) => {
            if (item) {
              return (
                <tr key={index}>
                  <td>
                    <div className={styles['nft']}>
                      <img
                        src={item?.media_info?.offchain?.image?.url}
                        className={styles['logo']}
                        alt={item?.media_info?.offchain?.image?.url}
                      />
                      <label className={styles['name']}>
                        {campaignDetail?.collection_name} #{item?.token_id}
                      </label>
                    </div>
                  </td>
                  <td>
                    {moment(item?.start_time * 1000).format(
                      'hh:mm A[\r\n]DD MMM YYYY'
                    )}
                  </td>
                  <td>
                    <div className={styles['staking-terms']} onClick={() => { }}>
                      <label className={styles['days-terms']}>
                        {(item?.lockup_term?.value / 86400).toFixed(0)} days
                      </label>
                      {!item?.is_end_reward && (
                        <label className={styles['days-unstake']}>
                          You can unstake after{' '}
                          {((item?.end_time - moment().unix()) / 86400).toFixed(
                            0
                          )}{' '}
                          days
                        </label>
                      )}
                    </div>
                  </td>
                  <td>
                    {formatNumberCoin(
                      item?.pending_reward,
                      campaignDetail?.token_decimal
                    )}{' '}
                    {campaignDetail?.token_symbol || 'AURA'}
                  </td>
                  <td>
                    {item?.is_end_reward === false ? 'Locked' : 'Can unstake'}
                  </td>
                  <td>
                    <div className={styles['unstake-btn']}>
                      <BaseButton
                        outline
                        loading={loadingUnstake === index}
                        disabled={item?.is_end_reward === false || !initialized}
                        onClick={() => {
                          unstake(item, index);
                        }}
                      >
                        Unstake
                      </BaseButton>
                    </div>
                  </td>
                </tr>
              );
            }
          })}
        </tbody>
      </table>

      <div className={styles['list-mobile']}>
        {nfts?.map((item, index) => {
          return (
            <div className={styles['list-mobile__item']} key={index}>
              <img
                src={item?.media_info?.offchain?.image?.url}
                className={styles['logo']}
                alt={item?.media_info?.offchain?.image?.url}
              />
              <div className={styles['infor']}>
                <label className={styles['campaign-name']}>
                  {campaignDetail?.collection_name} #{item?.token_id}
                </label>
                <label className={styles['collection-name']}>
                  You can unstake after{' '}
                  {((item?.end_time - moment().unix()) / 86400).toFixed(0)} days
                </label>
                <div className={styles['row-infor']}>
                  <label className={styles['row-infor__title']}>
                    Staked dates
                  </label>
                  <label className={styles['row-infor__value']}>
                    {moment(item?.start_time * 1000).format(
                      'hh:mm A, DD MMM YYYY'
                    )}
                  </label>
                </div>
                <div className={styles['row-infor']}>
                  <label className={styles['row-infor__title']}>
                    Staking terms
                  </label>
                  <label className={styles['row-infor__value']}>
                    {(item?.lockup_term?.value / 86400).toFixed(0)} days
                  </label>
                </div>
                <div className={styles['row-infor']}>
                  <label className={styles['row-infor__title']}>
                    Acc. rewards
                  </label>
                  <label className={styles['row-infor__value']}>
                    {formatNumberCoin(
                      item?.pending_reward,
                      campaignDetail?.token_decimal
                    )}{' '}
                    {campaignDetail?.token_symbol || 'AURA'}
                  </label>
                </div>
                <div className={styles['footer']}>
                  <label className={styles['footer-label']}>Locked</label>
                  <BaseButton
                    outline
                    loading={loadingUnstake === index}
                    disabled={item?.is_end_reward === false || !initialized}
                    onClick={() => {
                      unstake(item, index);
                    }}
                  >
                    Unstake
                  </BaseButton>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TableStakedNFTs;
